/* eslint-disable no-nested-ternary */
/* eslint-disable prefer-const */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-access-state-in-setstate */

/* eslint-disable no-shadow */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/sort-comp */
import React from 'react';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// @material-ui/icons
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Clearfix from 'components/Clearfix/Clearfix.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import Datetime from 'react-datetime';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import ReactSelect from 'react-select';
import Switch from '@material-ui/core/Switch';
import AddAlert from '@material-ui/icons/AddAlert';

import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

import CircularProgress from '@material-ui/core/CircularProgress';
import purple from '@material-ui/core/colors/purple';
import Danger from 'components/Typography/Danger.jsx';
import SnackbarContent from 'components/Snackbar/SnackbarContent.jsx';
import Snackbars from 'components/Snackbar/Snackbar.jsx';

import SweetAlert from 'react-bootstrap-sweetalert';
import moment from 'moment';
import ArrowBack from '@material-ui/icons/ArrowBack';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
// Fireabse
import AddTruckersStyle from 'assets/jss/material-dashboard-pro-react/views/AddTruckersStyle.jsx';
import { Radio, RadioGroup } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import firebase, { googleApiKey } from '../../../config/config';

import { switchModifyPage } from '../../../reducers/transactions';
import apiCaller from '../../../utils/apiCallerOutstanding';
import auditLogger from '../../../utils/auditLoggerToPG';
import OtherExpensesModifyTransaction from './TripCalculation/ModifyTransaction/OtherExpensesModifyTransaction';
import TripDeductionsModifyTransaction from './TripCalculation/ModifyTransaction/TripDeductionsModifyTransaction';
import AddManufacturer from './AddManufacturer';
import getManufacturerList from '../../../utils/getManufacturerList';

const addTransaction = firebase
  .functions()
  .httpsCallable('transactions-addTransaction');

const db = firebase.firestore();
const auditLog = firebase.functions().httpsCallable('utilities-auditLog');

class ModifyTransaction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingSubLocation: '',
      unloadingSubLocation: '',
      address: '',
      addressUnloading: '',
      transporterName: '',
      transporterNameState: '',
      truckerNameState: '',
      transporterId: '',
      truckerName: '',
      showTds: true,
      hardCodeTransactionNumber: 95670,
      latestTransactionNumber: '',
      truckerId: '',
      fromCity: [],
      fromCityState: '',
      toCity: [],
      toCityState: '',
      vehicleNumber: '',
      vehicleNumberState: '',
      amount: '',
      amountState: '',
      commission: '',
      commissionState: '',
      loadingCharges: '0',
      loadingChargesState: '',
      athPayable: '',
      athPayableState: '',
      bthPayable: '',
      bthPayableState: '',
      athReceivable: '',
      athReceivableState: '',
      bthReceivable: '',
      bthReceivableState: '',
      truckType: '',
      truckTypeState: '',
      truckNumber: '',
      truckNumberState: '',
      companyName: '',
      comment: '',
      isLoading: false,
      alert: null,
      companyId: '',
      trucks: [],
      truckTypes: [
        '12T- Open',
        '20FT Container',
        '22FT Closed Container',
        '22FT Jcb Carrier',
        '24FT Closed Container',
        '24FT Jcb Carrier',
        '32FT MultiAxle Container',
        '32FT Single Axle Container',
        '34FT Multi Axle Container',
        '34FT Single Axle Container',
        'FTL',
        'High Bed Trailer',
        'High Bed Trailer Double Axle',
        'High Bed Trailer Multi Axle',
        'LCV Closed Container - 14FT',
        'LCV-14FT',
        'LCV-19FT',
        'LCV-7FT',
        'LCV-17FT',
        'Low Bed Trailer',
        'Low Bed Trailer Double Axle',
        'Low Bed Trailer Multi Axle',
        'LPT-19 FT',
        'LPT-19FT Closed Container',
      ],
      transporters: [],
      truckers: [],
      fromCities: [],
      toCities: [],
      totalPayablePendingAmount: 0,
      totalReceivablePendingAmount: 0,
      totalCommission: 0,
      transactionSerial: 1,
      loadingDate: moment(),
      athReceivableDate: moment(),
      athPayableAutoCalculated: 0,
      bthPayableAutoCalculated: 0,
      selectedTrucker: null,
      selectedTransporter: null,
      agentId: '',
      createdBy: '',
      editedBy: '',
      createdByName: '',
      editedByName: '',
      newCurrentReceivableAmount: 0,
      newCurrentPayableAmount: 0,
      fromCitiesObject: [],
      selectedFromCity: null,
      selectedToCity: null,
      // loadingChargesPaidByTrucker: false,
      // hamaliChargesPaidByTrucker: false,
      // haltingChargesPaidByTrucker: false,
      ledgerId: '',
      truckerPhoneNumber: '',
      selectedTruck: null,
      selectedRisk: null,
      statusNotification: false,
      statusNotificationText: '',
      transporterCustomers: [],
      truckerCustomers: [],
      fetching: false,
      transporterPOC: [],
      truckerPOC: [],
      transporterPOCEdit: false,
      truckerPOCEdit: false,
      trafficUsers: [],
      truckPlacedBy: [],
      orderRecievedBy: [],
      isFetchingTraffic: false,
      tonnage: '',
      tonnageState: '',
      expectedDate: moment(),
      driverNumber: '',
      driverNumberState: '',
      hamaliChargesTrucker: 0,
      hamaliChargesTruckerState: '',
      hamaliChargesPaidByTrucker: false,
      hamaliChargesPaidByTransporter: false,
      hamaliChargesTransporter: 0,
      hamaliChargesTransporterState: '',
      haltingChargesTrucker: 0,
      haltingChargesTruckerState: '',
      haltingChargesTransporter: 0,
      haltingChargesTransporterState: '',
      haltingChargesPaidByTransporter: false,
      haltingChargesPaidByTrucker: false,
      loadingChargesTrucker: 0,
      loadingChargesTruckerState: '',
      loadingChargesTransporter: 0,
      loadingChargesTransporterState: '',
      loadingChargesPaidByTrucker: false,
      loadingChargesPaidByTransporter: false,
      unloadingChargesTrucker: 0,
      unloadingChargesTruckerState: '',
      unloadingChargesTransporter: 0,
      unloadingChargesTransporterState: '',
      unloadingChargesPaidByTrucker: false,
      unloadingChargesPaidByTransporter: false,
      challanTransporterAmount: 0,
      challanTransporterAmountState: '',
      challanTruckerAmount: 0,
      challanTruckerAmountState: '',
      penaltyTruckerAmount: 0,
      penaltyTruckerAmountState: '',
      fuelChargesTransporterAmount: 0,
      tdsTransporterAmount: 0,
      docChargesTransporterAmount: 0,
      penaltyTransporterAmount: 0,
      maamolChargesTransporterAmount: 0,
      maamolChargesTransporterAmountState: '',
      tdsTruckerAmount: 0,
      tdsTruckerAmountState: '',
      tdsTruckerShowinloadingslip: false,
      fuelChargesTruckerShowinloadingslip: false,
      fuelChargesTruckerAmount: 0,
      fuelChargesTruckerAmountState: '',
      docChargesTruckerShowinloadingslip: false,
      docChargesTruckerAmount: 0,
      docChargesTruckerAmountState: '',
      maamolChargesTruckerShowinloadingslip: false,
      maamolChargesTruckerAmount: 0,
      maamolChargesTruckerAmountState: '',
      shortageChargesTruckerShowinloadingslip: false,
      shortageChargesTruckerAmount: 0,
      shortageChargesTruckerAmountState: '',
      lateDeliveryTruckerShowinloadingslip: false,
      lateDeliveryTruckerAmount: 0,
      lateDeliveryTruckerAmountState: '',
      latePodTruckerShowinloadingslip: false,
      latePodTruckerAmount: 0,
      latePodTruckerAmountState: '',
      earlySettlementTruckerShowinloadingslip: false,
      earlySettlementTruckerAmount: 0,
      earlySettlementTruckerAmountState: '',
      transactionType: 'TAT',
      manufacturerList: [],
      selectedManufacturer: '',
      loadingChargesTruckerError: '',
      unloadingChargesTruckerError: '',
      hamaliChargesTruckerError: '',
      haltingChargesTruckerError: '',
      loadingChargesTransporterError: '',
      unloadingChargesTransporterError: '',
      hamaliChargesTransporterError: '',
      haltingChargesTransporterError: '',
      challanTransporterAmountError: '',
      penaltyTransporterAmountError: '',
      tdsTransporterAmountError: '',
      docChargesTransporterAmountError: '',
      fuelChargesTransporterAmountError: '',
      maamolChargesTransporterAmountError: '',
      challanTruckerAmountError: '',
      penaltyTruckerAmountError: '',
      tdsTruckerAmountError: '',
      fuelChargesTruckerAmountError: '',
      docChargesTruckerAmountError: '',
      maamolChargesTruckerAmountError: '',
      shortageChargesTruckerAmountError: '',
      lateDeliveryTruckerAmountError: '',
      latePodTruckerAmountError: '',
      earlySettlementTruckerAmountError: '',
      supplyUsers: [],
      supplyID: '',
      supplyIDState: '',
      demandID: '',
      demandIDState: '',
      isDemandValid: '',
      isSupplyValid: '',
      isValidatingID: '',
      validPan: true,
      tdsTruckerSubmitted: true,
    };
    this.change = this.change.bind(this);
    this.submitTransaction = this.submitTransaction.bind(this);
    this.validateAllFields = this.validateAllFields.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.getTransporters = this.getTransporters.bind(this);
    this.getTruckers = this.getTruckers.bind(this);
    this.getCities = this.getCities.bind(this);
    this.updateDashboardData = this.updateDashboardData.bind(this);
    this.handleTrucker = this.handleTrucker.bind(this);
    this.handleTransporter = this.handleTransporter.bind(this);
    this.handleTrucks = this.handleTrucks.bind(this);
    this.updateTransactionSerial = this.updateTransactionSerial.bind(this);
    this.goBack = this.goBack.bind(this);
    this.loadingDateChange = this.loadingDateChange.bind(this);
    this.athReceivableDateChange = this.athReceivableDateChange.bind(this);
    this.expectedDateChange = this.expectedDateChange.bind(this);
    this.otherExpenses = this.otherExpenses.bind(this);
    this.deductions = this.deductions.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.checkIsValidCalculations = this.checkIsValidCalculations.bind(this);
    this.handleFromCity = this.handleFromCity.bind(this);
    this.handleToCity = this.handleToCity.bind(this);
    this.handlePaidByTruckerSwitch = this.handlePaidByTruckerSwitch.bind(this);
    this.handleChangeTransactionType = this.handleChangeTransactionType.bind(
      this
    );
  }

  hideAlert(stay) {
    this.setState({
      alert: null,
    });
  }

  hideSuccessAlert(stay) {
    this.setState({
      alert: null,
    });
    if (!stay) this.goBack();
  }

  componentDidMount() {
    this.getTransporters();

    const { currentTransactionData } = this.props;
    if (
      currentTransactionData?.transactionSerial >=
      this.state.hardCodeTransactionNumber
    ) {
      this.tdsDeductedFlagHandler(currentTransactionData);
    }

    const currentSelectedManufacturer =
      currentTransactionData?.manufacturerName &&
      currentTransactionData?.manufacturerId
        ? {
            label: currentTransactionData?.manufacturerName,
            value: currentTransactionData?.manufacturerId,
          }
        : { label: '', value: '' };
    getManufacturerList().then(listData =>
      this.setState({ manufacturerList: listData })
    );
    this.setState({ selectedManufacturer: currentSelectedManufacturer });
    this.getTransporterSecondaryContacts(currentTransactionData.transporterId);
    this.getTruckerSecondaryContacts(currentTransactionData.truckerId);

    // this.setState({
    //   alert: null,
    // });
    this.getCities();
    this.setSubLocations();
    this.getTraffic();
    this.getSupply();
    const {
      transporterId,
      transporterName,
      truckerId,
      truckerName,
      fromCity,
      toCity,
      vehicleNumber,
      truckType,
      amount,
      commission,
      athPayable,
      bthPayable,
      athReceivable,
      bthReceivable,
      loadingDate,
      athReceivableDate,
      comment,
      branch,
      agentId,
      ledgerId,
      truckerPhoneNumber,
      truckPlacedById,
      truckPlacedByName,
      orderRecievedById,
      orderRecievedByName,
      tonnage,
      expectedDate,
      driversPhoneNumber,
      hamaliChargesTrucker,
      haltingChargesTrucker,
      hamaliChargesTransporter,
      haltingChargesTransporter,
      hamaliChargesPaidByTransporter,
      haltingChargesPaidByTransporter,
      loadingChargesPaidByTransporter,
      unloadingChargesPaidByTransporter,
      challanTransporterAmount,
      penaltyTransporterAmount,
      tdsTransporterAmount,
      docChargesTransporterAmount,
      fuelChargesTransporterAmount,
      maamolChargesTransporterAmount,
      challanTruckerAmount,
      penaltyTruckerAmount,
      tdsTruckerAmount,
      fuelChargesTruckerAmount,
      docChargesTruckerAmount,
      maamolChargesTruckerAmount,
      shortageChargesTruckerAmount,
      lateDeliveryTruckerAmount,
      latePodTruckerAmount,
      earlySettlementTruckerAmount,
      tdsTruckerShowinloadingslip,
      fuelChargesTruckerShowinloadingslip,
      docChargesTruckerShowinloadingslip,
      maamolChargesTruckerShowinloadingslip,
      shortageChargesTruckerShowinloadingslip,
      lateDeliveryTruckerShowinloadingslip,
      latePodTruckerShowinloadingslip,
      earlySettlementTruckerShowinloadingslip,
      supplyId,
      demandId,
    } = currentTransactionData;
    let {
      loadingChargesPaidByTrucker,
      unloadingChargesPaidByTrucker,
      hamaliChargesPaidByTrucker,
      haltingChargesPaidByTrucker,
      riskStatus,
      unloadingChargesTrucker,
      unloadingChargesTransporter,
      loadingChargesTrucker,
      loadingChargesTransporter,
    } = currentTransactionData;
    if (loadingChargesPaidByTrucker === undefined)
      loadingChargesPaidByTrucker = false;
    if (unloadingChargesTrucker === undefined) unloadingChargesTrucker = 0;
    if (unloadingChargesTransporter === undefined)
      unloadingChargesTransporter = 0;
    if (loadingChargesTrucker === undefined) loadingChargesTrucker = 0;
    if (loadingChargesTransporter === undefined) loadingChargesTransporter = 0;
    if (unloadingChargesPaidByTrucker === undefined)
      unloadingChargesPaidByTrucker = false;
    if (hamaliChargesPaidByTrucker === undefined)
      hamaliChargesPaidByTrucker = false;
    if (haltingChargesPaidByTrucker === undefined)
      haltingChargesPaidByTrucker = false;
    if (riskStatus === undefined) riskStatus = 'green';
    this.setState({
      selectedRisk: riskStatus,
    });
    const selectedToCity = {
      value: toCity,
      label: toCity,
    };
    const selectedFromCity = {
      value: fromCity,
      label: fromCity,
    };
    let { createdBy, createdByName } = currentTransactionData;
    if (!createdBy && !createdByName) {
      createdBy = '';
      createdByName = '';
    }
    this.getTruckers(truckerId, truckerName, vehicleNumber, truckType);
    const selectedTransporter = {
      value: transporterId,
      label: transporterName,
    };
    const selectedTrucker = {
      value: truckerId,
      label: truckerName,
    };
    const pocTransporter = {
      value: currentTransactionData.transporterPocId,
      label: currentTransactionData.transporterPocName,
    };
    const pocTrucker = {
      value: currentTransactionData.truckerPocId,
      label: currentTransactionData.truckerPocName,
    };
    const currentTruckPlacedBy = {
      value: truckPlacedById,
      label: truckPlacedByName,
    };
    const currentOrderRecievedBy = {
      value: orderRecievedById,
      label: orderRecievedByName,
    };

    this.setState(
      {
        agentId,
        createdBy,
        createdByName,
        branch,
        transporterId,
        transporterName,
        truckerName,
        truckerId,
        fromCity,
        toCity,
        truckNumber: vehicleNumber,
        truckType,
        amount,
        commission,
        athPayable,

        bthPayable,
        athReceivable,
        bthReceivable,
        comment,
        loadingDate: moment(loadingDate, 'MMM Do YY'),
        athReceivableDate: moment(athReceivableDate, 'MMM Do YY'),
        selectedTrucker,
        selectedTransporter,
        selectedFromCity,
        selectedToCity,
        loadingChargesPaidByTrucker,
        unloadingChargesPaidByTrucker,
        hamaliChargesPaidByTrucker,
        haltingChargesPaidByTrucker,
        ledgerId,
        truckerPhoneNumber,
        transporterPOC: pocTransporter,
        truckerPOC: pocTrucker,
        truckPlacedBy: currentTruckPlacedBy,
        orderRecievedBy: currentOrderRecievedBy,
        tonnage,
        expectedDate: moment(expectedDate, 'MMM Do YY'),
        driverNumber: driversPhoneNumber,
        hamaliChargesTrucker,
        haltingChargesTrucker,
        loadingChargesTrucker,
        unloadingChargesTrucker,
        hamaliChargesTransporter,
        haltingChargesTransporter,
        loadingChargesTransporter,
        unloadingChargesTransporter,
        // hamaliChargesPaidByTrucker,
        // haltingChargesPaidByTrucker,
        // loadingChargesPaidByTrucker,
        hamaliChargesPaidByTransporter,
        haltingChargesPaidByTransporter,
        loadingChargesPaidByTransporter,
        unloadingChargesPaidByTransporter,
        challanTransporterAmount,
        penaltyTransporterAmount,
        tdsTransporterAmount,
        docChargesTransporterAmount,
        fuelChargesTransporterAmount,
        maamolChargesTransporterAmount,
        challanTruckerAmount,
        penaltyTruckerAmount,
        tdsTruckerAmount,
        fuelChargesTruckerAmount,
        docChargesTruckerAmount,
        maamolChargesTruckerAmount,
        shortageChargesTruckerAmount,
        lateDeliveryTruckerAmount,
        latePodTruckerAmount,
        earlySettlementTruckerAmount,
        tdsTruckerShowinloadingslip,
        fuelChargesTruckerShowinloadingslip,
        docChargesTruckerShowinloadingslip,
        maamolChargesTruckerShowinloadingslip,
        shortageChargesTruckerShowinloadingslip,
        lateDeliveryTruckerShowinloadingslip,
        latePodTruckerShowinloadingslip,
        earlySettlementTruckerShowinloadingslip,
        supplyID: supplyId,
        demandID: demandId,
        latestTransactionNumber: currentTransactionData.transactionSerial,
      },
      this.calculateFields
    );
    this.setState({
      transactionType: currentTransactionData?.expressTrip ? 'Express' : 'TAT',
    });
  }

  // eslint-disable-next-line consistent-return
  async getTraffic() {
    this.setState({ isFetchingTraffic: true });
    const userDetails = firebase.auth().currentUser;
    const { Aa } = userDetails;
    const endPoint = 'pipe/get-lists';

    const body = {
      type: 'traffic_persons',
    };
    try {
      const response = await apiCaller(endPoint, body, `Bearer ${Aa}`, 'pipe');
      const trafficUsers = response?.data?.Data;
      this.setState({ trafficUsers, isFetchingTraffic: false });
    } catch (error) {
      this.setState({ isFetchingTraffic: false });
    }
  }

  async getSupply() {
    this.setState({ isFetchingTraffic: true });
    const userDetails = firebase.auth().currentUser;
    const { Aa } = userDetails;
    const endPoint = 'pipe/get-lists';

    const body = {
      type: 'supply_persons',
    };
    try {
      const response = await apiCaller(endPoint, body, `Bearer ${Aa}`, 'pipe');
      const supplyUsers = response?.data?.Data;
      this.setState({ supplyUsers, isFetchingTraffic: false });
    } catch (error) {
      this.setState({ isFetchingTraffic: false });
    }
  }

  // Will set the Sub locations of existing transaction
  setSubLocations() {
    const { currentTransactionData } = this.props;
    let loadingLat = '';
    let loadingLong = '';
    let unloadingLat = '';
    let unloadingLong = '';
    const {
      loadingAddress,
      unloadingAddress,
      loadingSubLocation,
      unloadingSubLocation,
    } = currentTransactionData;
    if (
      loadingSubLocation !== undefined &&
      loadingSubLocation !== '' &&
      loadingSubLocation
    ) {
      loadingLat = loadingSubLocation.lat;
      loadingLong = loadingSubLocation.lng;
    }
    if (
      unloadingSubLocation !== undefined &&
      unloadingSubLocation !== '' &&
      unloadingSubLocation
    ) {
      unloadingLat = unloadingSubLocation.lat;
      unloadingLong = unloadingSubLocation.lng;
    }
    if (loadingAddress !== undefined) {
      this.setState({
        address: loadingAddress,
      });
    } else if (
      loadingLat !== '' &&
      loadingLong !== '' &&
      loadingLat !== undefined &&
      loadingLong !== undefined
    ) {
      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${loadingLat},${loadingLong}&key=${googleApiKey}`
      )
        .then(res => res.json())
        .then(result => {
          this.setState({
            address: result.results[0].formatted_address,
          });
        });
    }
    if (unloadingAddress !== undefined) {
      this.setState({
        addressUnloading: unloadingAddress,
      });
    } else if (
      unloadingLat !== '' &&
      unloadingLong !== '' &&
      unloadingLat !== undefined &&
      unloadingLong !== undefined
    ) {
      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${unloadingLat},${unloadingLong}&key=${googleApiKey}`
      )
        .then(res => res.json())
        .then(result => {
          this.setState({
            addressUnloading: result.results[0].formatted_address,
          });
        });
    }
  }

  /**
   * will return All the Truckers Available for User
   * @function
   * @param {String} companyId
   */
  getTruckers(truckerName, truckNumber, truckType) {
    const TruckerRef = db.collection('Truckers');
    TruckerRef.where('status', '==', 'approved')
      .get()
      .then(
        resultData => {
          const truckers = [];
          resultData.forEach(trucker => {
            //  Checks if transporter and user have any common branches
            if (
              trucker.data().updatedByRole !== 'premiumTransporter' &&
              trucker.data().kycLevel !== '0'
            ) {
              const data = trucker.data();
              data.label = trucker.data().name;
              data.value = trucker.data().truckerId;
              data.blacklisted = trucker.data()?.blacklist;
              // if (!data?.blacklist) {
              truckers.push(data);
              // }
            }
          });
          this.setState(
            {
              truckers,
            },
            () =>
              this.handleExistingTruckerData(
                truckerName,
                truckNumber,
                truckType
              )
          );
        },
        err => {
          console.log('err', err);
        }
      )
      .catch(err => {
        console.log('err', err);
      });
  }

  handleChange = address => {
    this.setState({ address });
  };

  handleChangeUnloading = addressUnloading => {
    this.setState({ addressUnloading });
  };

  handleSelectUnloading = addressUnloading => {
    this.setState({ addressUnloading });
    geocodeByAddress(addressUnloading)
      .then(results => getLatLng(results[0]))
      .then(latLng => this.setState({ unloadingSubLocation: latLng }))
      .catch(error => console.error('Error unloading', error));
  };

  handleSelect = address => {
    this.setState({ address });
    geocodeByAddress(address)
      .then(results => getLatLng(results[0]))
      .then(latLng => this.setState({ loadingSubLocation: latLng }))
      .catch(error => console.error('Error', error));
  };

  /**
   * will return All the Transporters Available for User
   * @function
   * @param {String} companyId
   */
  getTransporters() {
    const TransporterRef = db.collection(`Transporters`);
    TransporterRef.where('status', '==', 'approved')
      .get()
      .then(
        resultData => {
          const transporters = [];
          resultData.forEach(transporter => {
            //  Checks if transporter and user have any common branches
            const data = transporter.data();
            data.label = transporter.data().name;
            data.value = transporter.data().transporterId;
            data.blacklisted = transporter?.data()?.blacklist;
            // if blacklisted do not added
            // if (!data?.blacklist) {
            transporters.push(data);
            // }
          });
          this.setState({ transporters });
        },
        err => {
          console.log('err', err);
        }
      )
      .catch(err => {
        console.log('err', err);
      });
  }

  /**
   * will return All the Cities Available for User
   * @function
   * @param {String} companyId
   */
  async getCities() {
    const userDetails = firebase.auth().currentUser;
    const { Aa } = userDetails;
    try {
      const endPoint = 'pipe/get-lists';
      const body = {
        type: 'cities',
      };
      const response = await apiCaller(endPoint, body, `Bearer ${Aa}`, 'pipe');

      const citiesList = response?.data?.Data;
      const fromCitiesObject = [];
      // eslint-disable-next-line guard-for-in

      for (let i = 0; i < citiesList.length; i++) {
        fromCitiesObject.push({ value: citiesList[i], label: citiesList[i] });
      }

      this.setState({ fromCitiesObject });
    } catch (e) {
      console.log('error', e);
    }
  }

  // function that verifies if value contains only numbers
  verifyNumber(value) {
    const numberRex = new RegExp('^[0-9]+$');
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  }

  verifyNumberAndGreater(value) {
    const numberRex = new RegExp('^[0-9]+$');
    if (numberRex.test(value) && value > 1) {
      return true;
    }
    return false;
  }

  verifyTonnage(value) {
    const numberRex = /^\s*(?=.*[1-9])\d*(?:\.\d{1,2})?\s*$/;
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  }

  verifyDriverNumber(value) {
    const numberRex = /^[6-9][0-9]{9}$/;
    if (numberRex.test(value) && value.length === 10) {
      return true;
    }
    return false;
  }

  verifyMaxNumber(value, maxValue) {
    const numberRex = new RegExp('^[0-9]+$');
    if (numberRex.test(value) && value <= maxValue) {
      return true;
    }
    return false;
  }

  async isAlphaNumeric(value, type) {
    const alphanumericRegex = /^[0-9a-zA-Z]+$/; // Regular expression to match alphanumeric characters
    const endPoint = 'pipe/trips';
    const userDetails = firebase.auth().currentUser;
    const { Aa } = userDetails;
    this.setState({ isValidatingID: type });
    const body = {
      supply_id: type === 'supplyID' ? value : '',
      demand_id: type === 'demandID' ? value : '',
      type: 'validate_supply_traffic',
    };
    if (alphanumericRegex.test(value)) {
      try {
        const response = await apiCaller(
          endPoint,
          body,
          `Bearer ${Aa}`,
          'pipe',
          'post'
        );
        const result = response?.data?.status;
        if (type === 'supplyID') {
          this.setState({ isSupplyValid: result ? 'isValid' : 'isNotValid' });
        } else {
          this.setState({ isDemandValid: result ? 'isValid' : 'isNotValid' });
        }
        this.setState({ isValidatingID: '' });
      } catch (e) {
        this.setState({
          isDemandValid: 'isNotValid',
          isSupplyValid: 'isNotValid',
          isValidatingID: '',
        });
      }
      return true;
      // return alphanumericRegex.test(value);
    }
    return false;
  }

  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  // Will Verify cities
  verifyCities(value) {
    if (value.length < 2) {
      return false;
    }
    return true;
  }

  calculateFields() {
    const {
      amount,
      athPayable,
      bthPayable,
      athReceivable,
      challanTruckerAmount,
      penaltyTruckerAmount,
      tdsTruckerAmount,
      fuelChargesTruckerAmount,
      docChargesTruckerAmount,
      maamolChargesTruckerAmount,
      shortageChargesTruckerAmount,
      lateDeliveryTruckerAmount,
      latePodTruckerAmount,
      earlySettlementTruckerAmount,
      challanTransporterAmount,
      penaltyTransporterAmount,
      tdsTransporterAmount,
      docChargesTransporterAmount,
      fuelChargesTransporterAmount,
      maamolChargesTransporterAmount,
      loadingChargesPaidByTransporter,
      loadingChargesTransporter,
      unloadingChargesPaidByTransporter,
      unloadingChargesTransporter,
      hamaliChargesTransporter,
      hamaliChargesPaidByTransporter,
      haltingChargesPaidByTransporter,
      haltingChargesTransporter,
      loadingChargesTrucker,
      unloadingChargesTrucker,
      hamaliChargesTrucker,
      haltingChargesTrucker,
    } = this.state;
    let {
      loadingChargesPaidByTrucker,
      unloadingChargesPaidByTrucker,
      hamaliChargesPaidByTrucker,
      haltingChargesPaidByTrucker,
    } = this.state;
    if (loadingChargesPaidByTrucker === undefined)
      loadingChargesPaidByTrucker = false;
    if (unloadingChargesPaidByTrucker === undefined)
      unloadingChargesPaidByTrucker = false;
    if (hamaliChargesPaidByTrucker === undefined)
      hamaliChargesPaidByTrucker = false;
    if (haltingChargesPaidByTrucker === undefined)
      haltingChargesPaidByTrucker = false;
    const bthReceivable = amount - athReceivable;
    this.setState({ bthReceivable });

    if (this.verifyNumber(bthReceivable))
      this.setState({
        bthReceivableState: 'success',
      });
    else
      this.setState({
        bthReceivableState: 'Invalid',
      });

    if (this.verifyNumber(athPayable))
      this.setState({
        athPayableState: 'success',
      });
    else
      this.setState({
        athPayableState: 'Invalid',
      });

    if (this.verifyNumber(bthPayable))
      this.setState({
        bthPayableState: 'success',
      });
    else
      this.setState({
        bthPayableState: 'Invalid',
      });

    let payableDeductions = 0;
    if (Number(challanTruckerAmount) > 0)
      payableDeductions += Number(challanTruckerAmount);
    if (Number(penaltyTruckerAmount) > 0)
      payableDeductions += Number(penaltyTruckerAmount);
    if (Number(tdsTruckerAmount) > 0)
      payableDeductions += Number(tdsTruckerAmount);
    if (Number(docChargesTruckerAmount) > 0)
      payableDeductions += Number(docChargesTruckerAmount);
    if (Number(maamolChargesTruckerAmount) > 0)
      payableDeductions += Number(maamolChargesTruckerAmount);
    if (Number(shortageChargesTruckerAmount) > 0)
      payableDeductions += Number(shortageChargesTruckerAmount);
    if (Number(lateDeliveryTruckerAmount) > 0)
      payableDeductions += Number(lateDeliveryTruckerAmount);
    if (Number(latePodTruckerAmount) > 0)
      payableDeductions += Number(latePodTruckerAmount);
    if (Number(earlySettlementTruckerAmount) > 0)
      payableDeductions += Number(earlySettlementTruckerAmount);
    if (Number(fuelChargesTruckerAmount) > 0)
      payableDeductions += Number(fuelChargesTruckerAmount);

    //  Total Receivable Calculation
    let receivableDeductions = 0;
    if (Number(challanTransporterAmount) > 0)
      receivableDeductions += Number(challanTransporterAmount);
    if (Number(fuelChargesTransporterAmount) > 0)
      receivableDeductions += Number(fuelChargesTransporterAmount);
    if (Number(tdsTransporterAmount) > 0)
      receivableDeductions += Number(tdsTransporterAmount);
    if (Number(docChargesTransporterAmount) > 0)
      receivableDeductions += Number(docChargesTransporterAmount);
    if (Number(penaltyTransporterAmount) > 0)
      receivableDeductions += Number(penaltyTransporterAmount);
    if (Number(maamolChargesTransporterAmount) > 0)
      receivableDeductions += Number(maamolChargesTransporterAmount);

    // let otherExpensesPayable = 0;
    let otherExpensesReceivable = 0;

    if (
      loadingChargesPaidByTransporter &&
      Number(loadingChargesTransporter) > 0
    ) {
      otherExpensesReceivable += Number(loadingChargesTransporter);
    }

    if (
      unloadingChargesPaidByTransporter &&
      Number(unloadingChargesTransporter) > 0
    ) {
      otherExpensesReceivable += Number(unloadingChargesTransporter);
    }

    if (
      hamaliChargesPaidByTransporter &&
      Number(hamaliChargesPaidByTransporter) > 0
    ) {
      otherExpensesReceivable += Number(hamaliChargesTransporter);
    }

    if (
      haltingChargesPaidByTransporter &&
      Number(haltingChargesTransporter) > 0
    ) {
      otherExpensesReceivable += Number(haltingChargesTransporter);
    }

    let otherExpensesPaidByTrucker = 0;
    if (loadingChargesPaidByTrucker && Number(loadingChargesTrucker) > 0)
      otherExpensesPaidByTrucker += Number(loadingChargesTrucker);
    if (unloadingChargesPaidByTrucker && Number(unloadingChargesTrucker) > 0)
      otherExpensesPaidByTrucker += Number(unloadingChargesTrucker);
    if (hamaliChargesPaidByTrucker && Number(hamaliChargesTrucker) > 0)
      otherExpensesPaidByTrucker += Number(hamaliChargesTrucker);
    if (haltingChargesPaidByTrucker && Number(haltingChargesTrucker) > 0)
      otherExpensesPaidByTrucker += Number(haltingChargesTrucker);

    const totalPayableAmount =
      Number(athPayable) +
      Number(bthPayable) -
      Number(payableDeductions) +
      Number(otherExpensesPaidByTrucker);
    // + otherExpensesPayable;
    const totalReceivableAmount =
      Number(athReceivable) +
      Number(bthReceivable) -
      receivableDeductions +
      otherExpensesReceivable;

    const athPayableAutoCalculated = athPayable - payableDeductions; // + otherExpensesPayable;
    const bthPayableAutoCalculated =
      Number(bthPayable) + Number(otherExpensesPaidByTrucker);

    const calculatedCommision = totalReceivableAmount - totalPayableAmount;
    this.setState({
      totalPayableAmount,
      totalReceivableAmount,
      // otherExpensesPayable,
      otherExpensesReceivable,
      payableDeductions,
      receivableDeductions,
      netCommission: calculatedCommision,
      athPayableAutoCalculated,
      bthPayableAutoCalculated,
    });
  }

  handleTruckerATH(amount, val) {
    const result = amount - val;
    let truckerATH = Math.round(result * 0.85);
    let truckerBTH = Math.round(result - truckerATH);
    this.setState({ athPayable: truckerATH, bthPayable: truckerBTH });
  }

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'number':
        if (stateName === 'commission') {
          this.handleTruckerATH(this.state.amount, event.target.value);
        }
        if (this.verifyNumber(event.target.value)) {
          this.setState(
            {
              [`${stateName}State`]: 'success',
              [stateName]: event.target.value,
            },
            this.calculateFields
          );
        } else {
          this.setState({
            [`${stateName}State`]: 'Invalid',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'maxNumber':
        if (this.verifyMaxNumber(event.target.value, stateNameEqualTo)) {
          this.setState(
            {
              [`${stateName}State`]: 'success',
              [stateName]: event.target.value,
            },
            this.calculateFields
          );
        } else {
          this.setState({
            [`${stateName}State`]: 'Invalid',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'Invalid',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'greater':
        if (stateName === 'amount') {
          this.handleTruckerATH(event.target.value, this.state.commission);
        }
        if (this.verifyNumberAndGreater(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'Invalid',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'tonnage':
        if (this.verifyTonnage(event.target.value, stateNameEqualTo)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'Invalid',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'driverNumber':
        if (this.verifyDriverNumber(event.target.value, stateNameEqualTo)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'Invalid',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'isAlphaNumeric':
        if (this.isAlphaNumeric(event.target.value, stateName)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'This field is required',
            [stateName]: event.target.value,
          });
        }
        break;
      default:
        break;
    }
  }

  handleFromCity = selectedCity => {
    this.setState({
      selectedFromCity: selectedCity,
      fromCity: selectedCity.value,
    });
  };

  handleToCity = selectedCity => {
    this.setState({
      selectedToCity: selectedCity,
      toCity: selectedCity.value,
    });
  };

  // validates all required fields and returns a promise
  validateAllFields() {
    const { classes } = this.props;
    const {
      transporterName,
      truckerName,
      branch,
      fromCity,
      toCity,
      amount,
      commission,
      bthPayable,
      bthReceivable,
      athPayable,
      athReceivable,
      truckNumber,
      hamaliChargesTrucker,
      haltingChargesTrucker,
      loadingChargesTrucker,
      unloadingChargesTrucker,
      hamaliChargesTransporter,
      haltingChargesTransporter,
      loadingChargesTransporter,
      unloadingChargesTransporter,
      challanTransporterAmount,
      penaltyTransporterAmount,
      tdsTransporterAmount,
      docChargesTransporterAmount,
      fuelChargesTransporterAmount,
      maamolChargesTransporterAmount,
      challanTruckerAmount,
      penaltyTruckerAmount,
      tdsTruckerAmount,
      fuelChargesTruckerAmount,
      docChargesTruckerAmount,
      maamolChargesTruckerAmount,
      shortageChargesTruckerAmount,
      lateDeliveryTruckerAmount,
      latePodTruckerAmount,
      earlySettlementTruckerAmount,
    } = this.state;
    const { currentTransactionData } = this.props;
    let { limitPayable } = currentTransactionData;
    if (limitPayable === undefined) {
      limitPayable = false;
    }
    let error = false;
    let errorReason = '';
    return new Promise((resolve, reject) => {
      // Verify  Transporter Name

      if (this.verifyLength(transporterName, 1)) {
        this.setState({
          transporterNameState: 'success',
        });
      } else {
        this.setState({ transporterNameState: 'This field is required' });
        error = true;
        errorReason = ' transporterName validation fail';
      }

      // Verify  Trucker Name
      if (this.verifyLength(truckerName, 1)) {
        this.setState({
          truckerNameState: 'success',
        });
      } else {
        this.setState({ truckerNameState: 'This field is required' });
        error = true;
        errorReason = ' trucker name validation fail';
      }

      if (this.verifyLength(branch, 1)) {
        this.setState({
          branchState: 'success',
        });
      } else {
        this.setState({ branchState: 'This field is required' });
        error = true;
        errorReason = ' branch validation fail';
      }

      // Verify  From City
      if (this.verifyLength(fromCity, 1)) {
        this.setState({
          fromCityState: 'success',
        });
      } else {
        this.setState({ fromCityState: 'This field is required' });
        error = true;
        errorReason = ' from city validation fail';
      }

      // Verify  To City
      if (this.verifyLength(toCity, 1)) {
        this.setState({
          toCityState: 'success',
        });
      } else {
        this.setState({ toCityState: 'This field is required' });
        error = true;
        errorReason = ' to city validation fail';
      }

      // Verify  Amount
      if (this.verifyNumberAndGreater(amount, 1)) {
        this.setState({
          amountState: 'success',
        });
      } else {
        this.setState({
          alert: (
            <SweetAlert
              error
              style={{ display: 'block', marginTop: '-100px' }}
              title="Error"
              onConfirm={() => this.hideAlert()}
              confirmBtnCssClass={`${classes.button} ${classes.success}`}
            >
              The Freight Value must be a minimum of Rs 1.
            </SweetAlert>
          ),
          amountState: 'This field is required',
        });
        error = true;
        errorReason = ' freight validation fail';
      }

      // Verify  Commission
      if (this.verifyNumber(commission)) {
        this.setState({
          commissionState: 'success',
        });
      } else {
        this.setState({ commissionState: 'This field is required' });
        error = true;
        errorReason = ' commsion validation fail';
      }

      // Verify  BTH Payable
      if (this.verifyNumber(bthPayable)) {
        this.setState({
          bthPayableState: 'success',
        });
      } else {
        this.setState({ bthPayableState: 'This field is required' });
        error = true;
        errorReason = ' bth payable validation fail';
      }
      // Verify  Commission
      if (this.verifyNumber(bthReceivable)) {
        this.setState({
          bthReceivableState: 'success',
        });
      } else {
        this.setState({ bthReceivableState: 'This field is required' });
        error = true;
        errorReason = ' bth recievable validation fail';
      }
      // Verify  Commission
      if (this.verifyMaxNumber(athPayable, 199999)) {
        this.setState({
          athPayableState: 'success',
        });
      } else {
        this.setState({
          alert: (
            <SweetAlert
              error
              style={{ display: 'block', marginTop: '-100px' }}
              title="Error"
              onConfirm={() => this.hideAlert()}
              confirmBtnCssClass={`${classes.button} ${classes.success}`}
            >
              Trucker ATH Limit Exceeded
            </SweetAlert>
          ),
          athPayableState: 'Trucker ATH Limit Exceeded',
        });
        error = true;
        errorReason = ' ath payable validation fail';
      }
      // Verify  Commission
      if (this.verifyNumber(athReceivable)) {
        this.setState({
          athReceivableState: 'success',
        });
      } else {
        this.setState({ athReceivableState: 'This field is required' });
        error = true;
        errorReason = ' ath recievable validation fail';
      }
      // Verify  Truck Number
      if (this.verifyLength(truckNumber, 1)) {
        this.setState({ truckNumberState: 'success' });
      } else {
        this.setState({ truckNumberState: 'This field is required' });
        error = true;
        errorReason = ' trcuk number validation fail';
      }
      // Verify whether the amount is less the commission
      if (parseInt(amount, 10) < parseInt(commission, 10)) {
        this.setState({
          commissionState: 'This field is required',
        });
        error = true;
        errorReason = ' commision validation fail';
      }

      // Verify  hamaliChargesTrucker
      if (this.verifyNumber(hamaliChargesTrucker)) {
        this.setState({
          hamaliChargesTruckerState: 'success',
        });
      } else {
        this.setState({ hamaliChargesTruckerState: 'This field is required' });
        error = true;
        errorReason = ' hamaliChargesTrucker validation fail';
      }
      // Verify  haltingChargesTrucker
      if (this.verifyNumber(haltingChargesTrucker)) {
        this.setState({
          haltingChargesTruckerState: 'success',
        });
      } else {
        this.setState({ haltingChargesTruckerState: 'This field is required' });
        error = true;
        errorReason = ' haltingChargesTrucker validation fail';
      }
      // Verify  loadingChargesTrucker
      if (this.verifyNumber(loadingChargesTrucker)) {
        this.setState({
          loadingChargesTruckerState: 'success',
        });
      } else {
        this.setState({ loadingChargesTruckerState: 'This field is required' });
        error = true;
        errorReason = ' loadingChargesTrucker validation fail';
      }
      // Verify  unloadingChargesTrucker
      if (this.verifyNumber(unloadingChargesTrucker)) {
        this.setState({
          unloadingChargesTruckerState: 'success',
        });
      } else {
        this.setState({
          unloadingChargesTruckerState: 'This field is required',
        });
        error = true;
        errorReason = ' unloadingChargesTrucker validation fail';
      }
      // Verify  hamaliChargesTransporter
      if (this.verifyNumber(hamaliChargesTransporter)) {
        this.setState({
          hamaliChargesTransporterState: 'success',
        });
      } else {
        this.setState({
          hamaliChargesTransporterState: 'This field is required',
        });
        error = true;
        errorReason = ' hamaliChargesTransporter validation fail';
      }
      // Verify  haltingChargesTransporter
      if (this.verifyNumber(haltingChargesTransporter)) {
        this.setState({
          haltingChargesTransporterState: 'success',
        });
      } else {
        this.setState({
          haltingChargesTransporterState: 'This field is required',
        });
        error = true;
        errorReason = ' haltingChargesTransporter validation fail';
      }
      // Verify  loadingChargesTransporter
      if (this.verifyNumber(loadingChargesTransporter)) {
        this.setState({
          loadingChargesTransporterState: 'success',
        });
      } else {
        this.setState({
          loadingChargesTransporterState: 'This field is required',
        });
        error = true;
        errorReason = ' loadingChargesTransporter validation fail';
      }
      // Verify  unloadingChargesTransporter
      if (this.verifyNumber(unloadingChargesTransporter)) {
        this.setState({
          unloadingChargesTransporterState: 'success',
        });
      } else {
        this.setState({
          unloadingChargesTransporterState: 'This field is required',
        });
        error = true;
        errorReason = ' unloadingChargesTransporter validation fail';
      }
      // Verify  challanTransporterAmount
      if (this.verifyNumber(challanTransporterAmount)) {
        this.setState({
          challanTransporterAmountState: 'success',
        });
      } else {
        this.setState({
          challanTransporterAmountState: 'This field is required',
        });
        error = true;
        errorReason = ' challanTransporterAmount validation fail';
      }
      // Verify  penaltyTransporterAmount
      if (this.verifyNumber(penaltyTransporterAmount)) {
        this.setState({
          penaltyTransporterAmountState: 'success',
        });
      } else {
        this.setState({
          penaltyTransporterAmountState: 'This field is required',
        });
        error = true;
        errorReason = ' penaltyTransporterAmount validation fail';
      }
      // Verify  tdsTransporterAmount
      if (this.verifyNumber(tdsTransporterAmount)) {
        this.setState({
          tdsTransporterAmountState: 'success',
        });
      } else {
        this.setState({
          tdsTransporterAmountState: 'This field is required',
        });
        error = true;
        errorReason = ' tdsTransporterAmount validation fail';
      }
      // Verify  docChargesTransporterAmount
      if (this.verifyNumber(docChargesTransporterAmount)) {
        this.setState({
          docChargesTransporterAmountState: 'success',
        });
      } else {
        this.setState({
          docChargesTransporterAmountState: 'This field is required',
        });
        error = true;
        errorReason = ' docChargesTransporterAmount validation fail';
      }
      // Verify  fuelChargesTransporterAmount
      if (this.verifyNumber(fuelChargesTransporterAmount)) {
        this.setState({
          fuelChargesTransporterAmountState: 'success',
        });
      } else {
        this.setState({
          fuelChargesTransporterAmountState: 'This field is required',
        });
        error = true;
        errorReason = ' fuelChargesTransporterAmount validation fail';
      }
      // Verify  maamolChargesTransporterAmount
      if (this.verifyNumber(maamolChargesTransporterAmount)) {
        this.setState({
          maamolChargesTransporterAmountState: 'success',
        });
      } else {
        this.setState({
          maamolChargesTransporterAmountState: 'This field is required',
        });
        error = true;
        errorReason = ' maamolChargesTransporterAmount validation fail';
      }
      // Verify  challanTruckerAmount
      if (this.verifyNumber(challanTruckerAmount)) {
        this.setState({
          challanTruckerAmountState: 'success',
        });
      } else {
        this.setState({
          challanTruckerAmountState: 'This field is required',
        });
        error = true;
        errorReason = ' challanTruckerAmount validation fail';
      }
      // Verify  penaltyTruckerAmount
      if (this.verifyNumber(penaltyTruckerAmount)) {
        this.setState({
          penaltyTruckerAmountState: 'success',
        });
      } else {
        this.setState({
          penaltyTruckerAmountState: 'This field is required',
        });
        error = true;
        errorReason = ' penaltyTruckerAmount validation fail';
      }

      // Verify  penaltyTruckerAmount
      if (this.verifyNumber(tdsTruckerAmount)) {
        this.setState({
          tdsTruckerAmountState: 'success',
        });
      } else {
        this.setState({
          tdsTruckerAmountState: 'This field is required',
        });
        error = true;
        errorReason = ' tdsTruckerAmount validation fail';
      }

      // Verify  fuelChargesTruckerAmount
      if (this.verifyNumber(fuelChargesTruckerAmount)) {
        this.setState({
          fuelChargesTruckerAmountState: 'success',
        });
      } else {
        this.setState({
          fuelChargesTruckerAmountState: 'This field is required',
        });
        error = true;
        errorReason = ' fuelChargesTruckerAmount validation fail';
      }

      // Verify  docChargesTruckerAmount
      if (this.verifyNumber(docChargesTruckerAmount)) {
        this.setState({
          docChargesTruckerAmountState: 'success',
        });
      } else {
        this.setState({
          docChargesTruckerAmountState: 'This field is required',
        });
        error = true;
        errorReason = ' docChargesTruckerAmount validation fail';
      }

      // Verify  maamolChargesTruckerAmount
      if (this.verifyNumber(maamolChargesTruckerAmount)) {
        this.setState({
          maamolChargesTruckerAmountState: 'success',
        });
      } else {
        this.setState({
          maamolChargesTruckerAmountState: 'This field is required',
        });
        error = true;
        errorReason = ' maamolChargesTruckerAmount validation fail';
      }

      // Verify  shortageChargesTruckerAmount
      if (this.verifyNumber(shortageChargesTruckerAmount)) {
        this.setState({
          shortageChargesTruckerAmountState: 'success',
        });
      } else {
        this.setState({
          shortageChargesTruckerAmountState: 'This field is required',
        });
        error = true;
        errorReason = ' shortageChargesTruckerAmount validation fail';
      }

      // Verify  lateDeliveryTruckerAmount
      if (this.verifyNumber(lateDeliveryTruckerAmount)) {
        this.setState({
          lateDeliveryTruckerAmountState: 'success',
        });
      } else {
        this.setState({
          lateDeliveryTruckerAmountState: 'This field is required',
        });
        error = true;
        errorReason = ' lateDeliveryTruckerAmount validation fail';
      }

      // Verify  latePodTruckerAmount
      if (this.verifyNumber(latePodTruckerAmount)) {
        this.setState({
          latePodTruckerAmountState: 'success',
        });
      } else {
        this.setState({
          latePodTruckerAmountState: 'This field is required',
        });
        error = true;
        errorReason = ' latePodTruckerAmount validation fail';
      }

      // Verify  earlySettlementTruckerAmount
      if (this.verifyNumber(earlySettlementTruckerAmount)) {
        this.setState({
          earlySettlementTruckerAmountState: 'success',
        });
      } else {
        this.setState({
          earlySettlementTruckerAmountState: 'This field is required',
        });
        error = true;
        errorReason = ' earlySettlementTruckerAmount validation fail';
      }
      if (!error) {
        if (this.checkValues()) {
          resolve(true);
        } else {
          this.setState({
            alert: (
              <SweetAlert
                warning
                style={{ display: 'block', marginTop: '-100px' }}
                title="Warning"
                onConfirm={() => this.hideAlert(true)}
                onCancel={() => this.hideAlert(true)}
                confirmBtnCssClass={`${classes.button} ${classes.success}`}
              >
                Please verify your calculations.
              </SweetAlert>
            ),
          });
          // eslint-disable-next-line prefer-promise-reject-errors
          reject('Calculations do not match.');
        }
      } else {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject('validation Failed');
      }
    });
  }

  checkValues() {
    const {
      amount,
      commission,
      athPayable,
      bthPayable,
      athReceivable,
      bthReceivable,
    } = this.state;
    this.calculateFields();
    // Check Frieght = Receivable ATH + Receivable BTH
    // Check Freight = Payable ATH + Payable BTH + Commission
    if (
      Number(amount) !== Number(athReceivable) + Number(bthReceivable) ||
      Number(amount) !==
        Number(athPayable) + Number(bthPayable) + Number(commission)
    )
      return false;
    return true;
  }

  /**
   * Will Update the Transaction Serial
   */
  updateTransactionSerial() {
    const { transactionSerial } = this.state;
    const nextSerial = Number(transactionSerial) + 1;
    db.collection('Global')
      .doc('TransactionSerial')
      .update({ modifiedAt: new Date(), transactionSerial: nextSerial });
  }

  /**
   * Will udate the Dashboard Data To The DB
   * @function
   */
  updateDashboardData() {
    const { amount, companyId } = this.state;
    let {
      totalPayablePendingAmount,
      totalReceivablePendingAmount,
      commission,
      totalCommission,
    } = this.state;
    const currentPayableAmount = Number(amount) - Number(commission);
    const currentReceivableAmount = Number(amount);
    commission = Number(commission);
    totalPayablePendingAmount =
      Number(totalPayablePendingAmount) + Number(currentPayableAmount);
    totalReceivablePendingAmount =
      Number(totalReceivablePendingAmount) + Number(currentReceivableAmount);
    totalCommission = Number(totalCommission) + Number(commission);
    db.collection('Companies')
      .doc(companyId)
      .update({
        totalPayablePendingAmount,
        totalReceivablePendingAmount,
        totalCommission,
        modifiedAt: new Date(),
      })
      .then(() => {
        this.clearForm();
        this.setState({ isLoading: false });
      });
  }

  checkIsValidCalculations(transactionId) {
    const { classes } = this.props;
    // const tranRef = db.collection('Transactions').doc(transactionId);
    return new Promise((resolve, reject) => {
      let totalReceivedAmount = 0;
      let totalPaidAmount = 0;

      const transRef = db
        .collection('Transactions')
        .doc(transactionId)
        .get();
      transRef.then(doc => {
        const { totalReceivableAmount, totalPayableAmount } = doc.data();
        let calculatedCurrentReceivableAmount = totalReceivableAmount;
        let calculatedCurrentPayableAmount = totalPayableAmount;
        const recRef = db
          .collection('Transactions')
          .doc(transactionId)
          .collection('Receivable')
          .get();
        const payRef = db
          .collection('Transactions')
          .doc(transactionId)
          .collection('Payable')
          .get();
        recRef.then(receivableSnapshot => {
          receivableSnapshot.forEach(receivableDoc => {
            const { amount } = receivableDoc.data();
            if (!receivableDoc.data().void) {
              calculatedCurrentReceivableAmount -= Number(amount);
            }
          });
          payRef.then(payableSnapshot => {
            payableSnapshot.forEach(payableDoc => {
              const { amount } = payableDoc.data();
              if (
                !payableDoc.data().void &&
                payableDoc.data().status === 'success'
              ) {
                calculatedCurrentPayableAmount -= Number(amount);
              }
            });

            totalReceivedAmount =
              totalReceivableAmount - calculatedCurrentReceivableAmount;
            const newTotalReceivableAmount = this.state.totalReceivableAmount;
            const newCurrentReceivableAmount =
              newTotalReceivableAmount - totalReceivedAmount;

            totalPaidAmount =
              totalPayableAmount - calculatedCurrentPayableAmount;
            const newTotalPayableAmount = this.state.totalPayableAmount;
            const newCurrentPayableAmount =
              newTotalPayableAmount - totalPaidAmount;

            if (
              this.verifyNumber(newCurrentReceivableAmount) &&
              this.verifyNumber(newCurrentPayableAmount)
            ) {
              this.setState({
                newCurrentReceivableAmount,
                newCurrentPayableAmount,
              });
              resolve(true);
            } else {
              this.setState({
                alert: (
                  <SweetAlert
                    error
                    style={{ display: 'block', marginTop: '-100px' }}
                    title="Error"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={`${classes.button} ${classes.success}`}
                  >
                    Please Check your Calculaions
                  </SweetAlert>
                ),
              });
              // eslint-disable-next-line prefer-promise-reject-errors
              reject('error');
            }
          });
        });
      });
    });
  }

  capitalizeFirstLetter(str) {
    // converting first letter to uppercase
    const capitalized = str.charAt(0).toUpperCase() + str.slice(1);

    return capitalized;
  }

  /**
   * Submits the transporter details to the Database
   * @function
   */
  submitTransaction() {
    const {
      classes,
      currentTransactionData,
      userDetails,
      // systemDetails,
    } = this.props;
    const systemDetails = JSON.parse(localStorage.getItem('systemDetails'));

    const params = window?.location?.href?.split('/');
    const activeRole = params[3] === 'branch-ops' ? 'branchOps' : params[3];
    const {
      truckNumber,
      truckerId,
      transporterId,
      truckType,
      amount,
      commission,
      athReceivable,
      athPayable,
      bthReceivable,
      loadingDate,
      bthPayable,
      athReceivableDate,
      fromCity,
      toCity,
      transporterName,
      truckerName,

      loadingSubLocation,
      unloadingSubLocation,

      createdBy,
      createdByName,
      agentId,
      newCurrentReceivableAmount,
      newCurrentPayableAmount,
      address,
      addressUnloading,
      ledgerId,
      truckerPhoneNumber,
      truckerPOC,
      transporterPOC,
      truckPlacedBy,
      orderRecievedBy,
      tonnage,
      expectedDate,
      driverNumber,
      hamaliChargesTrucker,
      haltingChargesTrucker,
      loadingChargesTrucker,
      unloadingChargesTrucker,
      hamaliChargesTransporter,
      haltingChargesTransporter,
      loadingChargesTransporter,
      unloadingChargesTransporter,
      hamaliChargesPaidByTrucker,
      haltingChargesPaidByTrucker,
      loadingChargesPaidByTrucker,
      unloadingChargesPaidByTrucker,
      hamaliChargesPaidByTransporter,
      haltingChargesPaidByTransporter,
      loadingChargesPaidByTransporter,
      unloadingChargesPaidByTransporter,
      challanTransporterAmount,
      penaltyTransporterAmount,
      tdsTransporterAmount,
      docChargesTransporterAmount,
      fuelChargesTransporterAmount,
      maamolChargesTransporterAmount,
      challanTruckerAmount,
      penaltyTruckerAmount,
      tdsTruckerAmount,
      fuelChargesTruckerAmount,
      docChargesTruckerAmount,
      maamolChargesTruckerAmount,
      shortageChargesTruckerAmount,
      lateDeliveryTruckerAmount,
      latePodTruckerAmount,
      earlySettlementTruckerAmount,
      tdsTruckerShowinloadingslip,
      fuelChargesTruckerShowinloadingslip,
      docChargesTruckerShowinloadingslip,
      maamolChargesTruckerShowinloadingslip,
      shortageChargesTruckerShowinloadingslip,
      lateDeliveryTruckerShowinloadingslip,
      latePodTruckerShowinloadingslip,
      earlySettlementTruckerShowinloadingslip,
      transactionType,
      selectedManufacturer,
      supplyID,
      demandID,
      showTds,
      hardCodeTransactionNumber,
      latestTransactionNumber,
    } = this.state;

    let { selectedRisk } = this.state;

    if (expectedDate.isBefore(loadingDate, 'day')) {
      this.setState({
        alert: (
          <SweetAlert
            error
            style={{ display: 'block', marginTop: '-100px' }}
            title="Error"
            onConfirm={() => this.hideAlert()}
            confirmBtnCssClass={`${classes.button} ${classes.success}`}
          >
            Expected date cannot be before loading date.
          </SweetAlert>
        ),
        isLoading: false,
      });
      return;
    }
    if (selectedRisk === undefined) selectedRisk = 'green';
    const loadingDateFormat = loadingDate.format('MMM Do YY');
    const athReceivableDateFormat = athReceivableDate.format('MMM Do YY');

    // NOTE checking if transaction needs to be submitted for edit

    if (
      currentTransactionData.vehicleNumber !== truckNumber ||
      currentTransactionData.truckerId !== truckerId ||
      currentTransactionData.transporterId !== transporterId ||
      currentTransactionData.fromCity !== fromCity ||
      currentTransactionData.toCity !== toCity ||
      currentTransactionData.amount !== amount ||
      currentTransactionData.commission !== commission ||
      currentTransactionData.athReceivable !== athReceivable ||
      currentTransactionData.bthReceivable !== bthReceivable ||
      currentTransactionData.athPayable !== athPayable ||
      currentTransactionData.bthPayable !== bthPayable ||
      currentTransactionData.loadingDate !== loadingDateFormat ||
      currentTransactionData.athReceivableDate !== athReceivableDateFormat ||
      currentTransactionData.loadingChargesTransporter !==
        loadingChargesTransporter ||
      currentTransactionData.unloadingChargesTransporter !==
        unloadingChargesTransporter ||
      currentTransactionData.loadingChargesTrucker !== loadingChargesTrucker ||
      currentTransactionData.loadingChargesPaidByTrucker !==
        loadingChargesPaidByTrucker ||
      currentTransactionData.unloadingChargesTrucker !==
        unloadingChargesTrucker ||
      currentTransactionData.unloadingChargesPaidByTrucker !==
        unloadingChargesPaidByTrucker ||
      currentTransactionData.hamaliChargesTrucker !== hamaliChargesTrucker ||
      currentTransactionData.hamaliChargesTransporter !==
        hamaliChargesTransporter ||
      currentTransactionData.hamaliChargesPaidByTransporter !==
        hamaliChargesPaidByTransporter ||
      currentTransactionData.hamaliChargesPaidByTrucker !==
        hamaliChargesPaidByTrucker ||
      currentTransactionData.haltingChargesTrucker !== haltingChargesTrucker ||
      currentTransactionData.haltingChargesTransporter !==
        haltingChargesTransporter ||
      currentTransactionData.haltingChargesPaidByTransporter !==
        haltingChargesPaidByTransporter ||
      currentTransactionData.haltingChargesPaidByTrucker !==
        haltingChargesPaidByTrucker ||
      currentTransactionData.challanTransporterAmount !==
        challanTransporterAmount ||
      currentTransactionData.challanTruckerAmount !== challanTruckerAmount ||
      currentTransactionData.penaltyTransporterAmount !==
        penaltyTransporterAmount ||
      currentTransactionData.penaltyTruckerAmount !== penaltyTruckerAmount ||
      currentTransactionData.tdsTransporterAmount !== tdsTransporterAmount ||
      currentTransactionData.tdsTruckerAmount !== tdsTruckerAmount ||
      currentTransactionData.tdsTruckerShowinloadingslip !==
        tdsTruckerShowinloadingslip ||
      currentTransactionData.docChargesTransporterAmount !==
        docChargesTransporterAmount ||
      currentTransactionData.docChargesTruckerAmount !==
        docChargesTruckerAmount ||
      currentTransactionData.docChargesTruckerShowinloadingslip !==
        docChargesTruckerShowinloadingslip ||
      currentTransactionData.fuelChargesTransporterAmount !==
        fuelChargesTransporterAmount ||
      currentTransactionData.fuelChargesTruckerAmount !==
        fuelChargesTruckerAmount ||
      currentTransactionData.fuelChargesTruckerShowinloadingslip !==
        fuelChargesTruckerShowinloadingslip ||
      currentTransactionData.maamolChargesTransporterAmount !==
        maamolChargesTransporterAmount ||
      currentTransactionData.maamolChargesTruckerAmount !==
        maamolChargesTruckerAmount ||
      currentTransactionData.maamolChargesTruckerShowinloadingslip !==
        maamolChargesTruckerShowinloadingslip ||
      currentTransactionData.shortageChargesTruckerAmount !==
        shortageChargesTruckerAmount ||
      currentTransactionData.shortageChargesTruckerShowinloadingslip !==
        shortageChargesTruckerShowinloadingslip ||
      currentTransactionData.lateDeliveryTruckerAmount !==
        lateDeliveryTruckerAmount ||
      currentTransactionData.lateDeliveryTruckerShowinloadingslip !==
        lateDeliveryTruckerShowinloadingslip ||
      currentTransactionData.latePodTruckerAmount !== latePodTruckerAmount ||
      currentTransactionData.latePodTruckerShowinloadingslip !==
        latePodTruckerShowinloadingslip ||
      currentTransactionData.earlySettlementTruckerAmount !==
        earlySettlementTruckerAmount ||
      currentTransactionData.earlySettlementTruckerShowinloadingslip !==
        earlySettlementTruckerShowinloadingslip ||
      currentTransactionData.loadingSubLocation !== loadingSubLocation ||
      currentTransactionData.unloadingSubLocation !== unloadingSubLocation ||
      currentTransactionData.loadingChargesPaidByTrucker !==
        loadingChargesPaidByTrucker ||
      currentTransactionData.unloadingChargesPaidByTrucker !==
        unloadingChargesPaidByTrucker ||
      currentTransactionData.hamaliChargesPaidByTrucker !==
        hamaliChargesPaidByTrucker ||
      currentTransactionData.haltingChargesPaidByTrucker !==
        haltingChargesPaidByTrucker ||
      currentTransactionData.truckerPocName !== truckerPOC.label ||
      currentTransactionData?.transporterPocName !== transporterPOC.label ||
      currentTransactionData?.tonnage !== tonnage ||
      currentTransactionData?.expectedDate !==
        expectedDate.format('MMM Do YY') ||
      currentTransactionData?.loadingChargesPaidByTransporter !==
        loadingChargesPaidByTransporter ||
      currentTransactionData?.unloadingChargesPaidByTransporter !==
        unloadingChargesPaidByTransporter ||
      currentTransactionData?.hamaliChargesPaidByTransporter !==
        hamaliChargesPaidByTransporter ||
      currentTransactionData?.haltingChargesPaidByTransporter !==
        haltingChargesPaidByTransporter ||
      currentTransactionData?.loadingChargesPaidByTrucker !==
        loadingChargesPaidByTrucker ||
      currentTransactionData?.unloadingChargesPaidByTrucker !==
        unloadingChargesPaidByTrucker ||
      currentTransactionData?.hamaliChargesPaidByTrucker !==
        hamaliChargesPaidByTrucker ||
      currentTransactionData?.haltingChargesPaidByTrucker !==
        haltingChargesPaidByTrucker ||
      currentTransactionData?.docChargesTruckerShowinloadingslip !==
        docChargesTruckerShowinloadingslip ||
      currentTransactionData?.fuelChargesTruckerShowinloadingslip !==
        fuelChargesTruckerShowinloadingslip ||
      currentTransactionData?.maamolChargesTruckerShowinloadingslip !==
        maamolChargesTruckerShowinloadingslip ||
      currentTransactionData?.shortageChargesTruckerShowinloadingslip !==
        shortageChargesTruckerShowinloadingslip ||
      currentTransactionData?.lateDeliveryTruckerShowinloadingslip !==
        lateDeliveryTruckerShowinloadingslip ||
      currentTransactionData?.tdsTruckerShowinloadingslip !==
        tdsTruckerShowinloadingslip ||
      currentTransactionData?.earlySettlementTruckerShowinloadingslip !==
        earlySettlementTruckerShowinloadingslip ||
      currentTransactionData?.latePodTruckerShowinloadingslip !==
        latePodTruckerShowinloadingslip ||
      currentTransactionData?.expressTrip !== (transactionType === 'Express') ||
      currentTransactionData?.tatTrip !== (transactionType === 'TAT') ||
      currentTransactionData?.manufacturerId !== selectedManufacturer?.value ||
      currentTransactionData?.supplyId !== supplyID ||
      currentTransactionData?.demandId !== demandID
    ) {
      this.setState({
        isLoading: true,
      });

      const sendValueTotransporterTdsDeducted = () =>
        latestTransactionNumber < hardCodeTransactionNumber || showTds;

      // NOTE validating fields
      this.validateAllFields()
        .then(success => {
          if (success) {
            // NOTE validating calculations
            this.checkIsValidCalculations(currentTransactionData.transactionId)
              .then(correct => {
                const {
                  totalReceivableAmount,
                  netCommission,
                  totalPayableAmount,
                  athPayableAutoCalculated,
                  bthPayableAutoCalculated,
                } = this.state;

                const body = {
                  createdBy,
                  createdByName,
                  editedBy: userDetails.uid,
                  editedByName: userDetails.name,
                  agentId,
                  isEditedTransaction: true,
                  branch: currentTransactionData.branch,
                  transporterName,
                  truckerName,
                  fromCity,
                  toCity,
                  vehicleNumber: truckNumber,
                  truckType,
                  truckerId,
                  transporterId,
                  amount,
                  commission,
                  netCommission,
                  athPayable,
                  bthPayable,
                  athReceivable,
                  bthReceivable,

                  totalPayableAmount,
                  totalReceivableAmount,
                  currentPayableAmount: newCurrentPayableAmount,
                  currentReceivableAmount: newCurrentReceivableAmount,
                  loadingDate: loadingDate.format('MMM Do YY'),
                  athReceivableDate: athReceivableDate.format('MMM Do YY'),
                  originalTransactionId: currentTransactionData.transactionId,
                  transactionSerial: currentTransactionData.transactionSerial,
                  createdAt: currentTransactionData.createdAt,
                  athPayableAutoCalculated,
                  bthPayableAutoCalculated,
                  loadingSubLocation,
                  unloadingSubLocation,
                  loadingChargesPaidByTrucker,
                  unloadingChargesPaidByTrucker,
                  hamaliChargesPaidByTrucker,
                  haltingChargesPaidByTrucker,
                  loadingAddress: address,
                  unloadingAddress: addressUnloading,
                  ledgerId,
                  truckerPhoneNumber,
                  riskStatus: selectedRisk,
                  truckerPocName: truckerPOC.label,
                  truckerPocId: truckerPOC.value,
                  transporterPocId: transporterPOC.value,
                  transporterPocName: transporterPOC.label,
                  truckPlacedByName: truckPlacedBy.label,
                  truckPlacedById: truckPlacedBy.value,
                  orderRecievedByName: orderRecievedBy.label,
                  orderRecievedById: orderRecievedBy.value,
                  tonnage,
                  expectedDate: expectedDate.format('MMM Do YY'),
                  driversPhoneNumber: driverNumber,
                  hamaliChargesTrucker,
                  haltingChargesTrucker,
                  loadingChargesTrucker,
                  unloadingChargesTrucker,
                  hamaliChargesTransporter,
                  haltingChargesTransporter,
                  loadingChargesTransporter,
                  unloadingChargesTransporter,
                  hamaliChargesPaidByTransporter,
                  haltingChargesPaidByTransporter,
                  loadingChargesPaidByTransporter,
                  unloadingChargesPaidByTransporter,
                  challanTransporterAmount,
                  penaltyTransporterAmount,
                  tdsTransporterAmount,
                  docChargesTransporterAmount,
                  fuelChargesTransporterAmount,
                  maamolChargesTransporterAmount,
                  challanTruckerAmount,
                  penaltyTruckerAmount,
                  tdsTruckerAmount,
                  fuelChargesTruckerAmount,
                  docChargesTruckerAmount,
                  maamolChargesTruckerAmount,
                  shortageChargesTruckerAmount,
                  lateDeliveryTruckerAmount,
                  latePodTruckerAmount,
                  earlySettlementTruckerAmount,
                  tdsTruckerShowinloadingslip,
                  fuelChargesTruckerShowinloadingslip,
                  docChargesTruckerShowinloadingslip,
                  maamolChargesTruckerShowinloadingslip,
                  shortageChargesTruckerShowinloadingslip,
                  lateDeliveryTruckerShowinloadingslip,
                  latePodTruckerShowinloadingslip,
                  earlySettlementTruckerShowinloadingslip,
                  expressTrip: transactionType === 'Express',
                  tatTrip: transactionType === 'TAT',
                  transporterTdsDeducted: sendValueTotransporterTdsDeducted(),
                  manufacturerId: selectedManufacturer
                    ? selectedManufacturer?.value
                    : '',
                  manufacturerName: selectedManufacturer
                    ? selectedManufacturer?.label
                    : '',
                  supplyId: supplyID,
                  demandId: demandID,
                };
                let transactionRef;
                if (currentTransactionData.status === 'approved') {
                  addTransaction(body)
                    .then(res => {
                      const auditBody = {
                        data: {
                          ...body,
                        },
                        collection: 'Transactions',
                        updatedBy: userDetails.uid,
                        systemDetails,
                        type: 'Edited Transaction',
                        message: `${userDetails.email} edited Transaction #${currentTransactionData.transactionSerial}`,
                      };
                      auditLog(auditBody);

                      const auditPayload = {
                        collection: 'transaction',
                        orgId: null,
                        data: body,
                        message: `${userDetails?.name} with email id ${userDetails?.email} ediited a transaction with id ${currentTransactionData.transactionId}`,
                        systemDetails,
                        type: `Edit Transaction`,
                        role: `${this.capitalizeFirstLetter(
                          activeRole.split('is')[0]
                        )}`,
                        eventDateTime: new Date().toISOString(),
                        source: 'office',
                      };
                      auditLogger(auditPayload);
                      this.clearForm();
                      this.setState({ isLoading: false });
                    })
                    .catch(err => {
                      const errorBody = {
                        message: `${userDetails.email} tried to edit Transaction #${currentTransactionData.transactionSerial} but FAILED`,
                        status: 'fail',
                        error: err,
                        updatedBy: userDetails.uid,
                        errorMessage: err.message,
                        collection: 'Transactions',
                        systemDetails,
                      };
                      auditLog(errorBody);
                      const auditPayload = {
                        collection: 'transaction',
                        orgId: null,
                        data: body,
                        message: `${userDetails?.name} with email id ${userDetails?.email} ediited a transaction with id ${currentTransactionData.transactionId} but failed ${err}`,
                        systemDetails,
                        type: `Edit Transaction`,
                        role: `${this.capitalizeFirstLetter(
                          activeRole.split('is')[0]
                        )}`,
                        eventDateTime: new Date().toISOString(),
                        source: 'office',
                      };
                      auditLogger(auditPayload);
                      if (err.message === 'Parameter Missing') {
                        this.setState({
                          alert: (
                            <SweetAlert
                              error
                              style={{
                                display: 'block',
                                marginTop: '-100px',
                              }}
                              title="Error"
                              onConfirm={() => this.hideAlert()}
                              confirmBtnCssClass={`${classes.button} ${classes.success}`}
                            >
                              Please verify your calculations..
                            </SweetAlert>
                          ),
                        });
                      } else {
                        this.setState({
                          alert: (
                            <SweetAlert
                              error
                              style={{
                                display: 'block',
                                marginTop: '-100px',
                              }}
                              title="Error"
                              onConfirm={() => this.hideAlert()}
                              confirmBtnCssClass={`${classes.button} ${classes.success}`}
                            >
                              Oops! Something went wrong.
                            </SweetAlert>
                          ),
                        });
                      }
                      this.setState({ isLoading: false });
                    });
                } else if (currentTransactionData.status === 'modify') {
                  transactionRef = db.collection(`Transactions`);
                  transactionRef
                    .doc(currentTransactionData.transactionId)
                    .set(
                      {
                        createdBy,
                        createdByName,
                        editedBy: userDetails.uid,
                        editedByName: userDetails.name,
                        agentId,
                        transactionId: currentTransactionData.transactionId,
                        transactionSerial:
                          currentTransactionData.transactionSerial,
                        branch: currentTransactionData.branch,
                        comment: '',
                        transporterName,
                        truckerName,
                        truckerId,
                        transporterId,
                        fromCity,
                        toCity,
                        vehicleNumber: truckNumber,
                        truckType,
                        amount,
                        commission,
                        netCommission,
                        athPayable,
                        bthPayable,
                        athReceivable,
                        bthReceivable,
                        loadingDate: loadingDate.format('MMM Do YY'),
                        athReceivableDate: athReceivableDate.format(
                          'MMM Do YY'
                        ),
                        //  createdAt: new Date(), Dont change createdAt
                        modifiedAt: new Date(),
                        //  Do not overwrite the original transaction's currentPayable and CurrentReceivable amounts
                        currentPayableAmount: newCurrentPayableAmount,
                        currentReceivableAmount: newCurrentReceivableAmount,
                        totalPayableAmount,
                        totalReceivableAmount,
                        //  transactionSerial, Don't change the transaction serial No when modifying
                        status: 'pending', //  Change the status back to pending
                        //  companyId: this.state.companyId
                        athPayableAutoCalculated,
                        bthPayableAutoCalculated,
                        loadingSubLocation,
                        unloadingSubLocation,
                        loadingAddress: address,
                        unloadingAddress: addressUnloading,
                        ledgerId,
                        truckerPhoneNumber,
                      },
                      { merge: true }
                    )
                    .then(TransactionDoc => {
                      // Update Global Pending Payable and Receivable Amounts
                      //  this.updateDashboardData();
                      const auditBody = {
                        data: {
                          ...body,
                        },
                        collection: 'Transactions',
                        updatedBy: userDetails.uid,
                        systemDetails,
                        type: 'Modified Transaction',
                        message: `${userDetails.email} modified changes for Transaction #${currentTransactionData.transactionSerial}`,
                      };
                      auditLog(auditBody);
                      this.clearForm();
                      this.setState({ isLoading: false });
                    })
                    .catch(err => {
                      const errorBody = {
                        message: `${userDetails.email} tried to modify changes for Transaction #${currentTransactionData.transactionSerial} but FAILED`,
                        status: 'fail',
                        error: err,
                        updatedBy: userDetails.uid,
                        errorMessage: err.message,
                        collection: 'Transactions',
                        systemDetails,
                      };
                      auditLog(errorBody);
                    });
                }
              })
              .catch(err => {
                console.log('calculation Error', err);
                this.setState({
                  isLoading: false,
                });
              });
          } // success end
        })
        .catch(err => {
          console.log('err', err);
          this.setState({
            isLoading: false,
          });
        });
    } else {
      this.setState({
        alert: (
          <SweetAlert
            error
            style={{ display: 'block', marginTop: '-100px' }}
            title="Error"
            onConfirm={() => this.hideAlert(true)}
            confirmBtnCssClass={`${classes.button} ${classes.success}`}
          >
            Can't submit without any changes.
          </SweetAlert>
        ),
      });
    }
  }

  /**
   * Will Clears ALl the State values after form submision
   * @function
   */
  clearForm() {
    const { classes } = this.props;
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: 'block', marginTop: '-100px' }}
          title="Good job!"
          onConfirm={() => this.hideSuccessAlert()}
          onCancel={() => this.hideSuccessAlert()}
          confirmBtnCssClass={`${classes.button} ${classes.success}`}
        >
          Transaction Modified and Submitted Succeessfully
        </SweetAlert>
      ),
    });
    this.setState({
      transporterName: '',
      transporterId: '',
      truckerName: '',
      truckerId: '',
      fromCity: '',
      fromCityState: '',
      toCity: '',
      toCityState: '',
      vehicleNumber: '',
      vehicleNumberState: '',
      amount: '',
      amountState: '',
      commission: '',
      commissionState: '',
      truckType: '',
      truckTypeState: '',
      truckNumber: '',
      truckNumberState: '',
      isLoading: false,
      loadingCharges: '',
      loadingChargesState: '',
      bthReceivable: '',
      bthReceivableState: '',
      bthPayable: '',
      bthPayableState: '',
      athReceivable: '',
      athReceivableState: '',
      athPayable: '',
      athPayableState: '',
      haultingCharges: '',
      haultingChargesState: '',
      hamaliCharges: '0',
      hamaliChargesState: '0',
      loadingChargesPaidTransporter: false,
      unloadingChargesPaidTransporter: false,
      hamaliChargesPaidTransporter: false,
      haultingChargesPaidTransporter: false,
      munshiana: '0',
      munshianaState: '',
      penalty: '0',
      penaltyState: '',
      munshianaTrucker: false,
      munshianaTransporter: false,
      penaltyTrucker: false,
      penaltyTransporter: false,
      athPayableAutoCalculated: '0',
      bthPayableAutoCalculated: '0',
      ledgerId: '',
      truckerPhoneNumber: '',
    });
  }

  handleExistingTruckerData(truckerId, truckerName, truckNumber) {
    const { truckers } = this.state;
    for (const i in truckers) {
      if (truckers[i].truckerId === truckerId) {
        if (truckers[i].trucks) {
          const newTrucks = [];
          truckers[i].trucks.forEach(truck => {
            newTrucks.push({
              label: truck.truckNumber,
              value: truck.truckNumber,
              ...truck,
            });
          });
          this.setState(
            {
              trucks: newTrucks,
              truckNumber,
              selectedTruck: {
                value: truckNumber,
                label: truckNumber,
              },
            },
            () => {
              this.setState({
                truckNumber,
                selectedTruck: {
                  value: truckNumber,
                  label: truckNumber,
                },
              });
            }
          );
        }
        this.setState({
          truckerName: truckers[i].name,
        });
      }
    }
    this.setState({ truckerId });
  }

  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  getTruckerSecondaryContacts = async ID => {
    // setFetching(true);
    this.setState({
      fetching: true,
    });
    let CustomersData = [];
    db.collection('Customers')
      .where('orgId', '==', ID)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const { name, customerId } = doc.data();
          // doc.data() is never undefined for query doc snapshots
          CustomersData.push({ value: customerId, label: name });
        });
        // setCustomers(CustomersData);
        // setFetching(false);
        this.setState({
          truckerCustomers: CustomersData,
          fetching: false,
        });
      })
      .catch(error => {
        // setFetching(false);
        this.setState({
          fetching: false,
        });
        console.log('Error getting documents: ', error);
      });
  };

  handleTruckerPOC = customer => {
    this.setState({
      truckerPOC: customer,
    });
  };

  handleTrucker = selectedTrucker => {
    if (!selectedTrucker?.blacklisted) {
      this.setState({
        truckerPOC: [],
        truckerPOCEdit: true,
      });
      this.getTruckerSecondaryContacts(selectedTrucker.truckerId);
      if (selectedTrucker.trucks) {
        const newTruckTypes = [];
        selectedTrucker.trucks.forEach(truck => {
          newTruckTypes.push({
            label: truck.truckNumber,
            value: truck.truckNumber,
            ...truck,
          });
        });
        this.setState({
          trucks: newTruckTypes,
          truckType: '',
          truckNumber: '',
        });
      }
      this.setState({
        selectedTrucker,
        selectedTruck: null,
        truckerName: selectedTrucker.name,
        truckerId: selectedTrucker.truckerId,
        ledgerId: selectedTrucker.ledgerId,
        truckerPhoneNumber: selectedTrucker.phoneNumber,
      });
    }
    this.getTdsDeduction(selectedTrucker.truckerId);
  };

  async getTdsDeduction(truckerId, retries = 2) {
    const { classes } = this.props;
    const userDetails = firebase.auth().currentUser;
    const { Aa } = userDetails;
    const endPoint = `pipe/trucker-tds/status?trucker_id=${truckerId}`;
    try {
      const response = await apiCaller(
        endPoint,
        {},
        `Bearer ${Aa}`,
        'nh-base',
        'get'
      );
      const tdsDeclaration = response?.data?.trucker_tds_declaration_submitted;
      const validPanCheck = response?.data?.valid_pan;
      if (tdsDeclaration === false) {
        this.setState({
          tdsTruckerSubmitted: false,
        });
      }
      if (validPanCheck === false) {
        this.setState({
          validPan: false,
        });
        this.setState({
          alert: (
            <SweetAlert
              error
              style={{ display: 'block', marginTop: '-100px' }}
              title="Error"
              onConfirm={() => this.hideAlert()}
              confirmBtnCssClass={`${classes.button} ${classes.success}`}
            >
              Please update a Vaild PAN to continue using this Trucker.
            </SweetAlert>
          ),
        });
      }
    } catch (error) {
      console.error(error);

      this.setState({
        validPan: false,
      });

      if (retries > 0) {
        this.getTdsDeduction(truckerId, retries - 1);
      } else {
        this.setState({
          alert: (
            <SweetAlert
              error
              style={{ display: 'block', marginTop: '-100px' }}
              title="Error"
              onConfirm={() => this.hideAlert()}
              confirmBtnCssClass={`${classes.button} ${classes.success}`}
            >
              Something Went Wrong, Please Try Again!
            </SweetAlert>
          ),
        });
      }
    }
  }

  getTransporterSecondaryContacts = async ID => {
    // setFetching(true);
    this.setState({
      fetching: true,
    });
    let CustomersData = [];
    db.collection('Customers')
      .where('orgId', '==', ID)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const { name, customerId } = doc.data();
          // doc.data() is never undefined for query doc snapshots
          CustomersData.push({ value: customerId, label: name });
        });
        // setCustomers(CustomersData);
        // setFetching(false);
        this.setState({
          transporterCustomers: CustomersData,
          fetching: false,
        });
      })
      .catch(error => {
        // setFetching(false);
        this.setState({
          fetching: false,
        });
        console.log('Error getting documents: ', error);
      });
  };

  handleTransporterPOC = customer => {
    this.setState({
      transporterPOC: customer,
    });
  };

  handleTruckPlacedBy = traffic => {
    this.setState({ truckPlacedBy: traffic });
  };

  handleOrderRecievedBy = traffic => {
    this.setState({ orderRecievedBy: traffic });
  };

  handleTransporter = selectedTransporter => {
    this.tdsDeductedFlagHandler(selectedTransporter);

    if (!selectedTransporter?.blacklisted) {
      this.setState({
        transporterPOC: [],
        transporterPOCEdit: true,
      });
      this.setState({
        selectedTransporter,
        transporterName: selectedTransporter.name,
        transporterId: selectedTransporter.transporterId,
      });
      this.getTransporterSecondaryContacts(selectedTransporter.transporterId);
    }
  };

  handleTrucks = event => {
    this.setState({
      truckType: event.truckType,
      truckNumber: event.truckNumber,
      selectedTruck: event,
    });
  };

  handleCheckbox(stateName) {
    let current = this.state[stateName];
    current = !current;
    this.setState({ [stateName]: current }, this.calculateFields);
  }

  autoCalculated() {
    const { classes } = this.props;

    return (
      <Card>
        <CardHeader color="primary" icon>
          <h3 className={classes.cardIconTitle}>Auto Calculated</h3>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText="ATH Payable"
                id="athPayable"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  onChange: event => this.change(event, 'athPayable', 'number'),
                  disabled: true,
                  type: 'number',
                  value: this.state.athPayableAutoCalculated,
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
              <CustomInput
                labelText="BTH Payable"
                id="bthPayable"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  onChange: event => this.change(event, 'bthPayable', 'number'),
                  type: 'number',
                  disabled: true,
                  value: this.state.bthPayableAutoCalculated,
                }}
              />
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    );
  }

  deductions() {
    const { classes, currentTransactionData } = this.props;
    const handleDeductionsTransporter = (state, val) => {
      if (val) {
        if (this.verifyNumber(val)) {
          this.setState({ [state]: val, [`${state}Error`]: 'success' });
        } else {
          this.setState({ [state]: val, [`${state}Error`]: 'error' });
        }
      } else {
        this.setState({ [state]: val, [`${state}Error`]: '' });
      }
    };

    const handleDeductionsTrucker = (state, val) => {
      if (val) {
        if (this.verifyNumber(val)) {
          this.setState({ [state]: val, [`${state}Error`]: 'success' });
          setTimeout(() => {
            this.calculateFields();
          }, 1000);
        } else {
          this.setState({ [state]: val, [`${state}Error`]: 'error' });
        }
      } else {
        this.setState({ [state]: val, [`${state}Error`]: '' });
      }
    };
    const handleMoreDeductionsTrucker = (state, val) => {
      if (val) {
        if (this.verifyNumber(val)) {
          this.setState({ [state]: val, [`${state}Error`]: 'success' });
          setTimeout(() => {
            this.calculateFields();
          }, 1000);
        } else {
          this.setState({ [state]: val, [`${state}Error`]: 'error' });
        }
      } else {
        this.setState({ [state]: val, [`${state}Error`]: '' });
      }
    };
    return (
      <TripDeductionsModifyTransaction
        classes={classes}
        currentTransactionData={currentTransactionData}
        state={this.state}
        handleDeductionsTransporter={(state, val) =>
          handleDeductionsTransporter(state, val)
        }
        handleDeductionsTrucker={(state, val) =>
          handleDeductionsTrucker(state, val)
        }
        handleMoreDeductionsTrucker={(state, val) =>
          handleMoreDeductionsTrucker(state, val)
        }
      />
    );
  }

  otherExpenses() {
    const { classes, currentTransactionData } = this.props;

    const handleOtherExpensesByTrucker = (state, val) => {
      if (val) {
        if (this.verifyNumber(val)) {
          this.setState({ [state]: val, [`${state}Error`]: 'success' });
          setTimeout(() => {
            this.calculateFields();
          }, 1000);
        } else {
          this.setState({ [state]: val, [`${state}Error`]: 'error' });
        }
      } else {
        this.setState({ [state]: val, [`${state}Error`]: '' });
      }
    };
    const handleOtherExpensesByTransporter = (state, val) => {
      if (val) {
        if (this.verifyNumber(val)) {
          this.setState({ [state]: val, [`${state}Error`]: 'success' });
        } else {
          this.setState({ [state]: val, [`${state}Error`]: 'error' });
        }
      } else {
        this.setState({ [state]: val, [`${state}Error`]: '' });
      }
    };
    return (
      <OtherExpensesModifyTransaction
        classes={classes}
        currentTransactionData={currentTransactionData}
        handleOtherExpensesByTrucker={(state, val) =>
          handleOtherExpensesByTrucker(state, val)
        }
        handleOtherExpensesByTransporter={(state, val) =>
          handleOtherExpensesByTransporter(state, val)
        }
        states={this.state}
      />
    );
  }

  handlePaidByTruckerSwitch = name => event => {
    this.setState({ [name]: event.target.checked }, this.calculateFields);
  };

  getTransporterNames() {
    const { classes } = this.props;
    return (
      <FormControl fullWidth className={classes.selectFormControl}>
        <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
          Select Transporter
        </InputLabel>
        <Select
          MenuProps={{ className: classes.selectMenu }}
          classes={{ select: classes.select }}
          value={this.state.transporterId}
          onChange={this.handleTransporter}
          inputProps={{ name: 'transporterName', id: 'simple-select' }}
        >
          <MenuItem disabled classes={{ root: classes.selectMenuItem }}>
            Select Transporter
          </MenuItem>
          {this.state.transporters.map((transporter, i) => (
            <MenuItem
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value={transporter.transporterId}
            >
              {transporter.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    );
  }

  // Will Return select From cities component
  selectFromCities() {
    const { classes } = this.props;
    const { fromCitiesObject, selectedFromCity } = this.state;
    return (
      <div>
        <InputLabel className={classes.labelText}>Select From City</InputLabel>
        <ReactSelect
          value={selectedFromCity}
          onChange={this.handleFromCity}
          options={fromCitiesObject}
          placeholder="Select City"
        />
      </div>
    );
  }

  async tdsDeductedFlagHandler(selectedTransporter) {
    let { activeRole } = this?.props;
    const role = activeRole === 'branch-ops' ? 'BranchOps' : activeRole;
    const currentActiveRole = `is${role.charAt(0).toUpperCase()}${role.slice(
      1
    )}`;

    const userDetails = firebase.auth().currentUser;
    const { Aa: token } = userDetails;

    const endPoint = `pipe/check-tds-deduction?role=${currentActiveRole}&transporter_id=${selectedTransporter?.transporterId}`;

    const maxRetries = 2;

    const makeRequest = async attempt => {
      try {
        const response = await apiCaller(
          endPoint,
          {},
          `Bearer ${token}`,
          'nh-base',
          'get'
        );
        const value = response?.data?.is_tds_deduction;
        if (value === false) {
          this.setState({
            showTds: value,
          });
        }
      } catch (e) {
        if (attempt < maxRetries) {
          await makeRequest(attempt + 1);
        } else {
          console.error(e);
        }
      }
    };
    await makeRequest(1);
  }

  selectManufacturer() {
    const { classes } = this.props;
    const { manufacturerList, selectedManufacturer } = this.state;

    return (
      <div style={{ marginBottom: '10px' }}>
        <InputLabel className={classes.labelText}>
          Select Manufacturer/Warehouse
        </InputLabel>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ flex: 1 }}>
            <ReactSelect
              value={selectedManufacturer}
              options={manufacturerList}
              placeholder="Select Manufacturer/Warehouse"
              onChange={val => this.setState({ selectedManufacturer: val })}
            />
          </div>

          <AddManufacturer
            refetchList={() =>
              getManufacturerList().then(listData =>
                this.setState({ manufacturerList: listData })
              )
            }
          />
        </div>
      </div>
    );
  }

  // Will Return select From cities component
  selectToCities() {
    const { classes } = this.props;
    const { fromCitiesObject, selectedToCity } = this.state;
    return (
      <div>
        <InputLabel className={classes.labelText}>Select To City</InputLabel>
        <ReactSelect
          value={selectedToCity}
          onChange={this.handleToCity}
          options={fromCitiesObject}
          placeholder="Select City"
        />
      </div>
    );
  }

  goBack() {
    this.props.switchModifyPage(false);
  }

  loadingDateChange(loadingDate) {
    this.setState({ loadingDate });
  }

  athReceivableDateChange(athReceivableDate) {
    this.setState({ athReceivableDate });
  }

  expectedDateChange(Date) {
    this.setState({ expectedDate: Date });
  }

  handleChangeTransactionType(event) {
    this.setState({ transactionType: event.target.value });
  }

  handleTransactionStatusChange = event => {
    this.setState({ selectedRisk: event.target.value });
    const { currentTransactionData, userDetails } = this.props;
    const { transactionId } = currentTransactionData;
    const { name, uid } = userDetails;
    const transactionRef = db.collection('Transactions').doc(transactionId);
    const riskStatusHistoryRef = db
      .collection('Transactions')
      .doc(transactionId)
      .collection('RiskStatusHistory');

    transactionRef
      .update({
        riskStatus: event.target.value,
        updatedAt: new Date(),
      })
      .then(response => {
        this.setState({
          statusNotification: true,
          statusNotificationText: `Transaction Status Changed to ${event.target.value}`,
        });
        riskStatusHistoryRef
          .add({
            transactionId,
            riskStatus: event.target.value,
            createdByName: name,
            createdById: uid,
            createdAt: new Date(),
          })
          .catch(err => {
            console.log('Error ', err);
          });
      })
      .catch(err => {
        console.log('Error ', err);
        this.setState({
          statusNotification: true,
          statusNotificationText: `Something Went Wrong!, Try Again`,
        });
      });
  };

  selectTransactionStatus() {
    const { classes, currentTransactionData } = this.props;
    const { selectedRisk } = this.state;
    return (
      <FormControl fullWidth className={classes.selectFormControl}>
        {!currentTransactionData.void ? (
          <Select
            MenuProps={{ className: classes.selectMenu }}
            classes={{ select: classes.select }}
            value={selectedRisk}
            onChange={this.handleTransactionStatusChange}
            inputProps={{ name: 'multipleSelect', id: 'multiple-select' }}
          >
            <MenuItem disabled classes={{ root: classes.selectMenuItem }}>
              Transaction Status
            </MenuItem>
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value="green"
            >
              Green
            </MenuItem>
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value="amber"
            >
              Amber
            </MenuItem>
            <MenuItem
              classes={{
                root: classes.selectMenuItem,
                selected: classes.selectMenuItemSelected,
              }}
              value="red"
            >
              Red
            </MenuItem>
          </Select>
        ) : null}
      </FormControl>
    );
  }

  customStyles = {
    option: (provided, state) => ({
      ...provided,
      textDecoration: state.data.blacklisted ? 'line-through' : 'none',
      textDecorationThickness: state.data.blacklisted ? '2px' : 'none',
      color: state.data.blacklisted ? 'red' : provided.color,
    }),
  };

  render() {
    const { classes, currentTransactionData } = this.props;
    const {
      selectedTransporter,
      transporters,
      truckers,
      selectedTrucker,
      alert,
      transporterNameState,
      truckerNameState,
      fromCityState,
      toCityState,
      truckNumberState,
      truckType,
      truckTypeState,
      amount,
      amountState,
      commission,
      commissionState,
      athReceivable,
      athReceivableState,
      athPayable,
      athPayableState,
      bthReceivable,
      bthPayableState,
      loadingDate,
      bthPayable,
      athReceivableDate,
      bthReceivableState,
      isLoading,
      comment,
      trucks,
      selectedTruck,
      selectedRisk,
      statusNotification,
      statusNotificationText,
      transactionType,
      expectedDate,
      supplyID,
      supplyIDState,
      demandID,
      demandIDState,
      isValidatingID,
      isDemandValid,
      isSupplyValid,
      validPan,
    } = this.state;
    let cardHeaderColor = 'info';
    if (selectedRisk === 'green') {
      cardHeaderColor = 'success';
    } else if (selectedRisk === 'amber') {
      cardHeaderColor = 'warning';
    } else if (selectedRisk === 'red') {
      cardHeaderColor = 'danger';
    }
    const searchOptions = { componentRestrictions: { country: 'in' } };

    const now = moment();
    const startYear = now.month() < 3 ? now.year() - 1 : now.year();
    const endYear = startYear + 1;
    const financialYear = `FY ${startYear
      .toString()
      .slice(-2)}-${endYear.toString().slice(-2)}`;
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            {statusNotification && (
              <Snackbars
                place="tc"
                color="success"
                icon={AddAlert}
                message={statusNotificationText}
                open={statusNotification}
              />
            )}
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            {alert}
            <Card>
              <CardHeader
                className={classes.cardHeaderNoPadding}
                color={cardHeaderColor}
              >
                <CardIcon
                  color="primary"
                  className={`${classes.cardHeader}`}
                  onClick={() => this.goBack()}
                >
                  <ArrowBack />
                </CardIcon>
                <h3 className={`${classes.cardTitle}`}>
                  <span className={classes.rajdhaniFont}>
                    Modify Transaction #
                    {currentTransactionData.transactionSerial}
                  </span>
                </h3>
                <div
                  style={{ float: 'right', marginTop: '-50px', width: '30%' }}
                >
                  {this.selectTransactionStatus()}
                </div>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="transactionType"
                        name="transactionType"
                        value={transactionType}
                        onChange={this.handleChangeTransactionType}
                        row
                      >
                        <FormControlLabel
                          value="TAT"
                          control={<Radio color="primary" />}
                          label="Normal"
                        />
                        <FormControlLabel
                          value="Express"
                          control={<Radio color="primary" />}
                          label="TAT & Express"
                        />
                      </RadioGroup>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    {comment ? (
                      <SnackbarContent message={comment} color="warning" />
                    ) : null}
                  </GridItem>
                  <GridContainer spacing={2}>
                    <GridItem xs={12} sm={12} md={6}>
                      <div style={{ marginBottom: '10px' }}>
                        <InputLabel className={classes.labelText}>
                          Select Transporter
                        </InputLabel>
                        <ReactSelect
                          value={selectedTransporter}
                          onChange={this.handleTransporter}
                          options={transporters}
                          placeholder="Select Transporter"
                          styles={this.customStyles}
                          isDisabled={
                            this.state.latestTransactionNumber >
                            this.state.hardCodeTransactionNumber
                          }
                        />
                        {/* {this.getTransporterNames()} */}
                        {transporterNameState === 'Invalid' && (
                          <Danger>{transporterNameState}</Danger>
                        )}
                      </div>
                      {this.state.transporterPOCEdit && (
                        <div style={{ marginBottom: '10px' }}>
                          <InputLabel className={classes.labelText}>
                            Select Transporter POC
                          </InputLabel>
                          <ReactSelect
                            value={this.state.transporterPOC}
                            onChange={this.handleTransporterPOC}
                            options={this.state.transporterCustomers}
                            placeholder="Select Transporter POC"
                          />
                          {transporterNameState === 'Invalid' && (
                            <Danger>{transporterNameState}</Danger>
                          )}
                        </div>
                      )}
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      <div style={{ marginBottom: '10px' }}>
                        <InputLabel className={classes.labelText}>
                          Select Trucker
                        </InputLabel>
                        <ReactSelect
                          value={selectedTrucker}
                          onChange={this.handleTrucker}
                          options={truckers}
                          placeholder="Select Trucker"
                          styles={this.customStyles}
                        />
                        {truckerNameState === 'Invalid' && (
                          <Danger>{truckerNameState}</Danger>
                        )}
                        {this.state.tdsTruckerSubmitted === false && (
                          <Typography
                            style={{ fontSize: '12px', color: '#D14343' }}
                          >
                            TDS Declaration for {financialYear} not submitted
                          </Typography>
                        )}
                      </div>
                      {this.state.truckerPOCEdit && (
                        <div style={{ marginBottom: '10px' }}>
                          <InputLabel className={classes.labelText}>
                            Select Trucker POC
                          </InputLabel>
                          <ReactSelect
                            value={this.state.truckerPOC}
                            onChange={this.handleTruckerPOC}
                            options={this.state.truckerCustomers}
                            placeholder="Select Trucker POC"
                          />
                          {transporterNameState === 'Invalid' && (
                            <Danger>{transporterNameState}</Danger>
                          )}
                        </div>
                      )}
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      <div
                        style={{
                          marginBottom: '10px',
                          display: 'flex',
                          alignItems: 'baseline',
                        }}
                      >
                        <CustomInput
                          labelText="Demand ID"
                          id="demandId"
                          formControlProps={{ fullWidth: true }}
                          inputProps={
                            {
                              onChange: event =>
                                this.change(
                                  event,
                                  'demandID',
                                  'isAlphaNumeric'
                                ),

                              value: demandID,
                            } //  disabled: true,
                          }
                          success={
                            demandIDState === 'success' &&
                            isDemandValid === 'isValid'
                          }
                          error={
                            demandIDState === 'This field is required' ||
                            isDemandValid === 'isNotValid'
                          }
                        />
                        {isValidatingID === 'demandID' && (
                          <div>
                            <CircularProgress size={20} />
                          </div>
                        )}
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <div
                        style={{
                          marginBottom: '10px',
                          display: 'flex',
                          alignItems: 'baseline',
                        }}
                      >
                        <CustomInput
                          labelText="Supply ID"
                          id="supplyId"
                          formControlProps={{ fullWidth: true }}
                          inputProps={
                            {
                              onChange: event =>
                                this.change(
                                  event,
                                  'supplyID',
                                  'isAlphaNumeric'
                                ),

                              value: supplyID,
                            } //  disabled: true,
                          }
                          success={
                            supplyIDState === 'success' &&
                            isSupplyValid === 'isValid'
                          }
                          error={
                            supplyIDState === 'This field is required' ||
                            isSupplyValid === 'isNotValid'
                          }
                        />
                        {isValidatingID === 'supplyID' && (
                          <div>
                            <CircularProgress size={20} />
                          </div>
                        )}
                      </div>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      {this.selectFromCities()}
                      {fromCityState === 'Invalid' && (
                        <Danger>{fromCityState}</Danger>
                      )}
                    </GridItem>
                    {/* <GridItem xs={12} sm={12} md={6}>
                      {this.selectManufacturer()}
                    </GridItem> */}
                    <GridItem xs={12} sm={12} md={6}>
                      <InputLabel
                        htmlFor="simple-select"
                        className={classes.selectLabelSearch}
                      >
                        Select loading Point
                      </InputLabel>
                      <PlacesAutocomplete
                        value={this.state.address}
                        onChange={this.handleChange}
                        onSelect={this.handleSelect}
                        searchOptions={searchOptions}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div>
                            <input
                              {...getInputProps({
                                placeholder: 'Enter loading point',
                                className: 'location-search-input',
                              })}
                            />
                            <div className="autocomplete-dropdown-container">
                              {loading && <div>Loading...</div>}
                              {suggestions.map(suggestion => {
                                const className = suggestion.active
                                  ? 'suggestion-item--active'
                                  : 'suggestion-item';
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                  ? {
                                      backgroundColor: '#fafafa',
                                      cursor: 'pointer',
                                    }
                                  : {
                                      backgroundColor: '#ffffff',
                                      cursor: 'pointer',
                                    };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      {this.selectToCities()}
                      {toCityState === 'Invalid' && (
                        <Danger>{toCityState}</Danger>
                      )}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <InputLabel
                        htmlFor="simple-select"
                        className={classes.selectLabelSearch}
                      >
                        Select Unloading Point
                      </InputLabel>
                      <PlacesAutocomplete
                        value={this.state.addressUnloading}
                        onChange={this.handleChangeUnloading}
                        onSelect={this.handleSelectUnloading}
                        searchOptions={searchOptions}
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div>
                            <input
                              {...getInputProps({
                                placeholder: 'Enter unloading point',
                                className: 'location-search-input',
                              })}
                            />
                            <div className="autocomplete-dropdown-container">
                              {loading && <div>Loading...</div>}
                              {suggestions.map(suggestion => {
                                const className = suggestion.active
                                  ? 'suggestion-item--active'
                                  : 'suggestion-item';
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                  ? {
                                      backgroundColor: '#fafafa',
                                      cursor: 'pointer',
                                    }
                                  : {
                                      backgroundColor: '#ffffff',
                                      cursor: 'pointer',
                                    };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      className,
                                      style,
                                    })}
                                  >
                                    <span>{suggestion.description}</span>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <InputLabel className={classes.labelText}>
                        Select Trucks
                      </InputLabel>
                      <ReactSelect
                        value={selectedTruck}
                        onChange={this.handleTrucks}
                        options={trucks}
                        placeholder="Select Trucks"
                      />

                      {truckNumberState === 'Invalid' && (
                        <Danger>{truckNumberState}</Danger>
                      )}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Truck Type"
                        id="credit-limit"
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          value: truckType,
                        }}
                        success={truckTypeState === 'success'}
                        error={truckTypeState === 'Invalid'}
                      />
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Freight Value"
                        id="amountr"
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          onChange: event =>
                            this.change(event, 'amount', 'greater'),
                          type: 'greater',
                          value: amount,
                        }}
                        success={amountState === 'success'}
                        error={amountState === 'Invalid'}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Commission"
                        id="commission"
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          onChange: event =>
                            this.change(event, 'commission', 'number'),
                          type: 'number',
                          value: commission,
                        }}
                        success={commissionState === 'success'}
                        error={commissionState === 'Invalid'}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="ATH Receivable"
                        id="athReceivable"
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          onChange: event =>
                            this.change(event, 'athReceivable', 'number'),
                          type: 'number',
                          value: athReceivable,
                        }}
                        success={athReceivableState === 'success'}
                        error={athReceivableState === 'Invalid'}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="BTH Receivable"
                        id="bthReceivable"
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          disabled: true,
                          type: 'number',
                          value: bthReceivable,
                        }}
                        success={bthReceivableState === 'success'}
                        error={bthReceivableState === 'Invalid'}
                      />
                    </GridItem>

                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Trucker ATH"
                        id="athPayable"
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          onChange: event =>
                            this.change(event, 'athPayable', 'number'),
                          type: 'number',
                          disabled: true,
                          value: athPayable,
                        }}
                        success={athPayableState === 'success'}
                        error={athPayableState === 'Invalid'}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <CustomInput
                        labelText="Trucker BTH"
                        id="bthPayable"
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          onChange: event =>
                            this.change(event, 'bthPayable', 'number'),
                          type: 'number',
                          disabled: true,
                          value: bthPayable,
                        }}
                        success={bthPayableState === 'success'}
                        error={bthPayableState === 'Invalid'}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                      <InputLabel className={classes.label}>
                        Loading Date
                      </InputLabel>
                      <br />
                      <FormControl fullWidth>
                        <Datetime
                          onChange={this.loadingDateChange}
                          value={loadingDate}
                          timeFormat={false}
                          inputProps={{ placeholder: 'Select Date' }}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={3}>
                      <InputLabel className={classes.label}>
                        Expected ATH Receivable Date
                      </InputLabel>
                      <br />
                      <FormControl fullWidth>
                        <Datetime
                          onChange={this.athReceivableDateChange}
                          value={athReceivableDate}
                          timeFormat={false}
                          inputProps={{ placeholder: 'Select Date' }}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <InputLabel className={classes.label}>
                        Expected Delivery Date
                      </InputLabel>
                      <br />
                      <FormControl fullWidth>
                        <Datetime
                          onChange={this.expectedDateChange}
                          value={expectedDate}
                          timeFormat={false}
                          inputProps={{ placeholder: 'Select Date' }}
                        />
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <CustomInput
                        labelText="Tonnage"
                        id="tonnage"
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          onChange: event => {
                            const inputValue = event.target.value;
                            if (/^\d{0,2}(\.\d{0,2})?$/.test(inputValue)) {
                              this.change(event, 'tonnage', 'tonnage');
                            }
                          },
                          type: 'text',
                          value: this.state.tonnage,
                        }}
                        success={this.state.tonnageState === 'success'}
                        error={this.state.tonnageState === 'Invalid'}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <CustomInput
                        labelText="Driver Phone Number"
                        id="driverNumber"
                        formControlProps={{ fullWidth: true }}
                        inputProps={{
                          onChange: event =>
                            this.change(event, 'driverNumber', 'driverNumber'),

                          //  disabled: true,
                          value: this.state.driverNumber,
                        }}
                        success={this.state.driverNumberState === 'success'}
                        error={this.state.driverNumberState === 'Invalid'}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={5}>
                      {this.otherExpenses()}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={5}>
                      {this.deductions()}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      {this.autoCalculated()}
                    </GridItem>
                  </GridContainer>
                  {isLoading ? (
                    <CircularProgress
                      className={classes.progress}
                      style={{ color: purple[500] }}
                      thickness={7}
                    />
                  ) : !currentTransactionData.void ? (
                    <Button
                      color="info"
                      onClick={this.submitTransaction}
                      className={classes.updateProfileButton}
                      disabled={validPan === false}
                    >
                      Submit
                    </Button>
                  ) : null}
                  <Clearfix />
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}
ModifyTransaction.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  userDetails: state.main.userDetails,
  systemDetails: state.main.systemDetails,
  currentTransactionData: state.transactions.currentTransactionData,
  activeRole: state.main.activeRole,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      switchModifyPage,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(AddTruckersStyle)(ModifyTransaction));
