/* eslint-disable vars-on-top */
/* eslint-disable prefer-const */
/* eslint-disable eqeqeq */
/* eslint-disable no-var */
/* eslint-disable camelcase */
/* eslint-disable react/sort-comp */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-find-dom-node */
/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
// @material-ui/Icons
import ArrowBack from '@material-ui/icons/ArrowBack';
import Table from 'components/Table/Table.jsx';
import FormControl from '@material-ui/core/FormControl';
import Datetime from 'react-datetime';
import Refresh from '@material-ui/icons/Refresh';
// core components
// import Checkbox from '@material-ui/core/Checkbox';

import CheckIcon from '@material-ui/icons/Check';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Card from 'components/Card/Card.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import SweetAlert from 'react-bootstrap-sweetalert';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Snackbar from 'components/Snackbar/Snackbar.jsx';
import ReactDOM from 'react-dom';
import Done from '@material-ui/icons/Done';
import ReactSelect from 'react-select';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Accordion from 'components/Accordion/Accordion.jsx';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import InputLabel from '@material-ui/core/InputLabel';
import AddAlert from '@material-ui/icons/AddAlert';
import CircularProgress from '@material-ui/core/CircularProgress';
import purple from '@material-ui/core/colors/purple';
import Slide from '@material-ui/core/Slide';
import Close from '@material-ui/icons/Close';
// import FormControlLabel from '@material-ui/core/FormControlLabel';

import TransactionStyle from 'assets/jss/material-dashboard-pro-react/views/TransactionStyle';
import axios from 'axios';
import swal from 'sweetalert';
import Popup from 'reactjs-popup';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {
  Divider,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
} from '@material-ui/core';
import LoadingSlipDownload from './NewFields/LoadingSlipDownload';
import ConfirmationDoc from './NewFields/ConfirmationDoc.jsx';
import RatingsCard from './Ratings/RatingsCard';
import PayoutsTable from './PayoutsTable.jsx';
import {
  switchActivePage,
  setCurrentTransaction,
  setCurrentTransactionUnsubscribe,
} from '../../../reducers/transactions';
import PayableHistory from './PayableHistory';
import CommentSection from './CommentSection';
import SalesCommentSection from './NewFields/SalesCommentSection';
import PODCommentsSection from './NewFields/PODCommentsSection.jsx';
import GeneralCommentsSection from './NewFields/GeneralCommentsSection.jsx';
import VehicleStatus from './VehicleStatus';
import ReceivableHistory from './ReceivableHistory';
import firebase, { googleApiKey } from '../../../config/config';
import history from '../../../history';
import PODFileUpload from './PODFileUpload.jsx';
import DrivingLicenseUploader from './DrivingLicenseUploader.jsx';
import TrackingLink from './TrackingLink.jsx';
import NewFieldsContainer from './NewFields/NewFieldsContainer';
import EwayBillUpload from './NewFields/EwayBillUpload';
import FeatureFlagToastAlert from '../../Components/FeatureFlagToastAlert';
import { getViewURL } from '../../../utils/fileUploadHandler';
import TripDeductions from './TripCalculation/TripDeductionsView';
import apiCaller from '../../../utils/apiCallerOutstanding';

const moment = require('moment');

const contentStyle = { width: '300px' };

const GreenRadio = withStyles({
  root: {
    color: 'green', // Change the color to green
    '&$checked': {
      color: 'green', // Change the checked color to green
    },
  },
  checked: {},
})(props => <Radio color="default" {...props} />);
const deleteModalButton = {
  width: '45%',
  background: '#e63946',
  borderRadius: 0,
  color: 'white',
  margin: '5px',
};
const cancelButton = {
  width: '45%',
  background: 'lightgray',
  borderRadius: 0,
  margin: '5px',
};
const hyperlinkButton = {
  background: 'transparent',
  border: 'none',
  textDecoration: 'underline',
  margin: '0',
  padding: '0',
  color: 'blue',
  cursor: 'pointer',
};
const auditLog = firebase.functions().httpsCallable('utilities-auditLog');

const disabledRadioStyles = {
  pointerEvents: 'none', // Disable pointer events to make the Radio buttons non-clickable
  cursor: 'none',
};
const opsApproveTransaction = firebase
  .functions()
  .httpsCallable('transactions-approveTransaction');

const checkPayableAmount = firebase
  .functions()
  .httpsCallable('internalCalculations-checkPayableAmount');
const checkReceivableAmount = firebase
  .functions()
  .httpsCallable('internalCalculations-checkReceivableAmount');
const addCollectedDate = firebase
  .functions()
  .httpsCallable('transactions-addCollectedPod');
const addSubmittedDate = firebase
  .functions()
  .httpsCallable('transactions-addSubmittedPod');
const resetCollectedDate = firebase
  .functions()
  .httpsCallable('transactions-resetCollectedPod');
const resetSubmittedDate = firebase
  .functions()
  .httpsCallable('transactions-resetSubmittedPod');

const db = firebase.firestore();

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" {...props} ref={ref} />
));

class DetailedTransactions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      oldLoadingSubLocation: '',
      oldUnloadingSubLocation: '',
      address: '',
      addressUnloading: '',
      oldAddress: '',
      oldAddressUnloading: '',
      loadingSubLocation: '',
      unloadingSubLocation: '',
      value: 0,
      tl: false,
      tc: false,
      tr: false,
      bl: false,
      bc: false,
      br: false,
      classicModal: false,
      isDetailedForm: false,
      truckerName: '',

      transporterName: '',
      vehicleNumber: '',
      branch: '',
      fromCity: '',
      toCity: '',

      date: '',
      payableTransactions: [
        {
          color: 'info',
          data: ['28-09-2019', '10,000', 'message is'],
        },
      ],

      ifsc: '',
      bankName: '',

      bankBranchName: '',
      gstNumber: '',

      panNumber: '',
      name: '',

      truckerId: '',
      transporterId: '',

      truckType: '',
      amount: '',

      commission: '',
      athPayable: '',

      bthPayable: '',
      athReceivable: '',

      bthReceivable: '',
      munshiana: '',

      munshianaTrucker: '',
      munshianaTransporter: '',

      penalty: '',
      penaltyTransporter: '',

      penaltyTrucker: '',
      loadingCharges: '',
      unloadingCharges: '',

      loadingChargesPaidTransporter: '',
      unloadingChargesPaidTransporter: '',
      hamaliCharges: '',

      hamaliChargesPaidTransporter: '',
      haultingCharges: '',

      haultingChargesPaidTransporter: '',
      loadingDate: '',
      athReceivableDate: '',

      banksData: [], // For the Table
      banksDocs: [], // Bank Docs from DB

      checksAlert: false,
      checkOne: false,
      checkTwo: false,
      checksError: '',
      checksLoading: false,
      recentTransactionsLoading: false,
      recentTransactions: [],

      showEditDetails: false,
      contactPerson: ' ',
      tdsState: '',
      tdsTrucker: '',
      tdsTransporter: '',
      docCharges: '0',
      docChargesState: '',
      docChargesTrucker: '',
      docChargesTransporter: '',
      fuelCharges: '0',
      fuelChargesState: '',
      fuelChargesTrucker: '',
      fuelChargesTransporter: '',
      athPayableAutoCalculated: '',
      bthPayableAutoCalculated: '',
      athPayableAutoCalculatedEdited: '',
      bthPayableAutoCalculatedEdited: '',
      tdsDeductedAutoCalculatedEdited: '',
      showTds: false,
      hardCodeTransactionNumber: 95670,
      latestTransactionNumber: '',
      currentTransporterId: '',
      transporterContactPerson: '',
      truckerContactPerson: '',
      author: '',
      vehicleNumberEdited: false,
      truckerIdEdited: false,
      transporterIdEdited: false,
      loadingDateEdited: false,
      fromCityEdited: false,
      toCityEdited: false,
      athReceivableDateEdited: false,
      expectedDateEdited: false,
      amountEdited: false,
      commissionEdited: false,
      athReceivableEdited: false,
      bthReceivableEdited: false,
      athPayableEdited: false,
      bthPayableEdited: false,
      loadingChargesEdited: false,
      unloadingChargesEdited: false,
      loadingChargesPaidTransporterEdited: false,
      unloadingChargesPaidTransporterEdited: false,
      hamaliChargesEdited: false,
      hamaliChargesPaidTransporterEdited: false,
      haultingChargesEdited: false,
      haultingChargesPaidTransporterEdited: false,
      munshianaEdited: false,
      munshianaTruckerEdited: false,
      munshianaTransporterEdited: false,
      penaltyEdited: false,
      penaltyTransporterEdited: false,
      penaltyTruckerEdited: false,
      tdsEdited: false,
      tdsTruckerEdited: false,
      tdsTransporterEdited: false,
      docChargesEdited: false,
      docChargesTruckerEdited: false,
      docChargesTransporterEdited: false,
      fuelChargesEdited: false,
      fuelChargesTruckerEdited: false,
      fuelChargesTransporterEdited: false,
      maamolChargesTransporterEdited: false,
      maamolChargesTruckerEdited: false,
      loadingPointEdited: false,
      unLoadingPointEdited: false,
      isConfirmDialog: false,
      guidancePrice: 0,
      loadingChargesPaidByTruckerEdited: false,
      unloadingChargesPaidByTruckerEdited: false,
      hamaliChargesPaidByTruckerEdited: false,
      haltingChargesPaidByTruckerEdited: false,
      loadingChargesPaidByTrucker: false,
      unloadingChargesPaidByTrucker: false,
      hamaliChargesPaidByTrucker: false,
      haltingChargesPaidByTrucker: false,
      isFinanceApproved: false,
      collectedDate: '',
      submittedDate: '',
      isCollected: false,
      isSubmitted: false,
      isLoadingCollected: false,
      isLoadingSubmitted: false,
      submittedError: '',
      collectedError: '',
      txnExistsError: false,
      currentTransactionData: {
        isEditedTransaction: false,
      },
      showDetailed: false,
      isLoading: false,
      selectedRisk: null,
      truckerPocIdEditted: false,
      truckerPocNameEditted: false,
      transporterPocIdEditted: false,
      transporterPocNameEditted: false,
      editTruckerPOC: false,
      editTransporterPOC: false,
      transporterCustomers: [],
      truckerCustomers: [],
      fetching: false,
      transporterPOC: '',
      truckerPOC: '',
      updatingTransporterPOC: false,
      updatingTruckerPOC: false,
      settingVoid: false,
      truckPlacedByNameIsEdited: false,
      orderRecievedByNameIsEdited: false,
      tonnageEditted: false,
      driverPhoneNumberEditted: false,
      truckRCFileURL: '',
      featureFlagFiles: '',
      expectedDateEditted: false,
      shortageChargesTruckerEditted: false,
      lateDeliveryTruckerEditted: false,
      tdsTruckerEditted: false,
      earlySettlementTruckerEditted: false,
      latePodTruckerEditted: false,
      loadingChargesPaidByTransporterCheckedEditted: false,
      unloadingChargesPaidByTransporterCheckedEditted: false,
      hamaliChargesPaidByTransporterCheckedEditted: false,
      haltingChargesPaidByTransporterCheckedEditted: false,
      loadingChargesPaidByTruckerCheckedEditted: false,
      unloadingChargesPaidByTruckerCheckedEditted: false,
      hamaliChargesPaidByTruckerCheckedEditted: false,
      haltingChargesPaidByTruckerCheckedEditted: false,
      earlySettlementTruckerShowinloadingslipEditted: false,
      tdsTruckerShowinloadingslipEditted: false,
      lateDeliveryTruckerShowinloadingslipEditted: false,
      shortageChargesTruckerShowinloadingslipEditted: false,
      maamolChargesTruckerShowinloadingslipEditted: false,
      fuelChargesTruckerShowinloadingslipEditted: false,
      docChargesTruckerShowinloadingslipEditted: false,
      latePodTruckerShowinloadingslipEditted: false,
      transactionType: '',
      expressTripEditted: false,
      tatTripEdiited: false,
      currentManufacturerName: '',
      currentManufacturerID: '',
      manufacturerEditted: false,
      currentSupplyID: '',
      currentDemandID: '',
      demandIdEditted: false,
      supplyIdEditted: false,
      isTruckerBlacklisted: false,
      isTransporterBlacklisted: false,
      // earlySettlementTruckerShowinloadingslipEditted: false,
      // tdsTruckerShowinloadingslipEditted: false,
      // lateDeliveryTruckerShowinloadingslipEditted: false,
      // shortageChargesTruckerShowinloadingslipEditted: false,
      // maamolChargesTruckerShowinloadingslipEditted: false,
      // fuelChargesTruckerShowinloadingslipEditted: false,
    };

    this.hideDetailedForm = this.hideDetailedForm.bind(this);
    this.showTruckerDetails = this.showTruckerDetails.bind(this);

    this.otherExpenses = this.otherExpenses.bind(this);
    this.deductions = this.deductions.bind(this);

    this.closeAlert = this.closeAlert.bind(this);
    this.closeChecksAlert = this.closeChecksAlert.bind(this);
    this.showChecksAlert = this.showChecksAlert.bind(this);
    this.finishChecks = this.finishChecks.bind(this);
    this.approveTransaction = this.approveTransaction.bind(this);

    this.modifyTransaction = this.modifyTransaction.bind(this);
    this.inputAlert = this.inputAlert.bind(this);
    this.rejectTransaction = this.rejectTransaction.bind(this);

    this.submitCollected = this.submitCollected.bind(this);
    this.submitSubmitted = this.submitSubmitted.bind(this);

    this.submittedDateChange = this.submittedDateChange.bind(this);
    this.collectedDateChange = this.collectedDateChange.bind(this);

    this.resetCollected = this.resetCollected.bind(this);
    this.resetSubmitted = this.resetSubmitted.bind(this);
  }

  componentDidMount() {
    this.setState({
      isLoading: true,
    });
    this.getFeatureFlagStatus();
    const transactionId = this.props.match.params.txnId;
    const transactionsRef = db.collection(`Transactions`).doc(transactionId);
    transactionsRef.get().then(transactionSnap => {
      if (transactionSnap.exists) {
        const currentTransactionData = transactionSnap.data();
        this.props.setCurrentTransaction(
          currentTransactionData,
          currentTransactionData.currentPayableAmount,
          currentTransactionData.currentReceivableAmount
        );

        this.setState(
          {
            currentTransactionData,
            isLoading: false,
            showDetailed: true,
          },
          () => {
            // Check if transactionSerial is greater than or equal to hardCodeTransactionNumber
            if (
              currentTransactionData.transactionSerial >=
              this.state.hardCodeTransactionNumber
            ) {
              this.tdsDeductedFlagHandler();
            }
            this.initializeTransactionData();
            this.getTransporterSecondaryContacts();
            this.getTruckerSecondaryContacts();

            if (this.state.featureFlagFiles === 2) {
              this.getTruckRCURLFromS3();
            } else if (this.state.featureFlagFiles === 0) {
              this.getTruckRCURL();
            }

            // Call handleGetTrucker with currentTransactionData from state
            this.handleGetTrucker(currentTransactionData);
            this.handleGetTransporter(currentTransactionData);
          }
        );
      } else {
        this.setState({
          txnExistsError: true,
          isLoading: false,
        });
      }
    });
  }

  async handleGetTrucker(currentTransactionData) {
    const { truckerId } = currentTransactionData;

    const userDetails = firebase.auth().currentUser;

    const { Aa } = userDetails;
    const token = Aa;
    try {
      const endPoint = `pipe/office-user-validation?id=${truckerId}&type=blacklist&source=trucker`;
      const response = await apiCaller(
        endPoint,
        {},
        `Bearer ${token}`,
        'nh-base',
        'get'
      );
      const data = response?.data;

      if (data.blacklist === true) {
        this.setState({ isTruckerBlacklisted: true });
      } else {
        this.setState({ isTruckerBlacklisted: false });
      }
    } catch (e) {
      console.error(e);
    }
  }

  async handleGetTransporter(currentTransactionData) {
    const { transporterId } = currentTransactionData;

    const userDetails = firebase.auth().currentUser;

    const { Aa } = userDetails;
    const token = Aa;
    try {
      const endPoint = `pipe/office-user-validation?id=${transporterId}&type=blacklist&source=transporter`;
      const response = await apiCaller(
        endPoint,
        {},
        `Bearer ${token}`,
        'nh-base',
        'get'
      );
      const data = response?.data;

      if (data.blacklist === true) {
        this.setState({ isTransporterBlacklisted: true });
      } else {
        this.setState({ isTransporterBlacklisted: false });
      }
    } catch (e) {
      console.error(e);
    }
  }

  getFeatureFlagStatus() {
    db.collection('FeatureFlags')
      .doc('FileUploadsFeature')
      .onSnapshot(doc => {
        if (doc.exists) {
          const data = doc.data();
          this.setState({ featureFlagFiles: data?.transactionFiles });
        }
      });
  }

  getTruckRCURLFromS3 = async ID => {
    // setFetching(true);
    this.setState({
      fetching: true,
    });
    const viewURLBody = {
      scope: 'truck',
      type: 'view',
      file_type: 'truck-rc',
      trucker_id: this.state.currentTransactionData.truckerId,
      truck_number: this.state.currentTransactionData.vehicleNumber,
    };
    getViewURL(viewURLBody)
      .then(result => {
        this.setState({ truckRCFileURL: result?.data?.url, fetching: false });
      })
      .catch(e =>
        this.setState({
          fetching: false,
        })
      );
  };

  getTransporterSecondaryContacts = async ID => {
    // setFetching(true);
    this.setState({
      fetching: true,
    });
    let CustomersData = [];
    db.collection('Customers')
      .where('orgId', '==', this.state.currentTransactionData.transporterId)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const { name, customerId } = doc.data();
          // doc.data() is never undefined for query doc snapshots
          CustomersData.push({ value: customerId, label: name });
        });
        // setCustomers(CustomersData);
        // setFetching(false);
        this.setState({
          transporterCustomers: CustomersData,
          fetching: false,
        });
      })
      .catch(error => {
        // setFetching(false);
        this.setState({
          fetching: false,
        });
      });
  };

  getTruckRCURL = async ID => {
    // setFetching(true);
    this.setState({
      fetching: true,
    });

    db.collection('FileUploads')
      .doc('Truck_Files')
      .collection('RC')
      .doc(this.state.currentTransactionData.truckerId)
      .get()
      .then(doc => {
        if (doc.exists) {
          const truckNumber = this.state.currentTransactionData.vehicleNumber;
          const data = doc.data();
          const { rcFileUploadURL } = data[truckNumber];

          this.setState({ truckRCFileURL: rcFileUploadURL });
        } else {
          // doc.data() will be undefined in this case
          this.setState({ truckRCFileURL: '' });
        }
        this.setState({
          fetching: false,
        });
      })
      .catch(error => {
        this.setState({
          fetching: false,
          truckRCFileURL: '',
        });
      });
  };

  getTruckerSecondaryContacts = async ID => {
    // setFetching(true);
    this.setState({
      fetching: true,
    });
    let CustomersData = [];
    db.collection('Customers')
      .where('orgId', '==', this.state.currentTransactionData.truckerId)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          const { name, customerId } = doc.data();
          // doc.data() is never undefined for query doc snapshots
          CustomersData.push({ value: customerId, label: name });
        });
        // setCustomers(CustomersData);
        // setFetching(false);
        this.setState({
          truckerCustomers: CustomersData,
          fetching: false,
        });
      })
      .catch(error => {
        // setFetching(false);
        this.setState({
          fetching: false,
        });
      });
  };

  getTransactionData() {
    const transactionId = this.props.match.params.txnId;
    const transactionsRef = db.collection(`Transactions`).doc(transactionId);
    transactionsRef.get().then(transactionSnap => {
      if (transactionSnap.exists) {
        this.props.setCurrentTransaction(
          transactionSnap.data(),
          transactionSnap.data().currentPayableAmount,
          transactionSnap.data().currentReceivableAmount
        );
        this.setState(
          {
            currentTransactionData: transactionSnap.data(),
            isLoading: false,
            showDetailed: true,
          },
          () => this.initializeTransactionData()
        );
      } else {
        this.setState({
          txnExistsError: true,
          isLoading: false,
        });
      }
    });
  }

  initializeTransactionData() {
    const { currentTransactionData } = this.state;
    let { riskStatus } = currentTransactionData;
    if (riskStatus === undefined) riskStatus = 'green';
    this.setState({
      selectedRisk: riskStatus,
    });
    this.getSubLocation();
    this.getCollectedData();
    // eslint-disable-next-line react/no-find-dom-node
    ReactDOM.findDOMNode(this).scrollIntoView();
    this.getTruckerDetails();
    this.getTransporterDetails();
    if (currentTransactionData.isCollected) {
      this.setState({
        collectedDate: currentTransactionData.collectedDate,
        isCollected: true,
      });
    }
    if (currentTransactionData.isSubmitted) {
      this.setState({
        submittedDate: currentTransactionData.submittedDate,
        isSubmitted: true,
      });
    }
    if (currentTransactionData.isEditedTransaction === false) {
      if (currentTransactionData.agentId) {
        db.collection('Users')
          .doc(currentTransactionData.agentId)
          .get()
          .then(authorDetail => {
            if (authorDetail.exists) {
              this.setState({
                author: authorDetail.data().name,
              });
            }
          });
      }
    }
    if (currentTransactionData.isEditedTransaction === true) {
      if (currentTransactionData.editedByName) {
        this.setState({ author: currentTransactionData.editedByName });
      }
      db.collection('Transactions')
        .doc(currentTransactionData.originalTransactionId)
        .get()
        .then(doc => {
          this.getOldSubLocations(doc.data());
          /* New Edit Details are put in the state */
          this.setState(
            {
              showEditDetails: true,
              ...doc.data(),
            },
            this.calculateFields
          );
          const transactionData = doc.data();
          this.setState({
            currentManufacturerName: transactionData?.manufacturerName || '',
            currentManufacturerID: transactionData?.manufacturerId || '',
            currentSupplyId: transactionData?.supplyId || '',
            currentDemandId: transactionData?.demandId || '',
          });
          this.findEditedTransaction(doc.data());
        });
    } else {
      const checkBody = {
        id: currentTransactionData.transactionId,
      };
      checkReceivableAmount(checkBody);
      checkPayableAmount(checkBody);
      this.calculateFields();
    }
  }

  getCollectedData() {
    setTimeout(() => {}, 2000);
  }

  componentWillUnmount() {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.switchActivePage(false);
    try {
      //  Called Snapshot Unsubscribe function to stop receiving updates for this transaction.
      // eslint-disable-next-line react/destructuring-assignment
      this.props.unsubscribeTransaction();
    } catch {
      // Snapshot Unsubscribe function may be null, or has already been run
      // eslint-disable-next-line react/destructuring-assignment
      this.props.setCurrentTransactionUnsubscribe(() => null);
    }
  }

  getSubLocation() {
    const { currentTransactionData } = this.state;
    let loadingLat = '';
    let loadingLong = '';
    let unloadingLat = '';
    let unloadingLong = '';
    const {
      loadingAddress,
      unloadingAddress,
      loadingSubLocation,
      unloadingSubLocation,
      transactionId,
    } = currentTransactionData;
    if (
      loadingSubLocation !== undefined &&
      loadingSubLocation !== '' &&
      loadingSubLocation
    ) {
      loadingLat = loadingSubLocation.lat;
      loadingLong = loadingSubLocation.lng;
    }
    if (
      unloadingSubLocation !== undefined &&
      unloadingSubLocation !== '' &&
      unloadingSubLocation
    ) {
      unloadingLat = unloadingSubLocation.lat;
      unloadingLong = unloadingSubLocation.lng;
    }
    if (loadingAddress !== undefined) {
      this.setState({
        address: loadingAddress,
      });
    } else if (
      loadingLat !== '' &&
      loadingLong !== '' &&
      loadingLat !== undefined &&
      loadingLong !== undefined
    ) {
      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${loadingLat},${loadingLong}&key=${googleApiKey}`
      )
        .then(res => res.json())
        .then(result => {
          this.updateLoadingAddress(
            result?.results[0]?.formatted_address,
            transactionId
          );
          this.setState({
            address: result?.results[0]?.formatted_address,
          });
        });
    }
    if (unloadingAddress !== undefined) {
      this.setState({
        addressUnloading: unloadingAddress,
      });
    } else if (
      unloadingLat !== '' &&
      unloadingLong !== '' &&
      unloadingLat !== undefined &&
      unloadingLong !== undefined
    ) {
      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${unloadingLat},${unloadingLong}&key=${googleApiKey}`
      )
        .then(res => res.json())
        .then(result => {
          this.updateUnloadingAddress(
            result.results[0].formatted_address,
            transactionId
          );
          this.setState({
            addressUnloading: result.results[0].formatted_address,
          });
        });
    }
  }

  updateLoadingAddress(loadingAddress, transactionId) {
    const addLoadingAddress = firebase
      .functions()
      .httpsCallable('transactions-addLoadingAddressString');
    const body = {
      transactionId,
      loadingAddress,
    };
    addLoadingAddress(body);
  }

  updateUnloadingAddress(unloadingAddress, transactionId) {
    const addUnloadingAddress = firebase
      .functions()
      .httpsCallable('transactions-addUnloadingAddressString');
    const body = {
      transactionId,
      unloadingAddress,
    };
    addUnloadingAddress(body);
  }

  getOldSubLocations(oldTransaction) {
    const { currentTransactionData } = this.state;
    let oldLoadingLat = '';
    let oldLoadingLong = '';
    let oldUnloadingLat = '';
    let oldUnloadingLong = '';
    const {
      loadingAddress,
      unloadingAddress,
      loadingSubLocation,
      unloadingSubLocation,
    } = oldTransaction;
    /* New Edit Details are put in the state */
    if (loadingSubLocation && currentTransactionData.loadingSubLocation) {
      if (
        loadingSubLocation.lat !== undefined &&
        currentTransactionData.loadingSubLocation.lat !== undefined &&
        loadingSubLocation.lng !== undefined &&
        currentTransactionData.loadingSubLocation.lng !== undefined
      ) {
        if (
          loadingSubLocation.lat !==
            currentTransactionData.loadingSubLocation.lat &&
          loadingSubLocation.lng !==
            currentTransactionData.loadingSubLocation.lng
        ) {
          this.setState({
            loadingPointEdited: true,
          });
        } else {
          this.setState({ loadingPointEdited: false });
        }
      }
    }
    if (unloadingSubLocation && currentTransactionData.unloadingSubLocation) {
      if (
        unloadingSubLocation.lat !== undefined &&
        currentTransactionData.unloadingSubLocation.lat !== undefined &&
        unloadingSubLocation.lng !== undefined &&
        currentTransactionData.unloadingSubLocation.lng !== undefined
      ) {
        if (
          unloadingSubLocation.lat !==
            currentTransactionData.unloadingSubLocation.lat &&
          unloadingSubLocation.lng !==
            currentTransactionData.unloadingSubLocation.lng
        ) {
          this.setState({
            unLoadingPointEdited: true,
          });
        } else {
          this.setState({ unLoadingPointEdited: false });
        }
      }
    }
    if (
      loadingSubLocation !== undefined &&
      loadingSubLocation !== '' &&
      loadingSubLocation
    ) {
      oldLoadingLat = loadingSubLocation.lat;
      oldLoadingLong = loadingSubLocation.lng;
    }
    if (
      unloadingSubLocation !== undefined &&
      unloadingSubLocation !== '' &&
      unloadingSubLocation
    ) {
      oldUnloadingLat = unloadingSubLocation.lat;
      oldUnloadingLong = unloadingSubLocation.lng;
    }

    if (loadingAddress !== undefined) {
      this.setState({
        oldAddress: unloadingAddress,
      });
    } else if (
      oldLoadingLat !== '' &&
      oldLoadingLong !== '' &&
      oldLoadingLat !== undefined &&
      oldLoadingLong !== undefined
    ) {
      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${oldLoadingLat},${oldLoadingLong}&key=${googleApiKey}`
      )
        .then(res => res.json())
        .then(result => {
          this.setState({
            oldAddress: result.results[0].formatted_address,
          });
        });
    }

    if (unloadingAddress !== undefined) {
      this.setState({
        oldAddressUnloading: unloadingAddress,
      });
    } else if (
      oldUnloadingLat !== '' &&
      oldUnloadingLong !== '' &&
      oldUnloadingLat !== undefined &&
      oldUnloadingLong !== undefined
    ) {
      fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${oldUnloadingLat},${oldUnloadingLong}&key=${googleApiKey}`
      )
        .then(res => res.json())
        .then(result => {
          this.setState({
            oldAddressUnloading: result.results[0].formatted_address,
          });
        });
    }
  }

  // eslint-disable-next-line react/sort-comp
  handleClickOpen(modal) {
    const x = [];
    x[modal] = true;
    this.setState(x);
  }

  handleClose(modal) {
    const x = [];
    x[modal] = false;
    this.setState(x);
  }

  getTransporterDetails() {
    const { currentTransactionData } = this.state;
    const { transporterId } = currentTransactionData;
    return db
      .collection('Transporters')
      .doc(transporterId)
      .get()
      .then(transporterDoc => {
        if (transporterDoc.exists) {
          const { contactPerson } = transporterDoc.data();
          this.setState({
            transporterContactPerson: contactPerson,
            contactPerson,
          });
        }
      });
  }

  getTruckerDetails() {
    const { currentTransactionData } = this.state;
    const { truckerId } = currentTransactionData;
    const truckersBankRef = db
      .collection('Truckers')
      .doc(truckerId)
      .collection('Banks');
    truckersBankRef.get().then(banks => {
      if (!banks.empty) {
        const banksData = [];
        const banksDocs = [];
        banks.forEach(bankDetailsResult => {
          const bankDetails = bankDetailsResult.data();
          banksDocs.push(bankDetails);
          banksData.push({
            color: 'success',
            data: [
              bankDetails.bankHolderName,
              bankDetails.accountNumber,
              bankDetails.bankName,
              bankDetails.ifsc,
              bankDetails.bankBranchName,
              bankDetails.bankAccountActive && (
                <Done style={{ color: '#43a047 ', alignItems: 'center' }} />
              ),
            ],
          });
        });
        this.setState({ banksData, banksDocs });
      }
    });
    return db
      .collection('Truckers')
      .doc(truckerId)
      .get()
      .then(truckerDoc => {
        if (truckerDoc.exists) {
          const {
            name,
            ifsc,
            bankName,
            bankBranchName,
            gstNumber,
            panNumber,
            contactPerson,
          } = truckerDoc.data();
          this.setState({
            name,
            ifsc,
            bankName,
            bankBranchName,
            gstNumber,
            panNumber,
            truckerContactPerson: contactPerson,
          });
        }
      });
  }

  /**
   * Will return the Bank Details Component
   * @function
   */
  getBanks() {
    const { classes } = this.props;
    const { banksData, banksState } = this.state;
    return (
      <Card>
        {banksData.length > 0 ? (
          <CardBody className={classes.customCardContentClass}>
            <Table
              hover
              tableHead={[
                'Name',
                'Account No',
                'Bank',
                'Ifsc',
                'Branch',
                'Active',
              ]}
              tableData={banksData}
            />
          </CardBody>
        ) : (
          <div style={{ textAlign: 'center' }}>
            <p
              className={`${classes.textCenter} ${classes.title}`}
              style={{ textAlign: 'center', color: 'red' }}
            >
              {banksState}
            </p>
          </div>
        )}
      </Card>
    );
  }

  calculateFields() {
    const { showEditDetails } = this.state;
    const { currentTransactionData } = this.state;
    const {
      athPayableAutoCalculated,
      bthPayableAutoCalculated,
      tdsTruckerAmount,
    } = currentTransactionData;

    this.setState({
      athPayableAutoCalculated,
      bthPayableAutoCalculated,
      tdsTruckerAmount,
    });

    if (showEditDetails) {
      this.calculateFieldsForEdit();
    }
  }

  calculateFieldsForEdit() {
    const {
      athPayableAutoCalculated,
      bthPayableAutoCalculated,
      tdsTruckerAmount,
    } = this.state;
    this.setState({
      athPayableAutoCalculatedEdited: athPayableAutoCalculated,
      bthPayableAutoCalculatedEdited: bthPayableAutoCalculated,
      tdsDeductedAutoCalculatedEdited: tdsTruckerAmount,
      tdsTruckerAmount,
    });
  }

  showTruckerDetails() {
    this.setState({ alert: true });
  }

  closeAlert() {
    this.setState({ alert: false });
  }

  /**
   * Will Clears ALl the State values after form submision
   * @function
   */
  submitAction(message) {
    const { classes } = this.props;
    this.setState({
      submitAlert: (
        <SweetAlert
          success
          style={{ display: 'block', marginTop: '-100px' }}
          title="Good job!"
          onConfirm={() => this.hideDetailedForm()}
          onCancel={() => this.hideDetailedForm()}
          confirmBtnCssClass={`${classes.button} ${classes.success}`}
        >
          Transaction {message}
        </SweetAlert>
      ),
    });
  }

  submitError(message) {
    const { classes } = this.props;
    this.setState({
      submitAlert: (
        <SweetAlert
          error
          style={{ display: 'block', marginTop: '-100px' }}
          title="Error"
          onConfirm={() => {
            this.setState({ submitAlert: null });
          }}
          onCancel={() => {
            this.setState({ submitAlert: null });
          }}
          confirmBtnCssClass={`${classes.button} ${classes.success}`}
        >
          {message}
        </SweetAlert>
      ),
    });
  }

  waitAlert() {
    const { classes } = this.props;
    this.setState({
      awaitAlert: (
        <SweetAlert
          success
          style={{ display: 'block', marginTop: '-100px' }}
          title="Please Wait........"
          showConfirm={false}
          confirmBtnCssClass={`${classes.button} ${classes.success}`}
        />
      ),
    });
  }

  submittedDateChange(submittedDate) {
    this.setState({ submittedDate });
  }

  collectedDateChange(collectedDate) {
    this.setState({ collectedDate });
  }

  // Returns if a value is undefined
  isUndefined(value) {
    return typeof value === 'undefined';
  }

  // Returns if a value is a string
  isString(value) {
    return typeof value === 'string' || value instanceof String;
  }

  submitCollected() {
    this.setState({
      isLoadingCollected: true,
    });
    const { collectedDate, currentTransactionData } = this.state;
    const { userDetails } = this.props;
    const { transactionId } = currentTransactionData;
    const { uid } = userDetails;
    const collectedNew = new Date(collectedDate).toJSON();
    const body = {
      transactionId,
      collectedDate: collectedNew,
      agentId: uid,
      isCollected: true,
    };
    if (
      this.isUndefined(collectedDate) ||
      collectedDate === '' ||
      collectedDate === null
    ) {
      this.setState({
        isLoadingCollected: false,
        collectedError: 'Please Select Collected Date',
      });
    } else {
      addCollectedDate(body)
        .then(response => {
          this.setState({
            isLoadingCollected: false,
            isCollected: true,
            collectedError: '',
          });
        })
        .catch(err => {
          this.setState({
            isLoadingCollected: false,
            isCollected: true,
            collectedError: 'Oops Something went wrong! Please Try Again',
          });
        });
    }
  }

  submitSubmitted() {
    this.setState({
      isLoadingSubmitted: true,
    });
    const { submittedDate, currentTransactionData } = this.state;
    const { userDetails } = this.props;
    const { transactionId } = currentTransactionData;
    const { uid } = userDetails;
    const submittedNew = new Date(submittedDate).toJSON();
    const body = {
      transactionId,
      submittedDate: submittedNew,
      agentId: uid,
      isSubmitted: true,
    };
    if (
      this.isUndefined(submittedDate) ||
      submittedDate === '' ||
      submittedDate === null
    ) {
      this.setState({
        isLoadingSubmitted: false,
        submittedError: 'Please Select Submitted Date',
      });
    } else {
      addSubmittedDate(body)
        .then(response => {
          this.setState({
            isLoadingSubmitted: false,
            isSubmitted: true,
            submittedError: '',
          });
        })
        .catch(err => {
          this.setState({
            isLoadingSubmitted: false,
            submittedError: 'Oops Something went wrong! Please Try Again',
          });
        });
    }
  }

  resetCollected() {
    this.setState({
      isLoadingCollected: true,
    });
    const { currentTransactionData } = this.state;
    const { userDetails } = this.props;
    const { transactionId } = currentTransactionData;
    const { uid } = userDetails;
    const body = {
      transactionId,
      agentId: uid,
      isCollected: true,
    };
    resetCollectedDate(body)
      .then(response => {
        this.setState({
          isLoadingCollected: false,
          isCollected: false,
          collectedDate: null,
          collectedError: '',
        });
      })
      .catch(err => {
        this.setState({
          isLoadingCollected: false,
          isCollected: false,
          collectedError: 'Oops Something went wrong! Please Try Again',
        });
      });
  }

  async tdsDeductedFlagHandler() {
    const {
      transporterId,
      transactionSerial,
    } = this?.state?.currentTransactionData;

    this.setState({
      latestTransactionNumber: transactionSerial,
    });

    let { activeRole } = this?.props;
    const roleMapping = {
      'branch-ops': 'BranchOps',
      'transaction-view': 'TransactionView',
      'finance-payable': 'FinancePayable',
      'finance-recievable': 'FinanceReceivable',
    };
    const currentRole = roleMapping[activeRole] || activeRole;
    const currentActiveRole = `is${currentRole
      .charAt(0)
      .toUpperCase()}${currentRole.slice(1)}`;

    const userDetails = firebase.auth().currentUser;
    const { Aa: token } = userDetails;

    const endPoint = `pipe/check-tds-deduction?role=${currentActiveRole}&transporter_id=${transporterId}`;

    const maxRetries = 2;

    const makeRequest = async attempt => {
      try {
        const response = await apiCaller(
          endPoint,
          {},
          `Bearer ${token}`,
          'nh-base',
          'get'
        );
        const value = response?.data?.is_tds_deduction;

        if (value === true) {
          this.setState({
            showTds: value,
          });
        }
      } catch (e) {
        if (attempt < maxRetries) {
          await makeRequest(attempt + 1);
        } else {
          console.error(e);
        }
      }
    };

    await makeRequest(1);
  }

  resetSubmitted() {
    this.setState({
      isLoadingSubmitted: true,
    });
    const { currentTransactionData } = this.state;
    const { userDetails } = this.props;
    const { transactionId } = currentTransactionData;
    const { uid } = userDetails;
    const body = {
      transactionId,
      agentId: uid,
      isSubmitted: true,
    };
    resetSubmittedDate(body)
      .then(response => {
        this.setState({
          isLoadingSubmitted: false,
          submittedDate: null,
          isSubmitted: false,
          submittedError: '',
        });
      })
      .catch(err => {
        this.setState({
          isLoadingSubmitted: false,
          submittedError: 'Oops Something went wrong! Please Try Again',
        });
      });
  }

  autoCalculated(isEdited, isEditedTransaction, isOldTransaction) {
    const { classes } = this.props;
    const {
      athPayableAutoCalculated,
      bthPayableAutoCalculated,
      tdsTruckerAmount,
      athPayableAutoCalculatedEdited,
      bthPayableAutoCalculatedEdited,
      tdsDeductedAutoCalculatedEdited,
    } = this.state;
    let isAthPayableEdited = false;
    let isBthPayableEdited = false;
    let isTdsDeductedEdited = false;
    let isAthPayableEditedOld = false;
    let isBthPayableEditedOld = false;
    let isTdsDeductedEditedOld = false;

    if (isEditedTransaction) {
      if (athPayableAutoCalculated !== athPayableAutoCalculatedEdited) {
        isAthPayableEdited = true;
      }
      if (bthPayableAutoCalculated !== bthPayableAutoCalculatedEdited) {
        isBthPayableEdited = true;
      }

      if (tdsTruckerAmount !== tdsDeductedAutoCalculatedEdited) {
        isTdsDeductedEdited = true;
      }
    }
    if (isOldTransaction) {
      if (athPayableAutoCalculated !== athPayableAutoCalculatedEdited) {
        isAthPayableEditedOld = true;
      }
      if (bthPayableAutoCalculated !== bthPayableAutoCalculatedEdited) {
        isBthPayableEditedOld = true;
      }

      if (tdsTruckerAmount !== tdsDeductedAutoCalculatedEdited) {
        isTdsDeductedEditedOld = true;
      }
    }
    return (
      <GridContainer>
        <GridItem
          xs={12}
          sm={12}
          md={12}
          style={{ float: 'left', textAlign: 'left' }}
        >
          <h5 className={classes.customCardIconTitle}>Auto Calculation</h5>
        </GridItem>
        <GridItem
          xs={12}
          sm={6}
          md={4}
          style={{ float: 'left', textAlign: 'left' }}
        >
          <InputLabel
            htmlFor="simple-select"
            className={classes.selectLabel}
            style={{ float: 'left !important' }}
          >
            ATH Payable
          </InputLabel>
          <h5
            className={`${classes.customHeader} ${isAthPayableEdited &&
              classes.isEdited} ${isAthPayableEditedOld &&
              classes.isEditedOld}`}
          >
            {isEdited
              ? athPayableAutoCalculatedEdited
              : athPayableAutoCalculated}
          </h5>
        </GridItem>
        <GridItem
          xs={12}
          sm={6}
          md={4}
          style={{ float: 'left', textAlign: 'left' }}
        >
          <InputLabel
            htmlFor="simple-select"
            className={classes.selectLabel}
            style={{ float: 'left !important' }}
          >
            BTH Payable
          </InputLabel>
          <h5
            className={`${classes.customHeader} ${isBthPayableEdited &&
              classes.isEdited} ${isBthPayableEditedOld &&
              classes.isEditedOld}`}
          >
            {isEdited
              ? `${bthPayableAutoCalculatedEdited}`
              : `${bthPayableAutoCalculated}`}
          </h5>
        </GridItem>

        {this?.state?.latestTransactionNumber >
          this?.state?.hardCodeTransactionNumber &&
          !this?.state?.showTds && (
            <GridItem
              xs={12}
              sm={6}
              md={4}
              style={{ float: 'left', textAlign: 'left' }}
            >
              <InputLabel
                htmlFor="simple-select"
                className={classes.selectLabel}
                style={{ float: 'left !important' }}
              >
                TDS
              </InputLabel>
              <h5
                className={`${classes.customHeader} ${isTdsDeductedEdited &&
                  classes.isEdited} ${isTdsDeductedEditedOld &&
                  classes.isEditedOld}`}
              >
                {isEdited
                  ? tdsDeductedAutoCalculatedEdited ?? 0
                  : tdsTruckerAmount ?? 0}
              </h5>
            </GridItem>
          )}
      </GridContainer>
    );
  }

  deductions(transaction, isCheckEdited, isOldTransaction) {
    const { classes, activeRole } = this.props;

    let {
      munshianaTruckerEdited,
      munshianaTransporterEdited,
      penaltyTruckerEdited,
      penaltyTransporterEdited,
      tdsTransporterEdited,
      docChargesTruckerEdited,
      docChargesTransporterEdited,
      fuelChargesTruckerEdited,
      fuelChargesTransporterEdited,
      loadingChargesPaidByTransporterCheckedEditted,
      unloadingChargesPaidByTransporterCheckedEditted,
      hamaliChargesPaidByTransporterCheckedEditted,
      haltingChargesPaidByTransporterCheckedEditted,
      loadingChargesPaidByTruckerCheckedEditted,
      unloadingChargesPaidByTruckerCheckedEditted,
      hamaliChargesPaidByTruckerCheckedEditted,
      haltingChargesPaidByTruckerCheckedEditted,
      earlySettlementTruckerShowinloadingslipEditted,
      tdsTruckerShowinloadingslipEditted,
      lateDeliveryTruckerShowinloadingslipEditted,
      shortageChargesTruckerShowinloadingslipEditted,
      maamolChargesTruckerShowinloadingslipEditted,
      fuelChargesTruckerShowinloadingslipEditted,
      docChargesTruckerShowinloadingslipEditted,
      latePodTruckerShowinloadingslipEditted,
      showTds,
      hardCodeTransactionNumber,
      latestTransactionNumber,
    } = this.state;
    const {
      loadingChargesPaidTransporterEdited,
      unloadingChargesPaidTransporterEdited,
      hamaliChargesPaidTransporterEdited,
      haultingChargesPaidTransporterEdited,
      loadingChargesPaidByTruckerEdited,
      unloadingChargesPaidByTruckerEdited,
      hamaliChargesPaidByTruckerEdited,
      haltingChargesPaidByTruckerEdited,
      maamolChargesTransporterEdited,
      maamolChargesTruckerEdited,
      shortageChargesTruckerEditted,
      lateDeliveryTruckerEditted,
      tdsTruckerEditted,
      earlySettlementTruckerEditted,
      latePodTruckerEditted,
    } = this.state;
    const edittedDeductions = {
      loadingChargesPaidTransporterEdited,
      unloadingChargesPaidTransporterEdited,
      hamaliChargesPaidTransporterEdited,
      haultingChargesPaidTransporterEdited,
      loadingChargesPaidByTruckerEdited,
      unloadingChargesPaidByTruckerEdited,
      hamaliChargesPaidByTruckerEdited,
      haltingChargesPaidByTruckerEdited,
      munshianaTransporterEdited,
      penaltyTransporterEdited,
      tdsTransporterEdited,
      docChargesTransporterEdited,
      fuelChargesTransporterEdited,
      maamolChargesTransporterEdited,
      munshianaTruckerEdited,
      penaltyTruckerEdited,
      docChargesTruckerEdited,
      fuelChargesTruckerEdited,
      maamolChargesTruckerEdited,
      shortageChargesTruckerEditted,
      lateDeliveryTruckerEditted,
      tdsTruckerEditted,
      earlySettlementTruckerEditted,
      latePodTruckerEditted,
      loadingChargesPaidByTransporterCheckedEditted,
      unloadingChargesPaidByTransporterCheckedEditted,
      hamaliChargesPaidByTransporterCheckedEditted,
      haltingChargesPaidByTransporterCheckedEditted,
      loadingChargesPaidByTruckerCheckedEditted,
      unloadingChargesPaidByTruckerCheckedEditted,
      hamaliChargesPaidByTruckerCheckedEditted,
      haltingChargesPaidByTruckerCheckedEditted,
      earlySettlementTruckerShowinloadingslipEditted,
      tdsTruckerShowinloadingslipEditted,
      lateDeliveryTruckerShowinloadingslipEditted,
      shortageChargesTruckerShowinloadingslipEditted,
      maamolChargesTruckerShowinloadingslipEditted,
      fuelChargesTruckerShowinloadingslipEditted,
      docChargesTruckerShowinloadingslipEditted,
      latePodTruckerShowinloadingslipEditted,
      showTds,
      hardCodeTransactionNumber,
      latestTransactionNumber,
    };

    return (
      <TripDeductions
        classes={classes}
        transaction={transaction}
        edittedDeductions={edittedDeductions}
        isOldTransaction={isOldTransaction}
        role={activeRole}
      />
    );
  }

  otherExpenses(transaction, isCheckEdited, isOldTransaction) {
    const { classes } = this.props;
    let {
      loadingChargesEdited,
      hamaliChargesEdited,
      haultingChargesEdited,
      loadingChargesPaidTransporterEdited,
      hamaliChargesPaidTransporterEdited,
      haultingChargesPaidTransporterEdited,
      loadingChargesPaidByTruckerEdited,
      hamaliChargesPaidByTruckerEdited,
      haltingChargesPaidByTruckerEdited,
    } = this.state;

    let loadingChargesEditedOld = loadingChargesEdited;
    let hamaliChargesEditedOld = hamaliChargesEdited;
    let haultingChargesEditedOld = haultingChargesEdited;
    let loadingChargesPaidTransporterEditedOld = loadingChargesPaidTransporterEdited;
    let hamaliChargesPaidTransporterEditedOld = hamaliChargesPaidTransporterEdited;
    let haultingChargesPaidTransporterEditedOld = haultingChargesPaidTransporterEdited;

    let loadingChargesPaidByTruckerEditedOld = loadingChargesPaidByTruckerEdited;
    let hamaliChargesPaidByTruckerEditedOld = hamaliChargesPaidByTruckerEdited;
    let haltingChargesPaidByTruckerEditedOld = haltingChargesPaidByTruckerEdited;

    if (!isCheckEdited) {
      loadingChargesEdited = false;
      hamaliChargesEdited = false;
      haultingChargesEdited = false;
      loadingChargesPaidTransporterEdited = false;
      hamaliChargesPaidTransporterEdited = false;
      haultingChargesPaidTransporterEdited = false;
      loadingChargesPaidByTruckerEdited = false;
      hamaliChargesPaidByTruckerEdited = false;
      haltingChargesPaidByTruckerEdited = false;
    }
    if (!isOldTransaction) {
      loadingChargesEditedOld = false;
      hamaliChargesEditedOld = false;
      haultingChargesEditedOld = false;
      loadingChargesPaidTransporterEditedOld = false;
      hamaliChargesPaidTransporterEditedOld = false;
      haultingChargesPaidTransporterEditedOld = false;
      loadingChargesPaidByTruckerEditedOld = false;
      hamaliChargesPaidByTruckerEditedOld = false;
      haltingChargesPaidByTruckerEditedOld = false;
    }

    return (
      <form>
        <GridContainer className={classes.customMargin}>
          <GridItem
            xs={12}
            sm={12}
            md={12}
            className={classes.noPadding}
            style={{ float: 'left', textAlign: 'left' }}
          >
            <h5 className={classes.customCardIconTitle}>Other Expenses</h5>
          </GridItem>
          <GridItem
            xs={12}
            sm={4}
            md={4}
            style={{ float: 'left', textAlign: 'left' }}
          >
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <InputLabel
                  htmlFor="simple-select"
                  className={classes.selectLabel}
                  style={{ float: 'left !important' }}
                >
                  Loading Charges
                </InputLabel>
                <h5
                  className={`${classes.customHeader} ${loadingChargesEdited &&
                    classes.isEdited}  ${loadingChargesEditedOld &&
                    classes.isEditedOld}`}
                >
                  {transaction.loadingCharges}
                </h5>
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled
                      className={`${
                        classes.checkBoxHeight
                      } ${loadingChargesPaidTransporterEdited &&
                        classes.isCheckBox}  ${loadingChargesPaidTransporterEditedOld &&
                        classes.isCheckBoxOld}`}
                      checked={transaction.loadingChargesPaidTransporter}
                      // iconStyle={{fill:'red'}}
                      value="loadingChargesPaidTransporter"
                    />
                  }
                  label="From Transporter?"
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled
                      className={`${
                        classes.checkBoxHeight
                      } ${loadingChargesPaidByTruckerEdited &&
                        classes.isCheckBox}  ${loadingChargesPaidByTruckerEditedOld &&
                        classes.isCheckBoxOld}`}
                      checked={transaction.loadingChargesPaidByTrucker}
                      // iconStyle={{fill:'red'}}
                      value="loadingChargesPaidByTrucker"
                    />
                  }
                  label="Paid By Trucker"
                />
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem
            xs={12}
            sm={4}
            md={4}
            style={{ float: 'left', textAlign: 'left' }}
          >
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <InputLabel
                  htmlFor="simple-select"
                  className={classes.selectLabel}
                  style={{ float: 'left !important' }}
                >
                  Hamali Charges
                </InputLabel>
                <h5
                  className={`${classes.customHeader} ${hamaliChargesEdited &&
                    classes.isEdited} ${hamaliChargesEditedOld &&
                    classes.isEditedOld}`}
                >
                  {transaction.hamaliCharges}
                </h5>
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={transaction.hamaliChargesPaidTransporter}
                      disabled
                      className={`${
                        classes.checkBoxHeight
                      } ${hamaliChargesPaidTransporterEdited &&
                        classes.isCheckBox}  ${hamaliChargesPaidTransporterEditedOld &&
                        classes.isCheckBoxOld}`}
                      value="hamaliChargesPaidTransporter"
                    />
                  }
                  label="From Transporter?"
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={transaction.hamaliChargesPaidByTrucker}
                      disabled
                      className={`${
                        classes.checkBoxHeight
                      } ${hamaliChargesPaidByTruckerEdited &&
                        classes.isCheckBox}  ${hamaliChargesPaidByTruckerEditedOld &&
                        classes.isCheckBoxOld}`}
                      value="hamaliChargesPaidByTrucker"
                    />
                  }
                  label="Paid By Trucker"
                />
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem
            xs={12}
            sm={4}
            md={4}
            style={{ float: 'left', textAlign: 'left' }}
          >
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <InputLabel
                  htmlFor="simple-select"
                  className={classes.selectLabel}
                  style={{ float: 'left !important' }}
                >
                  Halting Charges
                </InputLabel>
                <h5
                  className={`${classes.customHeader} ${haultingChargesEdited &&
                    classes.isEdited} ${haultingChargesEditedOld &&
                    classes.isEditedOld}`}
                >
                  {transaction.haultingCharges}
                </h5>
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={transaction.haultingChargesPaidTransporter}
                      disabled
                      className={`${
                        classes.checkBoxHeight
                      } ${haultingChargesPaidTransporterEdited &&
                        classes.isCheckBox}  ${haultingChargesPaidTransporterEditedOld &&
                        classes.isCheckBoxOld}`}
                      value="haultingChargesPaidTransporter"
                    />
                  }
                  label="From Transporter?"
                />
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={transaction.haltingChargesPaidByTrucker}
                      disabled
                      className={`${
                        classes.checkBoxHeight
                      } ${haltingChargesPaidByTruckerEdited &&
                        classes.isCheckBox}  ${haltingChargesPaidByTruckerEditedOld &&
                        classes.isCheckBoxOld}`}
                      value="haultingChargesPaidByTrucker"
                    />
                  }
                  label="Paid By Trucker"
                />
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </form>
    );
  }

  approveTransaction(transaction) {
    const { userDetails, systemDetails } = this.props;
    const { banksDocs } = this.state;
    if (userDetails.isAdmin || userDetails.isOps) {
      this.setState({ isButtonLoading: true });

      // Check if the Trucker has an active bank account
      let activeBankAccountExists = false;
      banksDocs.forEach(bank => {
        const { cashfree_registered, razorpay_registered } = bank;
        if (
          cashfree_registered !== undefined &&
          razorpay_registered !== undefined &&
          cashfree_registered &&
          razorpay_registered &&
          bank.bankAccountActive
        ) {
          activeBankAccountExists = true;
        }
      });
      if (!activeBankAccountExists) {
        this.submitError(
          'Trucker does not have an active bank account/fund account.'
        );
        this.setState({ isButtonLoading: false });
        return;
      }

      // Proceed to Approve

      opsApproveTransaction(transaction)
        .then(_result => {
          const auditBody = {
            data: { ...transaction },
            collection: 'Transactions',
            updatedBy: userDetails.uid,
            systemDetails,
            type: 'Approve Transaction',
            message: `${userDetails.email} approved transaction ${
              transaction.transactionSerial
            } ${transaction.isEditedTransaction ? 'EDIT' : ''} `,
          };
          auditLog(auditBody);
          this.setState({ isButtonLoading: false, color: 'info' });
          //           this.submitAction('Approved');
          this.hideOpsDetailedForm();
        })
        .catch(err => {
          const errorBody = {
            message: `${userDetails.email} tried to approved transaction ${
              transaction.transactionSerial
            } ${transaction.isEditedTransaction ? 'EDIT' : ''} but FAILED `,
            status: 'fail',
            error: err,
            updatedBy: userDetails.uid,
            errorMessage: err.message,
            collection: 'Transactions',
            systemDetails,
          };
          auditLog(errorBody);
          this.setState({ isButtonLoading: false });
        });
    }
  }

  confirmBankDetails(transaction) {
    this.handleClickOpen('isConfirmDialog');
  }

  /**
   * Reject User
   * @function
   */
  rejectTransaction(transaction) {
    this.setState({ isButtonLoading: true });
    const { userDetails, systemDetails } = this.props;
    // const { isEdit } = transaction;
    const transactionRef = db.collection('Transactions');

    transactionRef
      .doc(`${transaction.transactionId}`)
      .update({
        status: 'rejected',
        modifiedAt: new Date(),
      })
      .then(() => {
        // const deleteRef = db
        //   .collection(`Transactions`)
        //   .doc(transaction.transactionId);
        if (transaction.isEditedTransaction) {
          const editLogRef = db
            .collection(`Transactions`)
            .doc(transaction.originalTransactionId)
            .collection('EditLog');

          editLogRef.add({ ...transaction, logEntryCreatedAt: new Date() });
          // deleteRef.delete();
        } else {
          const editLogRef = db
            .collection(`Transactions`)
            .doc(transaction.transactionId)
            .collection('EditLog');
          editLogRef.add({ ...transaction, logEntryCreatedAt: new Date() });
        }

        const auditBody = {
          data: { ...transaction },
          collection: 'Transactions',
          updatedBy: userDetails.uid,
          systemDetails,
          type: 'Reject Transaction',
          message: `${userDetails.email} rejected transaction ${
            transaction.transactionSerial
          } ${transaction.isEditedTransaction ? 'EDIT' : ''} `,
        };
        auditLog(auditBody);
        this.setState({ isButtonLoading: false, color: 'info' });
        //         this.submitAction('Rejected');
        this.hideOpsDetailedForm();
      })
      .catch(err => {
        const errorBody = {
          message: `${userDetails.email} tried to reject transaction ${
            transaction.transactionSerial
          } ${transaction.isEditedTransaction ? 'EDIT' : ''} but FAILED `,
          status: 'fail',
          error: err,
          updatedBy: userDetails.uid,
          errorMessage: err.message,
          collection: 'Transactions',
          systemDetails,
        };
        auditLog(errorBody);
        this.setState({ isButtonLoading: false });
      });
  }

  inputAlert(transaction) {
    const { classes } = this.props;
    this.setState({
      transaction,
      modifyAlert: (
        <SweetAlert
          input
          showCancel
          style={{ display: 'block', marginTop: '-100px' }}
          title="Add Comment"
          onConfirm={e => this.inputConfirmAlert(e)}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={`${classes.button} ${classes.info}`}
          cancelBtnCssClass={`${classes.button} `}
        />
      ),
    });
  }

  inputConfirmAlert(e) {
    this.setState({ comment: e, modifyAlert: null }, this.modifyTransaction);

    //  setTimeout(this.inputConfirmAlertNext, 200);
  }

  modifyTransaction() {
    this.setState({ isButtonLoading: true });
    const { userDetails, systemDetails } = this.props;
    const { transaction, comment } = this.state;
    const transactionRef = db.collection('Transactions');
    transactionRef
      .doc(`${transaction.transactionId}`)
      .update({
        status: 'modify',
        comment,
        modifiedAt: new Date(),
      })
      .then(() => {
        const auditBody = {
          data: { ...transaction },
          collection: 'Transactions',
          updatedBy: userDetails.uid,
          systemDetails,
          type: 'Modify Transaction',
          message: `${
            userDetails.email
          } requested modifications for transaction ${
            transaction.transactionSerial
          } ${transaction.isEditedTransaction ? 'EDIT' : ''} `,
        };
        auditLog(auditBody);
        this.setState({ isButtonLoading: false, color: 'info' });
        //         this.submitAction('has been requested for modifications.');
        this.hideOpsDetailedForm();
      })
      .catch(err => {
        const errorBody = {
          message: `${
            userDetails.email
          } tried to requested modifications for transaction ${
            transaction.transactionSerial
          } ${transaction.isEditedTransaction ? 'EDIT' : ''} but FAILED `,
          status: 'fail',
          error: err,
          updatedBy: userDetails.uid,
          errorMessage: err.message,
          collection: 'Transactions',
          systemDetails,
        };
        auditLog(errorBody);
        this.setState({ isButtonLoading: false });
      });
  }

  hideAlert() {
    this.setState({
      modifyAlert: null,
    });
  }

  finishChecks() {
    this.setState({ checksError: '' });
    const { checkOne, checkTwo, currentTransactionData } = this.state;
    if (checkOne && checkTwo) {
      this.setState({ checksLoading: true });
      db.collection('Transactions')
        .doc(currentTransactionData.transactionId)
        .update({
          checkOne: true,
          checkTwo: true,
          showAthModal: true,
          modifiedAt: new Date(),
        })
        .then(() => {
          this.getTransactionData();
          this.waitAlert();
          setTimeout(() => {
            window.location.reload();
          }, 3000);
          this.setState({
            checksAlert: false,
            checksLoading: false,
            recentTransactionsLoading: false,
            recentTransactions: [],
          });
        });
    } else {
      this.setState({
        checksError: 'Finish both checks',
        checksLoading: false,
      });
    }
  }

  closeChecksAlert() {
    this.setState({
      checksAlert: false,
      checksError: '',
      recentTransactionsLoading: false,
      recentTransactions: [],
    });
  }

  showChecksAlert() {
    this.setState({
      checksAlert: true,
      checksError: '',
      recentTransactionsLoading: true,
    });
    const { currentTransactionData } = this.state;
    const { truckerId, vehicleNumber } = currentTransactionData;
    const recentTransactionsRef = db
      .collection('Transactions')
      .where('truckerId', '==', truckerId)
      .where('vehicleNumber', '==', vehicleNumber)
      .orderBy('createdAt', 'desc')
      .limit(4);

    recentTransactionsRef
      .get()
      .then(snapshot => {
        const recentTransactions = [];
        snapshot.forEach(transactionDoc => {
          if (
            currentTransactionData.transactionId !==
            transactionDoc.data().transactionId
          )
            // Don't Include the same transaction in the recent list
            recentTransactions.push(transactionDoc.data());
        });
        if (recentTransactions.length > 3) recentTransactions.pop();
        this.setState({ recentTransactions, recentTransactionsLoading: false });
      })
      .catch(() => {
        this.setState({
          recentTransactions: [],
          recentTransactionsLoading: false,
        });
      });
  }

  handleCheckOne() {
    let { checkOne } = this.state;
    checkOne = !checkOne;
    this.setState({ checkOne });
  }

  handleCheckTwo() {
    let { checkTwo } = this.state;
    checkTwo = !checkTwo;
    this.setState({ checkTwo });
  }

  oldDetails() {
    const { classes } = this.props;
    const {
      vehicleNumberEdited,
      truckerIdEdited,
      transporterIdEdited,
      loadingDateEdited,
      fromCityEdited,
      toCityEdited,
      athReceivableDateEdited,
      amountEdited,
      commissionEdited,
      athReceivableEdited,
      bthReceivableEdited,
      athPayableEdited,
      bthPayableEdited,
    } = this.state;
    const {
      truckerName,
      transporterName,
      vehicleNumber,
      truckType,
      loadingDate,
      fromCity,
      toCity,
      athReceivableDate,
      amount,
      commission,
      athReceivable,
      bthReceivable,
      athPayable,
      bthPayable,
      expectedDate,
      expectedDateEditted,
      transporterPocName,
      transporterPocNameEditted,
      truckerPocNameEditted,
      truckerPocName,
      orderRecievedByNameIsEdited,
      orderRecievedByName,
      truckPlacedByName,
      truckPlacedByNameIsEdited,
      tonnageEditted,
      driversPhoneNumberEditted,
      driversPhoneNumber,
      tonnage,
      transactionType,
      expressTripEditted,
    } = this.state;

    return (
      <GridContainer>
        <Accordion
          // eslint-disable-next-line react/jsx-boolean-value
          isTitleCenter={true}
          active={null}
          collapses={[
            {
              title: (
                <GridItem xs={12} sm={12} md={12}>
                  <h3
                    className={`${classes.cardTitle}`}
                    style={{ float: 'center', textAlign: 'center' }}
                  >
                    Old Details
                  </h3>
                </GridItem>
              ),
              content: (
                <Card className={classes.customCard}>
                  <GridContainer>
                    <GridItem
                      xs={12}
                      sm={12}
                      md={12}
                      style={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <FormControl component="fieldset">
                        <RadioGroup
                          aria-label="transactionType"
                          name="transactionType"
                          value={transactionType}
                          // onChange={this.handleChangeTransactionType}
                          row
                        >
                          {transactionType === 'TAT' ? (
                            <FormControlLabel
                              value="TAT"
                              // disabled
                              control={
                                <Radio
                                  style={disabledRadioStyles}
                                  color={
                                    !expressTripEditted
                                      ? 'primary'
                                      : 'secondary'
                                  }
                                />
                              }
                              label={
                                <Typography
                                  variant="subtitle2"
                                  style={{ color: expressTripEditted && 'red' }}
                                >
                                  TAT
                                </Typography>
                              }
                            />
                          ) : (
                            <FormControlLabel
                              value="Express"
                              // disabled
                              control={
                                <Radio
                                  style={disabledRadioStyles}
                                  color={
                                    !expressTripEditted
                                      ? 'primary'
                                      : 'secondary'
                                  }
                                />
                              }
                              label={
                                <Typography
                                  variant="subtitle2"
                                  style={{ color: expressTripEditted && 'red' }}
                                >
                                  Express
                                </Typography>
                              }
                            />
                          )}
                        </RadioGroup>
                      </FormControl>
                    </GridItem>
                    <GridItem
                      xs={6}
                      sm={6}
                      md={3}
                      style={{ float: 'left', textAlign: 'left' }}
                    >
                      <InputLabel
                        htmlFor="simple-select"
                        className={classes.selectLabel}
                        style={{ float: 'left !important' }}
                      >
                        From City
                      </InputLabel>
                      <h5
                        className={`${classes.h5NoMargin} ${fromCityEdited &&
                          classes.isEditedOld}`}
                      >
                        {fromCity}
                      </h5>
                    </GridItem>
                    <GridItem
                      xs={6}
                      sm={6}
                      md={3}
                      style={{ float: 'left', textAlign: 'left' }}
                    >
                      <InputLabel
                        htmlFor="simple-select"
                        className={classes.selectLabel}
                        style={{ float: 'left !important' }}
                      >
                        To City
                      </InputLabel>
                      <h5
                        className={`${classes.h5NoMargin} ${toCityEdited &&
                          classes.isEditedOld}`}
                      >
                        {toCity}
                      </h5>
                    </GridItem>
                    <GridItem
                      xs={4}
                      sm={4}
                      md={2}
                      style={{ float: 'left', textAlign: 'left' }}
                    >
                      <InputLabel
                        htmlFor="simple-select"
                        className={classes.selectLabel}
                        style={{ float: 'left !important' }}
                      >
                        Loading Date
                      </InputLabel>
                      <h5
                        className={`${classes.h5NoMargin} ${loadingDateEdited &&
                          classes.isEditedOld}`}
                      >
                        {loadingDate}
                      </h5>
                    </GridItem>

                    <GridItem
                      xs={4}
                      sm={4}
                      md={2}
                      style={{ float: 'left', textAlign: 'left' }}
                    >
                      <InputLabel
                        htmlFor="simple-select"
                        className={classes.selectLabel}
                        style={{ float: 'left !important' }}
                      >
                        Ath Receivable Date
                      </InputLabel>
                      <h5
                        className={`${
                          classes.h5NoMargin
                        } ${athReceivableDateEdited && classes.isEditedOld}`}
                      >
                        {athReceivableDate}
                      </h5>
                    </GridItem>
                    <GridItem
                      xs={4}
                      sm={4}
                      md={2}
                      style={{ float: 'left', textAlign: 'left' }}
                    >
                      <InputLabel
                        htmlFor="simple-select"
                        className={classes.selectLabel}
                        style={{ float: 'left !important' }}
                      >
                        Expected Date
                      </InputLabel>
                      <h5
                        className={`${
                          classes.h5NoMargin
                        } ${expectedDateEditted && classes.isEditedOld}`}
                      >
                        {expectedDate}
                      </h5>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <hr style={{ marginTop: '2px', marginBottom: '5px' }} />
                    </GridItem>
                    <GridItem
                      xs={6}
                      sm={6}
                      md={3}
                      style={{ float: 'left', textAlign: 'left' }}
                    >
                      <InputLabel
                        htmlFor="simple-select"
                        className={classes.selectLabel}
                        style={{ float: 'left !important' }}
                      >
                        Transporter
                      </InputLabel>
                      <h5
                        className={`${
                          classes.h5NoMargin
                        } ${transporterIdEdited && classes.isEditedOld}`}
                      >
                        {transporterName}
                      </h5>
                    </GridItem>
                    <GridItem
                      xs={6}
                      sm={6}
                      md={3}
                      style={{ float: 'left', textAlign: 'left' }}
                    >
                      <InputLabel
                        htmlFor="simple-select"
                        className={classes.selectLabel}
                        style={{ float: 'left !important' }}
                      >
                        Transporter POC
                      </InputLabel>
                      <h5
                        className={`${
                          classes.h5NoMargin
                        } ${transporterPocNameEditted && classes.isEditedOld}`}
                      >
                        {transporterPocName}
                      </h5>
                    </GridItem>
                    <GridItem
                      xs={6}
                      sm={6}
                      md={3}
                      style={{ float: 'left', textAlign: 'left' }}
                    >
                      <InputLabel
                        htmlFor="simple-select"
                        className={classes.selectLabel}
                        style={{ float: 'left !important' }}
                      >
                        Trucker
                      </InputLabel>
                      <h5
                        className={`${classes.h5NoMargin} ${truckerIdEdited &&
                          classes.isEditedOld}`}
                      >
                        {truckerName}
                      </h5>
                    </GridItem>
                    <GridItem
                      xs={6}
                      sm={6}
                      md={3}
                      style={{ float: 'left', textAlign: 'left' }}
                    >
                      <InputLabel
                        htmlFor="simple-select"
                        className={classes.selectLabel}
                        style={{ float: 'left !important' }}
                      >
                        Trucker POC
                      </InputLabel>
                      <h5
                        className={`${
                          classes.h5NoMargin
                        } ${truckerPocNameEditted && classes.isEditedOld}`}
                      >
                        {truckerPocName}
                      </h5>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <hr style={{ marginTop: '2px', marginBottom: '5px' }} />
                    </GridItem>
                    <GridItem
                      xs={6}
                      sm={6}
                      md={2}
                      style={{ float: 'left', textAlign: 'left' }}
                    >
                      <InputLabel
                        htmlFor="simple-select"
                        className={classes.selectLabel}
                        style={{ float: 'left !important' }}
                      >
                        Order Recieved By
                      </InputLabel>
                      <h5
                        className={`${
                          classes.h5NoMargin
                        } ${orderRecievedByNameIsEdited &&
                          classes.isEditedOld}`}
                      >
                        {orderRecievedByName}
                      </h5>
                    </GridItem>
                    <GridItem
                      xs={6}
                      sm={6}
                      md={2}
                      style={{ float: 'left', textAlign: 'left' }}
                    >
                      <InputLabel
                        htmlFor="simple-select"
                        className={classes.selectLabel}
                        style={{ float: 'left !important' }}
                      >
                        Truck Placed By
                      </InputLabel>
                      <h5
                        className={`${
                          classes.h5NoMargin
                        } ${truckPlacedByNameIsEdited && classes.isEditedOld}`}
                      >
                        {truckPlacedByName}
                      </h5>
                    </GridItem>
                    <GridItem
                      xs={6}
                      sm={6}
                      md={2}
                      style={{ float: 'left', textAlign: 'left' }}
                    >
                      <InputLabel
                        htmlFor="simple-select"
                        className={classes.selectLabel}
                        style={{ float: 'left !important' }}
                      >
                        Truck No
                      </InputLabel>
                      <h5
                        className={`${
                          classes.h5NoMargin
                        } ${vehicleNumberEdited && classes.isEditedOld}`}
                      >
                        {vehicleNumber}
                      </h5>
                    </GridItem>

                    <GridItem
                      xs={6}
                      sm={6}
                      md={2}
                      style={{ float: 'left', textAlign: 'left' }}
                    >
                      <InputLabel
                        htmlFor="simple-select"
                        className={classes.selectLabel}
                        style={{ float: 'left !important' }}
                      >
                        Truck Type
                      </InputLabel>
                      <h5
                        className={`${
                          classes.h5NoMargin
                        } ${vehicleNumberEdited && classes.isEditedOld}`}
                      >
                        {truckType}
                      </h5>
                    </GridItem>
                    <GridItem
                      xs={6}
                      sm={6}
                      md={2}
                      style={{ float: 'left', textAlign: 'left' }}
                    >
                      <InputLabel
                        htmlFor="simple-select"
                        className={classes.selectLabel}
                        style={{ float: 'left !important' }}
                      >
                        Tonnage
                      </InputLabel>
                      <h5
                        className={`${classes.h5NoMargin} ${tonnageEditted &&
                          classes.isEditedOld}`}
                      >
                        {tonnage}
                      </h5>
                    </GridItem>
                    <GridItem
                      xs={6}
                      sm={6}
                      md={2}
                      style={{ float: 'left', textAlign: 'left' }}
                    >
                      <InputLabel
                        htmlFor="simple-select"
                        className={classes.selectLabel}
                        style={{ float: 'left !important' }}
                      >
                        Driver Phone Number
                      </InputLabel>
                      <h5
                        className={`${
                          classes.h5NoMargin
                        } ${driversPhoneNumberEditted && classes.isEditedOld}`}
                      >
                        {driversPhoneNumber}
                      </h5>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <hr style={{ marginTop: '2px', marginBottom: '5px' }} />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <GridContainer style={{ marginLeft: '-10px' }}>
                        <GridItem
                          xs={6}
                          sm={6}
                          md={2}
                          style={{ float: 'left', textAlign: 'left' }}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                            style={{ float: 'left !important' }}
                          >
                            Freight
                          </InputLabel>
                          <h5
                            className={`${classes.h5NoMargin} ${amountEdited &&
                              classes.isEditedOld}`}
                          >
                            {Intl.NumberFormat('en-IN').format(amount)}
                          </h5>
                        </GridItem>
                        <GridItem
                          xs={6}
                          sm={6}
                          md={2}
                          style={{ float: 'left', textAlign: 'left' }}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                            style={{ float: 'left !important' }}
                          >
                            Commission
                          </InputLabel>
                          <h5
                            className={`${
                              classes.h5NoMargin
                            } ${commissionEdited && classes.isEditedOld}`}
                          >
                            {Intl.NumberFormat('en-IN').format(commission)}
                          </h5>
                        </GridItem>
                        <GridItem
                          xs={6}
                          sm={6}
                          md={2}
                          style={{ float: 'left', textAlign: 'left' }}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                            style={{ float: 'left !important' }}
                          >
                            Ath Receivable
                          </InputLabel>
                          <h5
                            className={`${
                              classes.h5NoMargin
                            } ${athReceivableEdited && classes.isEditedOld}`}
                          >
                            {Intl.NumberFormat('en-IN').format(athReceivable)}
                          </h5>
                        </GridItem>
                        <GridItem
                          xs={6}
                          sm={6}
                          md={2}
                          style={{ float: 'left', textAlign: 'left' }}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                            style={{ float: 'left !important' }}
                          >
                            Bth Receivable
                          </InputLabel>
                          <h5
                            className={`${
                              classes.h5NoMargin
                            } ${bthReceivableEdited && classes.isEditedOld}`}
                          >
                            {Intl.NumberFormat('en-IN').format(bthReceivable)}
                          </h5>
                        </GridItem>
                        <GridItem
                          xs={6}
                          sm={6}
                          md={2}
                          style={{ float: 'left', textAlign: 'left' }}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                            style={{ float: 'left !important' }}
                          >
                            Trucker ATH
                          </InputLabel>
                          <h5
                            className={`${
                              classes.h5NoMargin
                            } ${athPayableEdited && classes.isEditedOld}`}
                          >
                            {Intl.NumberFormat('en-IN').format(athPayable)}
                          </h5>
                        </GridItem>
                        <GridItem
                          xs={6}
                          sm={6}
                          md={2}
                          style={{ float: 'left', textAlign: 'left' }}
                        >
                          <InputLabel
                            htmlFor="simple-select"
                            className={classes.selectLabel}
                            style={{ float: 'left !important' }}
                          >
                            Trucker BTH
                          </InputLabel>
                          <h5
                            className={`${
                              classes.h5NoMargin
                            } ${bthPayableEdited && classes.isEditedOld}`}
                          >
                            {Intl.NumberFormat('en-IN').format(bthPayable)}
                          </h5>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6} />
                        <GridItem xs={12} sm={12} md={12}>
                          <hr
                            style={{ marginTop: '2px', marginBottom: '5px' }}
                          />
                        </GridItem>
                        {this.getLoadingAndUnloading(true)}
                        <GridItem xs={12} sm={12} md={12}>
                          <hr
                            style={{ marginTop: '2px', marginBottom: '5px' }}
                          />
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                    {/* <GridItem xs={12} sm={12} md={12}>
                      {this.otherExpenses(this.state, false, true)}
                    </GridItem> */}
                    <GridItem xs={12} sm={12} md={12}>
                      <hr style={{ marginTop: '2px', marginBottom: '5px' }} />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      {this.deductions(this.state, false, true)}
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                      <hr style={{ marginTop: '2px', marginBottom: '5px' }} />
                    </GridItem>
                    <GridItem xs={6} sm={6} md={6}>
                      {this.autoCalculated(true, false, true)}
                    </GridItem>
                  </GridContainer>
                </Card>
              ),
            },
          ]}
        />
      </GridContainer>
    );
  }

  findEditedTransaction(PreviousTransaction) {
    const { currentTransactionData } = this.state;

    const {
      vehicleNumber,
      truckerId,
      transporterId,
      loadingDate,
      fromCity,
      toCity,
      athReceivableDate,
      amount,
      commission,
      athReceivable,
      bthReceivable,
      athPayable,
      bthPayable,
      loadingCharges,
      unloadingCharges,

      tdsTrucker,

      loadingChargesPaidByTrucker,
      unloadingChargesPaidByTrucker,
      hamaliChargesPaidByTrucker,
      haltingChargesPaidByTrucker,
      truckerPocId,
      truckerPocName,
      transporterPocId,
      transporterPocName,
      truckPlacedByName,
      orderRecievedByName,
      tonnage,
      expectedDate,
      driversPhoneNumber,
      loadingChargesTransporter,
      unloadingChargesTransporter,
      hamaliChargesTransporter,
      haltingChargesTransporter,
      haltingChargesTrucker,
      hamaliChargesTrucker,
      loadingChargesTrucker,
      unloadingChargesTrucker,
      challanTransporterAmount,
      penaltyTransporterAmount,
      tdsTransporterAmount,
      docChargesTransporterAmount,
      fuelChargesTransporterAmount,
      maamolChargesTransporterAmount,
      penaltyTruckerAmount,
      challanTruckerAmount,
      docChargesTruckerAmount,
      fuelChargesTruckerAmount,
      maamolChargesTruckerAmount,
      shortageChargesTruckerAmount,
      lateDeliveryTruckerAmount,
      earlySettlementTruckerAmount,
      latePodTruckerAmount,
      tdsTruckerAmount,
      loadingChargesPaidByTransporter,
      unloadingChargesPaidByTransporter,
      hamaliChargesPaidByTransporter,
      haltingChargesPaidByTransporter,
      earlySettlementTruckerShowinloadingslip,
      tdsTruckerShowinloadingslip,
      lateDeliveryTruckerShowinloadingslip,
      shortageChargesTruckerShowinloadingslip,
      maamolChargesTruckerShowinloadingslip,
      fuelChargesTruckerShowinloadingslip,
      latePodTruckerShowinloadingslip,
      docChargesTruckerShowinloadingslip,
      expressTrip,
    } = PreviousTransaction;
    const previousTransactionType = expressTrip ? 'Express' : 'TAT';
    const currentTransactionType = currentTransactionData?.expressTrip
      ? 'Express'
      : 'TAT';
    this.setState({
      transactionType: previousTransactionType,
    });

    if (currentTransactionData.truckerPocId !== truckerPocId) {
      this.setState({
        vtruckerPocIdEditted: true,
      });
    }
    if (currentTransactionData.truckerPocName !== truckerPocName) {
      this.setState({
        truckerPocNameEditted: true,
      });
    }
    if (currentTransactionData.truckPlacedByName !== truckPlacedByName) {
      this.setState({
        truckPlacedByNameIsEdited: true,
      });
    }
    if (currentTransactionData.orderRecievedByName !== orderRecievedByName) {
      this.setState({
        orderRecievedByNameIsEdited: true,
      });
    }
    if (currentTransactionData.tonnage !== tonnage) {
      this.setState({
        tonnageEditted: true,
      });
    }
    if (currentTransactionData.driversPhoneNumber !== driversPhoneNumber) {
      this.setState({
        driversPhoneNumberEditted: true,
      });
    }
    if (currentTransactionData.transporterPocId !== transporterPocId) {
      this.setState({
        transporterPocIdEditted: true,
      });
    }
    if (currentTransactionData.transporterPocName !== transporterPocName) {
      this.setState({
        transporterPocNameEditted: true,
      });
    }
    if (currentTransactionData.vehicleNumber !== vehicleNumber) {
      this.setState({
        vehicleNumberEdited: true,
      });
    }
    if (currentTransactionData.truckerId !== truckerId) {
      this.setState({
        truckerIdEdited: true,
      });
    }
    if (currentTransactionData.transporterId !== transporterId) {
      this.setState({ transporterIdEdited: true });
    }
    if (currentTransactionData.fromCity !== fromCity) {
      this.setState({ fromCityEdited: true });
    }
    if (currentTransactionData.toCity !== toCity) {
      this.setState({
        toCityEdited: true,
      });
    }
    if (currentTransactionData.amount !== amount) {
      this.setState({ amountEdited: true });
    }
    if (currentTransactionData.commission !== commission) {
      this.setState({
        commissionEdited: true,
      });
    }
    if (currentTransactionData.athReceivable !== athReceivable) {
      this.setState({ athReceivableEdited: true });
    }
    if (currentTransactionData?.expectedDate !== expectedDate) {
      this.setState({ expectedDateEdited: true });
    }
    if (currentTransactionData.bthReceivable !== bthReceivable) {
      this.setState({ bthReceivableEdited: true });
    }
    if (currentTransactionData.athPayable !== athPayable) {
      this.setState({ athPayableEdited: true });
    }
    if (currentTransactionData.bthPayable !== bthPayable) {
      this.setState({ bthPayableEdited: true });
    }
    if (currentTransactionData.loadingDate !== loadingDate) {
      this.setState({ loadingDateEdited: true });
    }
    if (currentTransactionData.athReceivableDate !== athReceivableDate) {
      this.setState({ athReceivableDateEdited: true });
    }
    if (currentTransactionData.loadingCharges !== loadingCharges) {
      this.setState({ loadingChargesEdited: true });
    }
    if (currentTransactionData.unloadingCharges !== unloadingCharges) {
      this.setState({ unloadingChargesEdited: true });
    }
    if (
      currentTransactionData.loadingChargesTransporter !==
      loadingChargesTransporter
    ) {
      this.setState({ loadingChargesPaidTransporterEdited: true });
    }
    if (
      currentTransactionData.unloadingChargesTransporter !==
      unloadingChargesTransporter
    ) {
      this.setState({ unloadingChargesPaidTransporterEdited: true });
    }

    if (
      currentTransactionData.hamaliChargesTransporter !==
      hamaliChargesTransporter
    ) {
      this.setState({ hamaliChargesPaidTransporterEdited: true });
    }

    if (
      currentTransactionData.haltingChargesTransporter !==
      haltingChargesTransporter
    ) {
      this.setState({
        haultingChargesPaidTransporterEdited: true,
      });
    }

    if (
      currentTransactionData.loadingChargesTrucker !== loadingChargesTrucker
    ) {
      this.setState({ loadingChargesPaidByTruckerEdited: true });
    }
    if (
      currentTransactionData.unloadingChargesTrucker !== unloadingChargesTrucker
    ) {
      this.setState({ unloadingChargesPaidByTruckerEdited: true });
    }
    if (currentTransactionData.hamaliChargesTrucker !== hamaliChargesTrucker) {
      this.setState({ hamaliChargesPaidByTruckerEdited: true });
    }

    if (
      currentTransactionData.haltingChargesTrucker !== haltingChargesTrucker
    ) {
      this.setState({
        haltingChargesPaidByTruckerEdited: true,
      });
    }

    if (currentTransactionData.challanTruckerAmount !== challanTruckerAmount) {
      this.setState({ munshianaTruckerEdited: true });
    }
    if (
      currentTransactionData.challanTransporterAmount !==
      challanTransporterAmount
    ) {
      this.setState({ munshianaTransporterEdited: true });
    }

    if (currentTransactionData.tdsTrucker !== tdsTrucker) {
      this.setState({ tdsTruckerEdited: true });
    }
    if (currentTransactionData.tdsTransporterAmount !== tdsTransporterAmount) {
      this.setState({ tdsTransporterEdited: true });
    }

    if (currentTransactionData.penaltyTruckerAmount !== penaltyTruckerAmount) {
      this.setState({ penaltyTruckerEdited: true });
    }
    if (
      currentTransactionData.penaltyTransporterAmount !==
      penaltyTransporterAmount
    ) {
      this.setState({ penaltyTransporterEdited: true });
    }

    if (
      currentTransactionData.docChargesTruckerAmount !== docChargesTruckerAmount
    ) {
      this.setState({
        docChargesTruckerEdited: true,
      });
    }
    if (
      currentTransactionData.docChargesTransporterAmount !==
      docChargesTransporterAmount
    ) {
      this.setState({ docChargesTransporterEdited: true });
    }

    if (
      currentTransactionData.fuelChargesTruckerAmount !==
      fuelChargesTruckerAmount
    ) {
      this.setState({
        fuelChargesTruckerEdited: true,
      });
    }
    if (
      currentTransactionData.fuelChargesTransporterAmount !==
      fuelChargesTransporterAmount
    ) {
      this.setState({ fuelChargesTransporterEdited: true });
    }

    if (
      currentTransactionData.maamolChargesTransporterAmount !==
      maamolChargesTransporterAmount
    ) {
      this.setState({ maamolChargesTransporterEdited: true });
    }

    if (
      currentTransactionData.maamolChargesTruckerAmount !==
      maamolChargesTruckerAmount
    ) {
      this.setState({ maamolChargesTruckerEdited: true });
    }
    if (currentTransactionData.expectedDate !== expectedDate) {
      this.setState({ expectedDateEditted: true });
    }
    if (
      currentTransactionData.shortageChargesTruckerAmount !==
      shortageChargesTruckerAmount
    ) {
      this.setState({ shortageChargesTruckerEditted: true });
    }

    if (
      currentTransactionData.lateDeliveryTruckerAmount !==
      lateDeliveryTruckerAmount
    ) {
      this.setState({ lateDeliveryTruckerEditted: true });
    }

    if (currentTransactionData.tdsTruckerAmount !== tdsTruckerAmount) {
      this.setState({ tdsTruckerEditted: true });
    }

    if (
      currentTransactionData.earlySettlementTruckerAmount !==
      earlySettlementTruckerAmount
    ) {
      this.setState({ earlySettlementTruckerEditted: true });
    }

    if (currentTransactionData.latePodTruckerAmount !== latePodTruckerAmount) {
      this.setState({ latePodTruckerEditted: true });
    }

    if (
      currentTransactionData.loadingChargesPaidByTransporter !==
      loadingChargesPaidByTransporter
    ) {
      this.setState({ loadingChargesPaidByTransporterCheckedEditted: true });
    }
    if (
      currentTransactionData.unloadingChargesPaidByTransporter !==
      unloadingChargesPaidByTransporter
    ) {
      this.setState({ unloadingChargesPaidByTransporterCheckedEditted: true });
    }

    if (
      currentTransactionData.hamaliChargesPaidByTransporter !==
      hamaliChargesPaidByTransporter
    ) {
      this.setState({ hamaliChargesPaidByTransporterCheckedEditted: true });
    }
    if (
      currentTransactionData.haltingChargesPaidByTransporter !==
      haltingChargesPaidByTransporter
    ) {
      this.setState({ haltingChargesPaidByTransporterCheckedEditted: true });
    }
    if (
      currentTransactionData.loadingChargesPaidByTrucker !==
      loadingChargesPaidByTrucker
    ) {
      this.setState({ loadingChargesPaidByTruckerCheckedEditted: true });
    }
    if (
      currentTransactionData.unloadingChargesPaidByTrucker !==
      unloadingChargesPaidByTrucker
    ) {
      this.setState({ unloadingChargesPaidByTruckerCheckedEditted: true });
    }

    if (
      currentTransactionData.hamaliChargesPaidByTrucker !==
      hamaliChargesPaidByTrucker
    ) {
      this.setState({ hamaliChargesPaidByTruckerCheckedEditted: true });
    }
    if (
      currentTransactionData.haltingChargesPaidByTrucker !==
      haltingChargesPaidByTrucker
    ) {
      this.setState({ haltingChargesPaidByTruckerCheckedEditted: true });
    }

    if (
      currentTransactionData.docChargesTruckerShowinloadingslip !==
      docChargesTruckerShowinloadingslip
    ) {
      this.setState({ docChargesTruckerShowinloadingslipEditted: true });
    }

    if (
      currentTransactionData.fuelChargesTruckerShowinloadingslip !==
      fuelChargesTruckerShowinloadingslip
    ) {
      this.setState({ fuelChargesTruckerShowinloadingslipEditted: true });
    }

    if (
      currentTransactionData.maamolChargesTruckerShowinloadingslip !==
      maamolChargesTruckerShowinloadingslip
    ) {
      this.setState({ maamolChargesTruckerShowinloadingslipEditted: true });
    }

    if (
      currentTransactionData.shortageChargesTruckerShowinloadingslip !==
      shortageChargesTruckerShowinloadingslip
    ) {
      this.setState({ shortageChargesTruckerShowinloadingslipEditted: true });
    }

    if (
      currentTransactionData.lateDeliveryTruckerShowinloadingslip !==
      lateDeliveryTruckerShowinloadingslip
    ) {
      this.setState({ lateDeliveryTruckerShowinloadingslipEditted: true });
    }

    if (
      currentTransactionData.tdsTruckerShowinloadingslip !==
      tdsTruckerShowinloadingslip
    ) {
      this.setState({ tdsTruckerShowinloadingslipEditted: true });
    }

    if (
      currentTransactionData.earlySettlementTruckerShowinloadingslip !==
      earlySettlementTruckerShowinloadingslip
    ) {
      this.setState({ earlySettlementTruckerShowinloadingslipEditted: true });
    }

    if (
      currentTransactionData.latePodTruckerShowinloadingslip !==
      latePodTruckerShowinloadingslip
    ) {
      this.setState({ latePodTruckerShowinloadingslipEditted: true });
    }
    if (currentTransactionType !== previousTransactionType) {
      this.setState({ expressTripEditted: true });
    }
    if (
      currentTransactionData?.manufacturerId !==
      this.state.currentManufacturerID
    ) {
      this.setState({ manufacturerEditted: true });
    }
    if (currentTransactionData?.supplyId !== this.state.currentSupplyId) {
      this.setState({ supplyIdEditted: true });
    }
    if (currentTransactionData?.demandId !== this.state.currentDemandId) {
      this.setState({ demandIdEditted: true });
    }
  }

  updateTruckerPOC = async () => {
    this.setState({ updatingTruckerPOC: true });
    const ENDPOINT = 'update-transaction-poc';
    const userDetails = firebase.auth().currentUser;
    const { Aa } = userDetails;
    const { REACT_APP_OFFICE_REPORTS_API } = process.env;
    const { truckerPOC } = this.state;
    const data = {
      id: this.state.currentTransactionData.transactionId,
      truckerPocId: truckerPOC.value,
      truckerPocName: truckerPOC.label,
      transporterPocName: this.state.currentTransactionData.transporterPocName,
      transporterPocId: this.state.currentTransactionData.transporterPocId,
    };
    try {
      await axios.post(`${REACT_APP_OFFICE_REPORTS_API}${ENDPOINT}`, data, {
        headers: { Authorization: `Bearer ${Aa}` },
      });
      this.setState({
        updatingTruckerPOC: false,
        editTruckerPOC: false,
      });
      swal(
        'Success',
        `You have updated trucker POC to  ${truckerPOC.label}`,
        'success',
        {
          button: false,
        }
      );
    } catch (error) {
      this.setState({ updatingTruckerPOC: false, editTruckerPOC: false });
    }
  };

  updateTransporterPOC = async () => {
    this.setState({ updatingTransporterPOC: true });
    const ENDPOINT = 'update-transaction-poc';
    const userDetails = firebase.auth().currentUser;
    const { Aa } = userDetails;
    const { REACT_APP_OFFICE_REPORTS_API } = process.env;
    const { transporterPOC } = this.state;
    const data = {
      id: this.state.currentTransactionData.transactionId,
      transporterPocId: transporterPOC.value,
      transporterPocName: transporterPOC.label,
      truckerPocName: this.state.currentTransactionData.truckerPocName,
      truckerPocId: this.state.currentTransactionData.truckerPocId,
    };
    try {
      await axios.post(`${REACT_APP_OFFICE_REPORTS_API}${ENDPOINT}`, data, {
        headers: { Authorization: `Bearer ${Aa}` },
      });
      this.setState({
        updatingTransporterPOC: false,
        editTransporterPOC: false,
      });
      swal(
        'Success',
        `You have updated transporter POC to  ${transporterPOC.label}`,
        'success',
        {
          button: false,
        }
      );
    } catch (error) {
      this.setState({ updatingTransporterPOC: false });
    }
  };

  showEditTrucker(currentTransactionData) {
    const { activeRole } = this.props;
    history.push({
      pathname: `/${activeRole}/truckers/view/${currentTransactionData.truckerId}`,
      state: currentTransactionData.truckerId,
    });
  }

  showEditTransporter(currentTransactionData) {
    const { activeRole } = this.props;
    history.push({
      pathname: `/${activeRole}/transporters/view/${currentTransactionData.transporterId}`,
      state: currentTransactionData.transporterId,
    });
  }

  handleTransporterPOC = customer => {
    this.setState({
      transporterPOC: customer,
    });
  };

  handleTruckerPOC = customer => {
    this.setState({
      truckerPOC: customer,
    });
  };

  handleChange = event => {
    this.setState({ switchOpen: true });
  };

  handleSetTransactionVoid = data => {
    this.setState({ settingVoid: true });
    var transactionRef = db.collection('Transactions').doc(data.transactionId);

    // Set the "capital" field of the city 'DC'
    return transactionRef
      .update({
        void: true,
      })
      .then(() => {
        this.setState({ settingVoid: false });
        swal(
          'Success',
          `You have set transaction ${data.transactionSerial} to void`,
          'success',
          {
            button: false,
          }
        );
      })
      .catch(error => {
        // The document probably doesn't exist.
        console.error('Error updating document: ', error);
        this.setState({ settingVoid: false });
      });
  };

  // Will Return the Detailed Transaction Form
  showDetailedTransactions() {
    const { classes, activeRole } = this.props;
    let approve = false;
    let finance = false;
    const { currentTransactionData } = this.state;
    let { riskStatus } = currentTransactionData;
    if (riskStatus === undefined) riskStatus = 'green';
    let cardHeaderColor = 'info';
    if (riskStatus === 'green') {
      cardHeaderColor = 'success';
    } else if (riskStatus === 'amber') {
      cardHeaderColor = 'warning';
    } else if (riskStatus === 'red') {
      cardHeaderColor = 'danger';
    }
    if (activeRole === 'ops' && currentTransactionData.status === 'pending') {
      approve = true;
    }
    if (activeRole === 'finance') {
      finance = true;
    }
    let isEditedTransaction = false;
    if (currentTransactionData.isEditedTransaction) {
      isEditedTransaction = true;
    }
    const {
      author,
      modifyAlert,
      isButtonLoading,
      vehicleNumberEdited,
      truckerIdEdited,
      transporterIdEdited,
      loadingDateEdited,
      fromCityEdited,
      toCityEdited,
      athReceivableDateEdited,
      expectedDateEdited,
      amountEdited,
      commissionEdited,
      athReceivableEdited,
      bthReceivableEdited,
      athPayableEdited,
      bthPayableEdited,
      truckerPocNameEditted,
      transporterPocNameEditted,
      truckPlacedByNameIsEdited,
      orderRecievedByNameIsEdited,
      tonnageEditted,
      featureFlagFiles,
      expressTripEditted,
      isTruckerBlacklisted,
      isTransporterBlacklisted,
    } = this.state;
    const isTrackingRole = activeRole === 'tracking-role';
    return (
      <GridItem xs={12} sm={12} md={12} lg={12}>
        {modifyAlert}
        <Card style={{ backgroundColor: '#ffffff85' }}>
          <CardHeader
            color={cardHeaderColor}
            className={classes.cardHeaderNoPadding}
          >
            <CardIcon
              color="primary"
              className={`${classes.customCardHeader}`}
              // onClick={() => this.hideOpsDetailedForm()}
              onClick={() =>
                currentTransactionData.status == 'pending'
                  ? this.hideOpsDetailedForm()
                  : this.hideDetailedForm()
              }
            >
              <ArrowBack />
            </CardIcon>
            <h3 className={`${classes.cardTitle}`}>
              {currentTransactionData.isIncorrectPayable ? (
                <span className={classes.rajdhaniFont} style={{ color: 'red' }}>
                  #{currentTransactionData.transactionSerial}
                </span>
              ) : (
                <span className={classes.rajdhaniFont}>
                  #{currentTransactionData.transactionSerial}
                </span>
              )}
            </h3>
            <p className={`${classes.customCardHeader}`}>
              <span className={classes.rajdhaniFont}>
                {moment(currentTransactionData.createdAt.toDate()).format(
                  'MMM Do Y'
                )}
              </span>
            </p>

            <p
              className={`${classes.customCardHeader}`}
              style={{ float: 'right', marginTop: '-50px' }}
            >
              <span className={classes.rajdhaniFont}>{author}</span>
            </p>
            <p
              className={`${classes.customCardHeader}`}
              style={{ float: 'right', marginTop: '-25px' }}
            >
              <span className={classes.rajdhaniFont}>
                {currentTransactionData.branch}
              </span>
            </p>

            {(activeRole === 'ops' || activeRole === 'finance') && !approve ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-end',
                  justifyContent: 'flex-end',
                }}
              >
                <Popup
                  trigger={
                    this.state.settingVoid ? (
                      <CircularProgress />
                    ) : (
                      <Button
                        style={{
                          width: '40px',
                          fontWeight: 'bold',
                          background: '#fcd21f',
                          color: 'black',
                        }}
                        disabled={currentTransactionData.void}
                      >
                        Void
                      </Button>
                    )
                  }
                  {...{
                    contentStyle,
                  }}
                  modal
                >
                  {close => (
                    <div
                      style={{
                        padding: '10px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <HighlightOffIcon fontSize="large" color="error" />
                      <h4 style={{ fontWeight: 'bold' }}>Are You Sure?</h4>
                      <p>If you proceed, you will not be able to recover it</p>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          width: '100%',
                        }}
                      >
                        <Button
                          onClick={() => {
                            close();
                            this.setState({ switchOpen: false });
                          }}
                          style={cancelButton}
                        >
                          No
                        </Button>
                        <Button
                          onClick={() => {
                            close();
                            this.handleSetTransactionVoid(
                              currentTransactionData
                            );
                          }}
                          style={deleteModalButton}
                        >
                          Yes
                        </Button>
                      </div>
                    </div>
                  )}
                </Popup>
              </div>
            ) : (
              ''
            )}

            {approve && (
              <div>
                {isButtonLoading ? (
                  <CircularProgress
                    className={classes.progress}
                    style={{ color: purple[500] }}
                    thickness={7}
                  />
                ) : (
                  <div>
                    <Button
                      onClick={() =>
                        this.rejectTransaction(currentTransactionData)
                      }
                      className={classes.updateProfileButton}
                      disabled={featureFlagFiles === 1}
                    >
                      Reject
                    </Button>
                    <Tooltip title="Coming soon...">
                      <span>
                        <Button
                          color="warning"
                          onClick={() =>
                            this.inputAlert(currentTransactionData)
                          }
                          className={classes.updateProfileButton}
                          disabled
                        >
                          Modify
                        </Button>
                      </span>
                    </Tooltip>

                    <Button
                      color="success"
                      onClick={() =>
                        this.confirmBankDetails(currentTransactionData)
                      }
                      className={classes.updateProfileButton}
                      disabled={featureFlagFiles === 1}
                    >
                      Approve
                    </Button>
                  </div>
                )}
              </div>
            )}
          </CardHeader>
          <CardBody
            className={`${classes.cardHeader} ${classes.textCenter} ${classes.noPaddingCardBody}`}
          >
            <GridContainer spacing={2}>
              {currentTransactionData.isEditedTransaction &&
                activeRole !== 'sales' &&
                activeRole !== 'traffic' && (
                  <GridItem xs={12} sm={12} md={12}>
                    <h3 className={`${classes.cardTitle}`}>
                      <span className={classes.rajdhaniFont}>New Details</span>
                    </h3>
                  </GridItem>
                )}
              <Card className={classes.customCard}>
                <GridContainer>
                  <GridItem
                    xs={12}
                    sm={12}
                    md={12}
                    style={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <FormControl component="fieldset">
                      <RadioGroup
                        aria-label="transactionType"
                        name="transactionType"
                        value={
                          currentTransactionData?.expressTrip
                            ? 'Express'
                            : 'TAT'
                        }
                        // onChange={this.handleChangeTransactionType}
                        row
                      >
                        {currentTransactionData?.expressTrip ? (
                          <FormControlLabel
                            value="Express"
                            // disabled
                            control={
                              expressTripEditted ? (
                                <GreenRadio style={disabledRadioStyles} />
                              ) : (
                                <Radio
                                  style={disabledRadioStyles}
                                  color="primary"
                                />
                              )
                            }
                            label={
                              <Typography
                                variant="subtitle2"
                                style={{ color: expressTripEditted && 'green' }}
                              >
                                TAT & Express
                              </Typography>
                            }
                          />
                        ) : (
                          <FormControlLabel
                            value="TAT"
                            // disabled
                            control={
                              expressTripEditted ? (
                                <GreenRadio style={disabledRadioStyles} />
                              ) : (
                                <Radio
                                  style={disabledRadioStyles}
                                  color="primary"
                                />
                              )
                            }
                            label={
                              <Typography
                                variant="subtitle2"
                                style={{ color: expressTripEditted && 'green' }}
                              >
                                Normal
                              </Typography>
                            }
                          />
                        )}
                      </RadioGroup>
                    </FormControl>
                  </GridItem>
                  <GridItem
                    xs={6}
                    sm={6}
                    md={3}
                    style={{ float: 'left', textAlign: 'left' }}
                  >
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                      style={{ float: 'left !important' }}
                    >
                      From City
                    </InputLabel>
                    <h5
                      className={`${classes.h5NoMargin} ${fromCityEdited &&
                        classes.isEdited}`}
                    >
                      {currentTransactionData.fromCity}
                    </h5>
                  </GridItem>
                  <GridItem
                    xs={6}
                    sm={6}
                    md={3}
                    style={{ float: 'left', textAlign: 'left' }}
                  >
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                      style={{ float: 'left !important' }}
                    >
                      To City
                    </InputLabel>
                    <h5
                      className={`${classes.h5NoMargin} ${toCityEdited &&
                        classes.isEdited}`}
                    >
                      {currentTransactionData.toCity}
                    </h5>
                  </GridItem>

                  <GridItem
                    xs={4}
                    sm={4}
                    md={2}
                    style={{ float: 'left', textAlign: 'left' }}
                  >
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                      style={{ float: 'left !important' }}
                    >
                      Loading Date
                    </InputLabel>
                    <h5
                      className={`${classes.h5NoMargin} ${loadingDateEdited &&
                        classes.isEdited}`}
                    >
                      {currentTransactionData.loadingDate}
                    </h5>
                  </GridItem>

                  {isTrackingRole ? null : (
                    <GridItem
                      xs={4}
                      sm={4}
                      md={2}
                      style={{ float: 'left', textAlign: 'left' }}
                    >
                      <InputLabel
                        htmlFor="simple-select"
                        className={classes.selectLabel}
                        style={{ float: 'left !important' }}
                      >
                        Ath Receivable Date
                      </InputLabel>
                      <h5
                        className={`${
                          classes.h5NoMargin
                        } ${athReceivableDateEdited && classes.isEdited}`}
                      >
                        {currentTransactionData.athReceivableDate}
                      </h5>
                    </GridItem>
                  )}

                  <GridItem
                    xs={4}
                    sm={4}
                    md={2}
                    style={{ float: 'left', textAlign: 'left' }}
                  >
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                      style={{ float: 'left !important' }}
                    >
                      Expected Date
                    </InputLabel>
                    <h5
                      className={`${classes.h5NoMargin} ${expectedDateEdited &&
                        classes.isEdited}`}
                    >
                      {currentTransactionData?.expectedDate
                        ? currentTransactionData?.expectedDate
                        : null}
                    </h5>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12}>
                    <hr style={{ marginTop: '2px', marginBottom: '5px' }} />
                  </GridItem>
                  {isTrackingRole ? null : (
                    <GridItem
                      xs={6}
                      sm={6}
                      md={3}
                      style={{ float: 'left', textAlign: 'left' }}
                    >
                      <InputLabel
                        htmlFor="simple-select"
                        className={classes.selectLabel}
                        style={{ float: 'left !important' }}
                      >
                        Transporter
                      </InputLabel>
                      {(activeRole === 'sales' ||
                        activeRole === 'ops' ||
                        activeRole === 'traffic' ||
                        activeRole === 'branch-ops') &&
                      !isTransporterBlacklisted ? (
                        <button
                          type="button"
                          style={hyperlinkButton}
                          onClick={() =>
                            this.showEditTransporter(currentTransactionData)
                          }
                        >
                          <h5
                            className={`${
                              classes.h5NoMargin
                            } ${transporterIdEdited && classes.isEdited}`}
                            style={{
                              textDecoration: isTransporterBlacklisted
                                ? 'line-through'
                                : 'none',
                              textDecorationColor: isTransporterBlacklisted
                                ? 'red'
                                : 'initial',
                            }}
                          >
                            {currentTransactionData.transporterName}
                          </h5>
                        </button>
                      ) : (
                        <h5
                          className={`${
                            classes.h5NoMargin
                          } ${transporterIdEdited && classes.isEdited}`}
                          style={{
                            textDecoration: isTransporterBlacklisted
                              ? 'line-through'
                              : 'none',
                            textDecorationColor: isTransporterBlacklisted
                              ? 'red'
                              : 'initial',
                          }}
                        >
                          {currentTransactionData.transporterName}
                        </h5>
                      )}
                    </GridItem>
                  )}
                  {isTrackingRole ? null : (
                    <GridItem
                      xs={6}
                      sm={6}
                      md={3}
                      style={{ float: 'left', textAlign: 'left' }}
                    >
                      <InputLabel
                        htmlFor="simple-select"
                        className={classes.selectLabel}
                        style={{ float: 'left !important' }}
                      >
                        Transporter POC
                      </InputLabel>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        {this.state.editTransporterPOC ? (
                          <div
                            style={{
                              width: '100%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            }}
                          >
                            <div style={{ width: '80%' }}>
                              <ReactSelect
                                value={this.state.transporterPOC}
                                onChange={this.handleTransporterPOC}
                                options={this.state.transporterCustomers}
                                placeholder="Select Transporter POC"
                              />
                            </div>
                            {this.state.updatingTransporterPOC ? (
                              <CircularProgress />
                            ) : (
                              <IconButton
                                onClick={() => this.updateTransporterPOC()}
                              >
                                <CheckIcon />
                              </IconButton>
                            )}
                          </div>
                        ) : (
                          <>
                            <h5
                              className={`${
                                classes.h5NoMargin
                              } ${transporterPocNameEditted &&
                                classes.isEdited}`}
                            >
                              {currentTransactionData?.transporterPocName}
                            </h5>
                            {(this.props.activeRole === 'sales' ||
                              this.props.activeRole === 'ops' ||
                              this.props.activeRole === 'traffic') &&
                            !currentTransactionData.void ? (
                              <IconButton
                                onClick={() =>
                                  this.setState({ editTransporterPOC: true })
                                }
                                disabled={
                                  currentTransactionData.status === 'pending'
                                }
                              >
                                <EditIcon />
                              </IconButton>
                            ) : (
                              ''
                            )}
                          </>
                        )}
                      </div>
                    </GridItem>
                  )}
                  <GridItem
                    xs={6}
                    sm={6}
                    md={3}
                    style={{ float: 'left', textAlign: 'left' }}
                  >
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                      style={{ float: 'left !important' }}
                    >
                      Trucker
                    </InputLabel>
                    {(activeRole === 'sales' ||
                      activeRole === 'ops' ||
                      activeRole === 'traffic' ||
                      activeRole === 'branch-ops') &&
                    !isTruckerBlacklisted ? (
                      <button
                        type="button"
                        style={hyperlinkButton}
                        onClick={() =>
                          this.showEditTrucker(currentTransactionData)
                        }
                      >
                        <h5
                          className={`${classes.h5NoMargin} ${truckerIdEdited &&
                            classes.isEdited}`}
                          style={{
                            textDecoration: isTruckerBlacklisted
                              ? 'line-through'
                              : 'none',
                            textDecorationColor: isTruckerBlacklisted
                              ? 'red'
                              : 'initial',
                          }}
                        >
                          {currentTransactionData.truckerName}
                        </h5>
                      </button>
                    ) : (
                      <h5
                        className={`${classes.h5NoMargin} ${truckerIdEdited &&
                          classes.isEdited}`}
                        style={{
                          textDecoration: isTruckerBlacklisted
                            ? 'line-through'
                            : 'none',
                          textDecorationColor: isTruckerBlacklisted
                            ? 'red'
                            : 'initial',
                        }}
                      >
                        {currentTransactionData.truckerName}
                      </h5>
                    )}
                  </GridItem>
                  <GridItem
                    xs={6}
                    sm={6}
                    md={3}
                    style={{ float: 'left', textAlign: 'left' }}
                  >
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                      style={{ float: 'left !important' }}
                    >
                      Trucker POC
                    </InputLabel>
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      {this.state.editTruckerPOC ? (
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          <div style={{ width: '80%' }}>
                            <ReactSelect
                              value={this.state.truckerPOC}
                              onChange={this.handleTruckerPOC}
                              options={this.state.truckerCustomers}
                              placeholder="Select Trucker POC"
                            />
                          </div>

                          {this.state.updatingTruckerPOC ? (
                            <CircularProgress />
                          ) : (
                            <IconButton onClick={() => this.updateTruckerPOC()}>
                              <CheckIcon />
                            </IconButton>
                          )}
                        </div>
                      ) : (
                        <>
                          <h5
                            className={`${
                              classes.h5NoMargin
                            } ${truckerPocNameEditted && classes.isEdited}`}
                          >
                            {currentTransactionData?.truckerPocName}
                          </h5>
                          {(this.props.activeRole === 'sales' ||
                            this.props.activeRole === 'ops' ||
                            this.props.activeRole === 'traffic') &&
                          !currentTransactionData.void ? (
                            <IconButton
                              onClick={() =>
                                this.setState({ editTruckerPOC: true })
                              }
                              disabled={
                                currentTransactionData.status === 'pending'
                              }
                            >
                              <EditIcon />
                            </IconButton>
                          ) : (
                            ''
                          )}
                        </>
                      )}
                    </div>
                  </GridItem>
                  {isTrackingRole ? null : (
                    <GridItem xs={12} sm={12} md={12}>
                      <hr style={{ marginTop: '2px', marginBottom: '5px' }} />
                    </GridItem>
                  )}
                  {isTrackingRole ? null : (
                    <GridItem
                      xs={6}
                      sm={6}
                      md={2}
                      style={{ float: 'left', textAlign: 'left' }}
                    >
                      <InputLabel
                        htmlFor="simple-select"
                        className={classes.selectLabel}
                        style={{ float: 'left !important' }}
                      >
                        Order Recieved By
                      </InputLabel>
                      <h5
                        className={`${
                          classes.h5NoMargin
                        } ${orderRecievedByNameIsEdited && classes.isEdited}`}
                      >
                        {currentTransactionData?.orderRecievedByName}
                      </h5>
                    </GridItem>
                  )}
                  {isTrackingRole ? null : (
                    <GridItem
                      xs={6}
                      sm={6}
                      md={2}
                      style={{ float: 'left', textAlign: 'left' }}
                    >
                      <InputLabel
                        htmlFor="simple-select"
                        className={classes.selectLabel}
                        style={{ float: 'left !important' }}
                      >
                        Truck Placed By
                      </InputLabel>
                      <h5
                        className={`${
                          classes.h5NoMargin
                        } ${truckPlacedByNameIsEdited && classes.isEdited}`}
                      >
                        {currentTransactionData?.truckPlacedByName}
                      </h5>
                    </GridItem>
                  )}

                  <GridItem
                    xs={6}
                    sm={6}
                    md={2}
                    style={{ float: 'left', textAlign: 'left' }}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div>
                        <InputLabel
                          htmlFor="simple-select"
                          className={classes.selectLabel}
                          style={{ float: 'left !important' }}
                        >
                          Truck No
                        </InputLabel>
                        <h5
                          className={`${
                            classes.h5NoMargin
                          } ${vehicleNumberEdited && classes.isEdited}`}
                        >
                          {currentTransactionData.vehicleNumber}
                        </h5>
                      </div>
                      {this.state.truckRCFileURL && (
                        <div>
                          <Button
                            variant="contained"
                            style={{
                              width: '20px',
                              padding: '10px 20px',
                              background: '#163BAC',
                              marginLeft: '5px',
                            }}
                            onClick={() =>
                              window.open(this.state.truckRCFileURL, '_blank')
                            }
                          >
                            <VisibilityIcon color="white" />
                          </Button>
                        </div>
                      )}
                    </div>
                  </GridItem>
                  <GridItem
                    xs={6}
                    sm={6}
                    md={2}
                    style={{ float: 'left', textAlign: 'left' }}
                  >
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                      style={{ float: 'left !important' }}
                    >
                      Truck Type
                    </InputLabel>
                    <h5 className={classes.h5NoMargin}>
                      {currentTransactionData.truckType}
                    </h5>
                  </GridItem>
                  {isTrackingRole ? null : (
                    <GridItem
                      xs={6}
                      sm={6}
                      md={2}
                      style={{ float: 'left', textAlign: 'left' }}
                    >
                      <InputLabel
                        htmlFor="simple-select"
                        className={classes.selectLabel}
                        style={{ float: 'left !important' }}
                      >
                        Tonnage
                      </InputLabel>
                      <h5
                        className={`${classes.h5NoMargin} ${tonnageEditted &&
                          classes.isEdited}`}
                      >
                        {currentTransactionData?.tonnage}
                      </h5>
                    </GridItem>
                  )}
                  <GridItem
                    xs={6}
                    sm={6}
                    md={2}
                    style={{ float: 'left', textAlign: 'left' }}
                  >
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                      style={{ float: 'left !important' }}
                    >
                      Driver Phone Number
                    </InputLabel>
                    <h5
                      className={`${classes.h5NoMargin} ${tonnageEditted &&
                        classes.isEdited}`}
                    >
                      {currentTransactionData?.driversPhoneNumber}
                    </h5>
                  </GridItem>
                  {isTrackingRole ? null : (
                    <>
                      <GridItem xs={12} sm={12} md={12}>
                        <hr style={{ marginTop: '2px', marginBottom: '5px' }} />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <GridContainer style={{ marginLeft: '-10px' }}>
                          <GridItem
                            xs={6}
                            sm={6}
                            md={2}
                            style={{ float: 'left', textAlign: 'left' }}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                              style={{ float: 'left !important' }}
                            >
                              Freight
                            </InputLabel>
                            <h5
                              className={`${
                                classes.h5NoMargin
                              } ${amountEdited && classes.isEdited}`}
                            >
                              {Intl.NumberFormat('en-IN').format(
                                currentTransactionData.amount
                              )}
                            </h5>
                          </GridItem>
                          <GridItem
                            xs={6}
                            sm={6}
                            md={2}
                            style={{ float: 'left', textAlign: 'left' }}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                              style={{ float: 'left !important' }}
                            >
                              Commission
                            </InputLabel>
                            <h5
                              className={`${
                                classes.h5NoMargin
                              } ${commissionEdited && classes.isEdited}`}
                            >
                              {Intl.NumberFormat('en-IN').format(
                                currentTransactionData.commission
                              )}
                            </h5>
                          </GridItem>
                          <GridItem
                            xs={6}
                            sm={6}
                            md={2}
                            style={{ float: 'left', textAlign: 'left' }}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                              style={{ float: 'left !important' }}
                            >
                              Ath Receivable
                            </InputLabel>
                            <h5
                              className={`${
                                classes.h5NoMargin
                              } ${athReceivableEdited && classes.isEdited}`}
                            >
                              {Intl.NumberFormat('en-IN').format(
                                currentTransactionData.athReceivable
                              )}
                            </h5>
                          </GridItem>
                          <GridItem
                            xs={6}
                            sm={6}
                            md={2}
                            style={{ float: 'left', textAlign: 'left' }}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                              style={{ float: 'left !important' }}
                            >
                              Bth Receivable
                            </InputLabel>
                            <h5
                              className={`${
                                classes.h5NoMargin
                              } ${bthReceivableEdited && classes.isEdited}`}
                            >
                              {Intl.NumberFormat('en-IN').format(
                                currentTransactionData.bthReceivable
                              )}
                            </h5>
                          </GridItem>
                          <GridItem
                            xs={6}
                            sm={6}
                            md={2}
                            style={{ float: 'left', textAlign: 'left' }}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                              style={{ float: 'left !important' }}
                            >
                              Trucker ATH
                            </InputLabel>
                            <h5
                              className={`${
                                classes.h5NoMargin
                              } ${athPayableEdited && classes.isEdited}`}
                            >
                              {Intl.NumberFormat('en-IN').format(
                                currentTransactionData.athPayable
                              )}
                            </h5>
                          </GridItem>
                          <GridItem
                            xs={6}
                            sm={6}
                            md={2}
                            style={{ float: 'left', textAlign: 'left' }}
                          >
                            <InputLabel
                              htmlFor="simple-select"
                              className={classes.selectLabel}
                              style={{ float: 'left !important' }}
                            >
                              Trucker BTH
                            </InputLabel>
                            <h5
                              className={`${
                                classes.h5NoMargin
                              } ${bthPayableEdited && classes.isEdited}`}
                            >
                              {Intl.NumberFormat('en-IN').format(
                                currentTransactionData.bthPayable
                              )}
                            </h5>
                          </GridItem>
                          <GridItem xs={12} sm={12} md={12}>
                            <hr
                              style={{ marginTop: '2px', marginBottom: '5px' }}
                            />
                          </GridItem>
                          {this.getLoadingAndUnloading(false)}
                          <GridItem xs={12} sm={6} md={6} />

                          <GridItem xs={12} sm={12} md={12}>
                            <hr
                              style={{ marginTop: '2px', marginBottom: '5px' }}
                            />
                          </GridItem>
                        </GridContainer>
                      </GridItem>
                    </>
                  )}

                  {isTrackingRole ? null : (
                    <>
                      <GridItem xs={12} sm={12} md={12}>
                        <hr style={{ marginTop: '2px', marginBottom: '5px' }} />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        {this.deductions(
                          currentTransactionData,
                          isEditedTransaction,
                          false
                        )}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <hr style={{ marginTop: '2px', marginBottom: '5px' }} />
                      </GridItem>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={6}
                        style={{ float: 'left', textAlign: 'left' }}
                      >
                        <GridContainer>
                          <GridItem
                            xs={12}
                            sm={12}
                            md={12}
                            style={{ float: 'left', textAlign: 'left' }}
                          >
                            <h5 className={classes.customCardIconTitle}>POD</h5>
                          </GridItem>
                          {this.getCollectedDates()}
                          {this.getSubmittedDates()}
                        </GridContainer>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={4}>
                        {this.autoCalculated(false, isEditedTransaction, false)}
                      </GridItem>
                    </>
                  )}
                </GridContainer>
              </Card>

              {isTrackingRole ||
              currentTransactionData.isEditedTransaction !== false ? null : (
                <>
                  <RatingsCard
                    activeRole={activeRole}
                    transactionSerial={currentTransactionData.transactionSerial}
                    currentTransactionData={this.state.currentTransactionData}
                  />
                </>
              )}
            </GridContainer>

            {currentTransactionData.isEditedTransaction === true &&
              activeRole !== 'sales' &&
              activeRole !== 'traffic' &&
              this.oldDetails()}
            {finance ? (
              currentTransactionData.checkOne &&
              currentTransactionData.checkTwo ? (
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <Button
                      color="success"
                      className={classes.updateProfileButton}
                      onClick={() => this.handleClickOpen('classicModal')}
                    >
                      View Trucker Bank Details
                    </Button>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <PayableHistory
                      currentTransactionData={currentTransactionData}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <ReceivableHistory
                      currentTransactionData={currentTransactionData}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={12} lg={12}>
                    <PayoutsTable
                      singleTransaction
                      currentTransactionData={currentTransactionData}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        {activeRole === 'sales' ||
                        activeRole === 'traffic' ? null : (
                          <CommentSection />
                        )}
                      </GridItem>

                      {activeRole === 'finance' && (
                        <GridItem xs={12} sm={12} md={12}>
                          <NewFieldsContainer
                            currentTransactionData={currentTransactionData}
                            activeRole={activeRole}
                            featureFlagFiles={featureFlagFiles}
                          />
                        </GridItem>
                      )}
                    </GridContainer>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <GridContainer>
                      {currentTransactionData.status === 'approved' && (
                        <GridItem xs={12} sm={12} md={12}>
                          <VehicleStatus />
                        </GridItem>
                      )}
                      {activeRole == 'finance' ||
                      activeRole == 'finance-payable' ? (
                        <GridItem xs={12} sm={12} md={12}>
                          <PODFileUpload
                            currentTransactionData={currentTransactionData}
                            activeRole={activeRole}
                            stylesClass={this.props}
                            featureFlagFiles={featureFlagFiles}
                          />
                        </GridItem>
                      ) : null}

                      <GridItem xs={12} sm={12} md={12}>
                        {activeRole === 'finance-recievable' ||
                        activeRole == 'finance-payable' ||
                        activeRole == 'transaction-view' ? null : (
                          <EwayBillUpload
                            currentTransactionData={currentTransactionData}
                            activeRole={activeRole}
                            stylesClass={this.props}
                            featureFlagFiles={featureFlagFiles}
                          />
                        )}
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
              ) : (
                <GridContainer>
                  {!currentTransactionData.void ? (
                    <GridItem xs={12} sm={12} md={12}>
                      <Button
                        color="success"
                        className={classes.updateProfileButton}
                        onClick={() => this.showChecksAlert()}
                      >
                        Finance Approval
                      </Button>
                    </GridItem>
                  ) : null}
                </GridContainer>
              )
            ) : (
              <GridContainer>
                {isTrackingRole ? null : (
                  <>
                    <GridItem xs={12} sm={12} md={12}>
                      <Button
                        color="success"
                        className={classes.updateProfileButton}
                        onClick={
                          () => this.handleClickOpen('classicModal') // onClick={() => this.showTruckerDetails()}
                        }
                      >
                        View Trucker Bank Details
                      </Button>
                    </GridItem>
                  </>
                )}
                {/* If Admin  Approval Page, do not show ATH/BTH History */
                approve ? null : (
                  <React.Fragment>
                    {isTrackingRole ? null : (
                      <>
                        <GridItem xs={12} sm={12} md={6}>
                          <PayableHistory
                            currentTransactionData={currentTransactionData}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <ReceivableHistory
                            currentTransactionData={currentTransactionData}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <PayoutsTable
                            singleTransaction
                            currentTransactionData={currentTransactionData}
                          />
                        </GridItem>
                      </>
                    )}
                    <GridItem xs={12} sm={12} md={6}>
                      <GridContainer>
                        {currentTransactionData.status === 'approved' && (
                          <GridItem xs={12} sm={12} md={12}>
                            <VehicleStatus isTrackingRole={isTrackingRole} />
                          </GridItem>
                        )}
                        {isTrackingRole ? null : (
                          <>
                            <GridItem xs={12} sm={12} md={12}>
                              {activeRole === 'finance-recievable' ? null : (
                                <PODFileUpload
                                  currentTransactionData={
                                    currentTransactionData
                                  }
                                  activeRole={activeRole}
                                  stylesClass={this.props}
                                  featureFlagFiles={featureFlagFiles}
                                />
                              )}
                            </GridItem>

                            <GridItem xs={12} sm={12} md={12}>
                              <EwayBillUpload
                                currentTransactionData={currentTransactionData}
                                activeRole={activeRole}
                                stylesClass={this.props}
                                featureFlagFiles={featureFlagFiles}
                              />
                            </GridItem>
                          </>
                        )}
                        {isTrackingRole ? null : (
                          <>
                            <GridItem xs={12} sm={12} md={12}>
                              {activeRole === 'finance-recievable' ||
                              activeRole === 'finance-payable' ||
                              activeRole === 'transaction-view' ? null : (
                                <TrackingLink
                                  stylesClass={this.props}
                                  currentTransactionData={
                                    currentTransactionData
                                  }
                                  activeRole={activeRole}
                                />
                              )}
                            </GridItem>
                          </>
                        )}
                      </GridContainer>
                    </GridItem>

                    {isTrackingRole ? (
                      <GridItem xs={12} sm={12} md={6}>
                        <TrackingLink
                          stylesClass={this.props}
                          currentTransactionData={currentTransactionData}
                          activeRole={activeRole}
                        />
                      </GridItem>
                    ) : (
                      <>
                        <GridItem xs={12} sm={12} md={6}>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                              {activeRole === 'finance-recievable' ||
                              activeRole === 'finance-payable' ||
                              activeRole === 'transaction-view' ? null : (
                                <LoadingSlipDownload
                                  currentTransactionData={
                                    currentTransactionData
                                  }
                                  activeRole={activeRole}
                                  stylesClass={this.props}
                                />
                              )}
                            </GridItem>
                            <GridItem xs={12} sm={12} md={12}>
                              <ConfirmationDoc
                                currentTransactionData={currentTransactionData}
                                activeRole={activeRole}
                                stylesClass={this.props}
                              />
                            </GridItem>
                            {activeRole === 'sales' ||
                            activeRole === 'ops' ||
                            activeRole === 'traffic' ||
                            activeRole === 'branch-ops' ? (
                              <GridItem xs={12} sm={12} md={12}>
                                <NewFieldsContainer
                                  currentTransactionData={
                                    currentTransactionData
                                  }
                                  activeRole={activeRole}
                                  featureFlagFiles={featureFlagFiles}
                                />
                              </GridItem>
                            ) : null}
                            <GridItem xs={12} sm={12} md={12}>
                              {activeRole === 'finance-recievable' ||
                              activeRole === 'finance-payable' ||
                              activeRole === 'transaction-view' ? null : (
                                <DrivingLicenseUploader
                                  currentTransactionData={
                                    currentTransactionData
                                  }
                                  activeRole={activeRole}
                                  stylesClass={this.props}
                                  featureFlagFiles={featureFlagFiles}
                                />
                              )}
                            </GridItem>
                          </GridContainer>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          <Divider style={{ marginTop: '20px' }} />
                        </GridItem>
                        {activeRole === 'sales' ||
                        activeRole === 'traffic' ? null : (
                          <GridItem xs={12} sm={12} md={6}>
                            <CommentSection />
                          </GridItem>
                        )}
                        {activeRole === 'sales' ||
                        activeRole === 'ops' ||
                        activeRole === 'traffic' ||
                        activeRole === 'branch-ops' ? (
                          <GridItem xs={12} sm={12} md={6}>
                            <SalesCommentSection
                              transactionId={
                                currentTransactionData?.transactionId
                              }
                              activeRole={activeRole}
                              featureFlagFiles={featureFlagFiles}
                            />
                          </GridItem>
                        ) : null}
                        <GridItem xs={12} sm={12} md={6}>
                          <PODCommentsSection
                            transactionId={
                              currentTransactionData?.transactionId
                            }
                            activeRole={activeRole}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={6}>
                          <GeneralCommentsSection
                            transactionId={
                              currentTransactionData?.transactionId
                            }
                            activeRole={activeRole}
                          />
                        </GridItem>
                      </>
                    )}
                  </React.Fragment>
                )}
              </GridContainer>
            )}
          </CardBody>
          <CardFooter stats>
            <div className={classes.stats} />
          </CardFooter>
        </Card>
      </GridItem>
    );
  }

  getCollectedDates() {
    const { classes, userDetails, approve } = this.props;
    const {
      collectedDate,
      isLoadingCollected,
      isCollected,
      collectedError,
      currentTransactionData,
    } = this.state;
    let showReset = false;
    let allowEdit = false;
    if (currentTransactionData.status === 'approved' && !approve) {
      allowEdit = true;
    }
    if (userDetails.isAdmin || userDetails.isOps) {
      showReset = true;
    }
    return (
      <GridItem
        xs={12}
        sm={12}
        md={6}
        style={{ float: 'left', textAlign: 'left' }}
      >
        {isCollected ? (
          <div>
            <InputLabel
              htmlFor="simple-select"
              className={classes.selectLabel}
              style={{ float: 'left !important' }}
            >
              Collected Date
            </InputLabel>
            <div style={{ display: 'flex', justifyContent: 'start' }}>
              <h5 className={`${classes.h5NoMargin}`}>
                {moment(collectedDate.toDate()).format('MMM Do Y')}
              </h5>
              {showReset ? (
                <>
                  {isLoadingCollected ? (
                    <CircularProgress
                      className={classes.progress}
                      style={{ color: purple[500] }}
                      thickness={7}
                    />
                  ) : !currentTransactionData.void ? (
                    <Button
                      color="danger"
                      className={classes.updateProfileButton}
                      onClick={() => this.resetCollected()}
                      size="lg"
                      justIcon
                      simple
                      style={{ paddingTop: 0, marginTop: 0 }}
                    >
                      <Refresh className={classes.underChartIcons} />
                    </Button>
                  ) : null}
                </>
              ) : null}
            </div>
          </div>
        ) : (
          <>
            <InputLabel
              htmlFor="simple-select"
              className={classes.selectLabel}
              style={{ float: 'left !important' }}
            >
              Collected Date
            </InputLabel>
            <div style={{ display: 'flex', justifyContent: 'start' }}>
              <FormControl>
                <Datetime
                  inputProps={{
                    placeholder: 'Select Collected Date',
                    disabled: !allowEdit,
                  }}
                  onChange={this.collectedDateChange}
                  value={collectedDate}
                  timeFormat={false}
                />
              </FormControl>
              {currentTransactionData.void ? null : isLoadingCollected ? (
                <CircularProgress
                  className={classes.progress}
                  style={{ color: purple[500] }}
                  thickness={7}
                />
              ) : (
                <Button
                  size="md"
                  color="success"
                  simple
                  justIcon
                  round
                  onClick={() => this.submitCollected()}
                  disabled={!allowEdit}
                  style={{ paddingTop: 0, marginTop: 0 }}
                >
                  <Done className={classes.underChartIcons} />
                </Button>
              )}
            </div>
            <p style={{ color: 'red' }}>{collectedError}</p>
          </>
        )}
      </GridItem>
    );
  }

  getSubmittedDates() {
    const { classes, userDetails, approve } = this.props;
    const {
      isCollected,
      isLoadingSubmitted,
      submittedDate,
      isSubmitted,
      submittedError,
      currentTransactionData,
    } = this.state;
    let allowEdit = false;
    if (currentTransactionData.status === 'approved' && !approve) {
      allowEdit = true;
    }
    const showSubmitted = !isCollected;
    let showReset = false;
    if (userDetails.isAdmin || userDetails.isOps) {
      showReset = true;
    }
    return (
      <GridItem
        xs={12}
        sm={12}
        md={6}
        style={{ float: 'left', textAlign: 'left' }}
      >
        {isSubmitted ? (
          <div>
            <InputLabel
              htmlFor="simple-select"
              className={classes.selectLabel}
              style={{ float: 'left !important' }}
            >
              Submitted Date
            </InputLabel>
            <div style={{ display: 'flex', justifyContent: 'start' }}>
              <h5 className={`${classes.h5NoMargin}`}>
                {moment(submittedDate?.toDate()).format('MMM Do Y')}
              </h5>
              {showReset ? (
                <>
                  {isLoadingSubmitted ? (
                    <CircularProgress
                      className={classes.progress}
                      style={{ color: purple[500] }}
                      thickness={7}
                    />
                  ) : !currentTransactionData.void ? (
                    <Button
                      color="danger"
                      className={classes.updateProfileButton}
                      onClick={() => this.resetSubmitted()}
                      size="lg"
                      justIcon
                      simple
                      style={{ paddingTop: 0, marginTop: 0 }}
                    >
                      <Refresh className={classes.underChartIcons} />
                    </Button>
                  ) : null}
                </>
              ) : null}
            </div>
          </div>
        ) : (
          <>
            <InputLabel
              htmlFor="simple-select"
              className={classes.selectLabel}
              style={{ float: 'left !important' }}
            >
              Submitted Date
            </InputLabel>
            <div style={{ display: 'flex', justifyContent: 'start' }}>
              <FormControl>
                <Datetime
                  inputProps={{
                    placeholder: 'Select Submitted Date',
                    disabled: !allowEdit,
                  }}
                  onChange={this.submittedDateChange}
                  value={submittedDate}
                  timeFormat={false}
                />
              </FormControl>
              {currentTransactionData.void ? null : isLoadingSubmitted ? (
                <CircularProgress
                  className={classes.progress}
                  style={{ color: purple[500] }}
                  thickness={7}
                />
              ) : (
                <Button
                  size="lg"
                  color="success"
                  simple
                  justIcon
                  round
                  onClick={() => this.submitSubmitted()}
                  disabled={!allowEdit}
                  style={{ paddingTop: 0, marginTop: 0 }}
                >
                  <Done className={classes.underChartIcons} />
                </Button>
              )}
            </div>
            <p style={{ color: 'red' }}>{submittedError}</p>
          </>
        )}
      </GridItem>
    );
  }

  getLoadingAndUnloading(oldDetails) {
    const { classes, currentTransactionData } = this.props;
    const {
      loadingPointEdited,
      unLoadingPointEdited,
      currentManufacturerName,
      manufacturerEditted,
      currentSupplyId,
      currentDemandId,
      supplyIdEditted,
      demandIdEditted,
    } = this.state;
    const manufacturer = currentTransactionData?.manufacturerName || '---';
    return (
      <React.Fragment>
        <GridItem
          xs={6}
          sm={6}
          md={3}
          style={{ float: 'left', textAlign: 'left' }}
        >
          <InputLabel
            htmlFor="simple-select"
            className={classes.selectLabel}
            style={{ float: 'left !important' }}
          >
            loading Point
          </InputLabel>
          <h5
            className={`${classes.h5NoMargin} ${loadingPointEdited &&
              classes.isEdited} ${unLoadingPointEdited &&
              oldDetails &&
              classes.isEditedOld}`}
          >
            {oldDetails
              ? this.state.oldAddress || 'None'
              : this.state.address || 'None'}
          </h5>
        </GridItem>
        <GridItem
          xs={6}
          sm={6}
          md={3}
          style={{ float: 'left', textAlign: 'left' }}
        >
          <InputLabel
            htmlFor="simple-select"
            className={classes.selectLabel}
            style={{ float: 'left !important' }}
          >
            Unloading Point
          </InputLabel>
          <h5
            className={`${classes.h5NoMargin} ${unLoadingPointEdited &&
              classes.isEdited} ${unLoadingPointEdited &&
              oldDetails &&
              classes.isEditedOld}`}
          >
            {oldDetails
              ? this.state.oldAddressUnloading || 'None'
              : this.state.addressUnloading || 'None'}
          </h5>
        </GridItem>
        <GridItem
          xs={6}
          sm={6}
          md={3}
          style={{ float: 'left', textAlign: 'left' }}
        >
          <InputLabel
            htmlFor="simple-select"
            className={classes.selectLabel}
            style={{ float: 'left !important' }}
          >
            Manufacturer
          </InputLabel>
          <h5
            className={`${classes.h5NoMargin} ${manufacturerEditted &&
              classes.isEdited} ${manufacturerEditted &&
              oldDetails &&
              classes.isEditedOld}`}
          >
            {oldDetails ? currentManufacturerName || 'None' : manufacturer}
          </h5>
        </GridItem>
        <GridItem
          xs={6}
          sm={6}
          md={1}
          style={{ float: 'left', textAlign: 'left' }}
        >
          <InputLabel
            htmlFor="simple-select"
            className={classes.selectLabel}
            style={{ float: 'left !important' }}
          >
            Supply ID
          </InputLabel>
          <h5
            className={`${classes.h5NoMargin} ${supplyIdEditted &&
              classes.isEdited} ${supplyIdEditted &&
              oldDetails &&
              classes.isEditedOld}`}
          >
            {oldDetails ? currentSupplyId : currentTransactionData?.supplyId}
          </h5>
        </GridItem>
        <GridItem
          xs={6}
          sm={6}
          md={1}
          style={{ float: 'left', textAlign: 'left' }}
        >
          <InputLabel
            htmlFor="simple-select"
            className={classes.selectLabel}
            style={{ float: 'left !important' }}
          >
            Demand ID
          </InputLabel>
          <h5
            className={`${classes.h5NoMargin} ${demandIdEditted &&
              classes.isEdited} ${demandIdEditted &&
              oldDetails &&
              classes.isEditedOld}`}
          >
            {oldDetails ? currentDemandId : currentTransactionData?.demandId}
          </h5>
        </GridItem>
      </React.Fragment>
    );
  }

  // WIll Hide the Detailed Transactions And returns to Transactions Main Page
  hideDetailedForm() {
    const { activeRole } = this.props;
    // eslint-disable-next-line react/destructuring-assignment
    const { branch } = this.state.currentTransactionData;
    history.push({
      pathname: `/${activeRole}/transactions/view`,
      state: branch,
    });
  }

  // WIll Hide the Detailed Transactions And returns to Transactions Main Page
  hideOpsDetailedForm() {
    const { activeRole } = this.props;
    // eslint-disable-next-line react/destructuring-assignment
    history.push(`/${activeRole}/approve/transaction`);
  }

  handleBack() {}

  showDialog() {
    const { classes } = this.props;
    const { classicModal, name } = this.state;
    return (
      <Dialog
        classes={{
          root: `${classes.center}`,
          // paper: classes.modal,
        }}
        maxWidth="md"
        open={classicModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => this.handleClose('classicModal')}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => this.handleClose('classicModal')}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>{name} Bank Details</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {this.getBanks()}
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button
            onClick={() => this.handleClose('classicModal')}
            color="danger"
            simple
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  showConfirmBankDialog() {
    const { classes } = this.props;
    const { isConfirmDialog, currentTransactionData } = this.state;
    return (
      <Dialog
        classes={{
          root: `${classes.center} ${classes.modalRoot}`,
          paper: classes.modal,
        }}
        open={isConfirmDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => this.handleClose('isConfirmDialog')}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <Button
            justIcon
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="transparent"
            onClick={() => this.handleClose('isConfirmDialog')}
          >
            <Close className={classes.modalClose} />
          </Button>
          <h4 className={classes.modalTitle}>Please Check the Bank Details</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {this.getBanks()}
        </DialogContent>
        {this.state.isButtonLoading ? (
          <CircularProgress
            className={classes.progress}
            style={{ color: purple[500] }}
            thickness={3}
          />
        ) : (
          <DialogActions className={classes.modalFooter}>
            <Button onClick={() => this.handleClose('isConfirmDialog')}>
              Cancel
            </Button>
            <Button
              onClick={() => this.approveTransaction(currentTransactionData)}
              color="success"
            >
              Yes, Approve
            </Button>
          </DialogActions>
        )}
      </Dialog>
    );
  }

  render() {
    const { classes } = this.props;
    const {
      submitAlert,
      checksAlert,
      checkOne,
      checkTwo,
      checksError,
      checksLoading,
      color,
      agentName,
      tc,
      recentTransactionsLoading,
      recentTransactions,
      isLoading,
      showDetailed,
      featureFlagFiles,
      awaitAlert,
    } = this.state;
    return (
      <div>
        {featureFlagFiles === 1 && <FeatureFlagToastAlert />}
        {submitAlert}
        {awaitAlert}
        {isLoading ? (
          <GridContainer justify="center">
            <CircularProgress
              className={classes.progress}
              style={{ color: purple[500] }}
              thickness={3}
            />
          </GridContainer>
        ) : (
          showDetailed && (
            <GridContainer justify="center">
              {checksAlert && (
                <Dialog
                  className={classes.modal}
                  open={this.state.checksAlert}
                  keepMounted
                  onClose={() => this.closeChecksAlert()}
                >
                  <DialogTitle className={classes.modalHeader}>
                    <GridContainer>
                      <GridItem xs={2} md={3} lg={3} />
                      <GridItem xs={8} md={8} lg={8}>
                        <span className={classes.rajdhaniFont}>
                          Finance Approval
                        </span>
                      </GridItem>
                      <GridItem xs={2} md={1} lg={1} style={{ color: 'red' }}>
                        <Button
                          justIcon
                          className={classes.modalCloseButton}
                          key="close"
                          aria-label="Close"
                          color="transparent"
                          onClick={() => this.closeChecksAlert()}
                        >
                          <Close className={classes.modalClose} />
                        </Button>
                      </GridItem>
                    </GridContainer>
                  </DialogTitle>
                  <DialogContent className={classes.modalBody}>
                    <GridContainer>
                      <GridItem xs={12}>
                        <div className={classes.checkboxAndRadio}>
                          <FormControlLabel
                            style={{ float: 'left' }}
                            control={
                              <Checkbox
                                checked={checkOne}
                                className={classes.checkBoxHeight}
                                onChange={() => this.handleCheckOne()}
                                value="checkOne"
                              />
                            }
                            label="Has this Transporter been doing regular business with us?"
                          />
                        </div>
                      </GridItem>
                      <GridItem xs={12}>
                        <div className={classes.checkboxAndRadio}>
                          <FormControlLabel
                            style={{ float: 'left' }}
                            control={
                              <Checkbox
                                checked={checkTwo}
                                className={classes.checkBoxHeight}
                                onChange={() => this.handleCheckTwo()}
                                value="checkTwo"
                              />
                            }
                            label="There are no outstanding issues currently."
                          />
                        </div>
                      </GridItem>
                      <GridItem xs={12}>
                        {recentTransactionsLoading ? (
                          <CircularProgress
                            className={classes.progress}
                            style={{ color: purple[500] }}
                            thickness={3}
                          />
                        ) : (
                          <React.Fragment>
                            <h4 className={classes.rajdhaniFont}>
                              Recent Transactions
                            </h4>
                            {recentTransactions.length > 0 ? (
                              <Table
                                tableHeaderColor="primary"
                                tableHead={[
                                  'Date',
                                  'Truck',
                                  'Freight',
                                  'To',
                                  'From',
                                ]}
                                tableData={recentTransactions.map(
                                  transaction => [
                                    moment(
                                      transaction.createdAt.toDate()
                                    ).format('MMM Do Y'),
                                    transaction.vehicleNumber,
                                    transaction.amount,
                                    transaction.toCity,
                                    transaction.fromCity,
                                  ]
                                )}
                                coloredColls={[3]}
                                colorsColls={['primary']}
                              />
                            ) : (
                              <h5 className={classes.rajdhaniFont}>
                                No Previous Transactions with this Truck
                              </h5>
                            )}
                          </React.Fragment>
                        )}
                      </GridItem>
                      <GridItem xs={12}>
                        <span
                          style={{ color: '#f44336' }}
                          className={classes.rajdhaniFont}
                        >
                          {checksError}
                        </span>
                        {checksLoading ? (
                          <CircularProgress
                            className={classes.progress}
                            style={{ color: purple[500] }}
                            thickness={7}
                          />
                        ) : null}
                      </GridItem>
                    </GridContainer>
                  </DialogContent>
                  <DialogActions
                    className={`${classes.modalFooter} ${classes.modalFooterCenter}`}
                  >
                    {this.finishChecks}
                    <Button
                      onClick={() => this.closeChecksAlert()}
                      color="danger"
                    >
                      Cancel
                    </Button>
                    <Button onClick={() => this.finishChecks()} color="success">
                      Submit
                    </Button>
                  </DialogActions>
                </Dialog>
              )}
              {this.showDetailedTransactions()}
              {this.showDialog()}
              {this.showConfirmBankDialog()}
            </GridContainer>
          )
        )}

        <Snackbar
          place="tc"
          color={color}
          icon={AddAlert}
          message={agentName}
          open={tc}
          closeNotification={() => this.setState({ tc: false })}
          close
        />
      </div>
    );
  }
}

DetailedTransactions.propTypes = {
  classes: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => ({
  currentTransactionData: state.transactions.currentTransactionData,
  unsubscribeTransaction: state.transactions.unsubscribeTransaction,
  userDetails: state.main.userDetails,
  systemDetails: state.main.systemDetails,
  activeRole: state.main.activeRole,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      switchActivePage,
      setCurrentTransaction,
      setCurrentTransactionUnsubscribe,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(TransactionStyle)(DetailedTransactions));
