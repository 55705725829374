/* eslint-disable prefer-const */
/* eslint-disable guard-for-in */
/* eslint-disable eqeqeq */
/* eslint-disable react/no-find-dom-node */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-prototype-builtins */
/* eslint-disable react/no-unused-state */
/* eslint-disable class-methods-use-this */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-useless-escape */
/* eslint-disable no-console */
/* eslint class-methods-use-this: ["error", { "exceptMethods": ["verifyRequired","verifyGst","verifyEmail","verifyNumber","verifyPan","verifyPhone","verifyLength"] }] */

import React from 'react';
import { connect } from 'react-redux';
import Tooltip from '@material-ui/core/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import greenTick from 'assets/img/greenCheck.svg';
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
/* eslint no-unused-expressions: "error" */
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
// @material-ui/icons
import Add from '@material-ui/icons/Add';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Edit from '@material-ui/icons/Edit';
import Done from '@material-ui/icons/Done';
import swal from 'sweetalert';
// core components
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Clearfix from 'components/Clearfix/Clearfix.jsx';
import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import Close from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Table from 'components/Table/Table.jsx';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Danger from 'components/Typography/Danger.jsx';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import CircularProgress from '@material-ui/core/CircularProgress';
import purple from '@material-ui/core/colors/purple';

import SweetAlert from 'react-bootstrap-sweetalert';
import axios from 'axios';
// Fireabse
import AddTruckersStyle from 'assets/jss/material-dashboard-pro-react/views/AddTruckersStyle.jsx';
import TextField from '@material-ui/core/TextField';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Typography } from '@material-ui/core';
import Popup from 'reactjs-popup';
import moment from 'moment';
import infoIcon from 'assets/img/infoIcon.svg';
import firebase from '../../config/config';
import PanFileUpload from './FileUploads/PanFileUpload';
import AdhaarFileUpload from './FileUploads/AdhaarFileUpload';
import TruckFilesUpload from './TruckFiles/TruckFilesUpload';
import TdsPercentage from './TdsPercentageSection/TdsPercentage';
import TruckerOverallRatingCard from './Ratings/TruckerOverallRatingCard';
import SecondaryContactsView from './SecondaryContacts/SecondaryContactsView';
import CommentSection from './Comments/CommentSection';
import TruckerNewFieldsView from './NewFieldsSection/TruckerNewFieldsView';
import TruckerTDS from './FileUploads/TruckerTDS';
import TruckerTdsNew from './FileUploads/TruckerTdsNew';
import auditLogger from '../../utils/auditLoggerToPG';
import apiCaller from '../../utils/apiCallerOutstanding';
import TruckDetailCard from './TruckFiles/TruckDetailCard';
import AdhaarBackFileUploader from './FileUploads/AdhaarBackFileUpload';
import FeatureFlagToastAlert from '../Components/FeatureFlagToastAlert';
import history from '../../history';

const contentStyle = { width: '300px' };

const deleteModalButton = {
  width: '45%',
  background: '#52BE56',
  borderRadius: 0,
  color: 'white',
  margin: '5px',
};

const cancelButton = {
  width: '45%',
  background: 'lightgray',
  borderRadius: 0,
  margin: '5px',
};

const panButton = {
  marginTop: '20px',
  marginLeft: '10px',
  height: '35px',
  width: '150px',
  borderRadius: '4px',
  border: '1px solid #8F95B2',
  background: 'transparent',
  color: '#474D66',
  fontWeight: '500',
};

const { REACT_APP_OFFICE_REPORTS_API, REACT_APP_API_BASE_URL } = process.env;
const db = firebase.firestore();
const auditLog = firebase.functions().httpsCallable('utilities-auditLog');
const validateAndUpdateBanks = firebase
  .functions()
  .httpsCallable('truckers-validateAndUpdateBanks');
const retryBankAccount = firebase
  .functions()
  .httpsCallable('truckers-retryBankAccount');

const validateBank = firebase
  .functions()
  .httpsCallable('truckers-validateBankNew');

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="down" {...props} ref={ref} />
));
const updateSecondaryContact = async (id, contacts) => {
  const podRef = await db.collection('Truckers').doc(id);
  // .collection('POD')
  // .doc(transporterId);
  for (const i in contacts) {
    try {
      podRef
        .collection('SecondaryContacts')
        .doc()
        .set(
          {
            name: contacts[i].name,
            number: contacts[i].number,
          },
          { merge: true }
        );
    } catch (error) {
      console.log('Error', error);
    }
  }
};

class AdminEditTrucker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      nameState: '',

      email: '',
      emailState: '',

      contactPerson: '',

      phoneNumber: '',
      phoneNumberState: '',

      gstNumber: '',
      gstNumberState: '',

      panNumber: '',
      panNumberState: '',

      isLoading: false,

      alert: null,

      accountNumber: '',
      accountNumberState: '',

      ifsc: '',
      ifscState: '',

      truckTypeState: '',

      bankHolderName: '',
      bankHolderNameState: '',

      branchName: '',
      branchNameState: '',

      showDetails: false,
      componentLoading: false,

      selectedTruckerData: null,
      bankName: '',
      bankNameState: '',
      truckType: '',
      truckTypes: [],
      trucks: [],
      truckersData: [],
      trucksState: 'Please Add Truck Details',
      bankModal: false,
      truckNumber: '',
      banks: [],
      banksData: [],
      activeError: '',
      banksState: 'Please Add Bank Details',
      editBank: false,
      isBankEdit: false,
      initialBanksLength: 0,
      initialBanks: [],

      approveAlert: false,
      fundAccountLoading: false,
      contactLoading: false,
      fundAccountSuccess: false,
      contactSuccess: false,
      razorpayError: '',
      isLoadingBank: false,
      secondaryContact: [],

      classicModal: false,
      edit: false,
      validating: false,
      panValidation: false,
      validationMessage: '',
      duplicateTrucker: false,
      validatingPhone: false,
      validationFailed: false,
      blacklisting: false,
      presentOrgs: [],
      verifiedNameAtBank: '',
      isVerifyingbank: false,
      checkedVerifiednameAtBank: false,
      bankAccountDetailsVerified: false,
      featureFlagFiles: '',

      panModal: false,
      panDetailData: {},
      showLoader: false,
      tdsPercentage: '',
    };

    this.change = this.change.bind(this);
    this.submitTrucker = this.submitTrucker.bind(this);
    this.getAllTruckTypes = this.getAllTruckTypes.bind(this);
    this.getTruckerData = this.getTruckerData.bind(this);
    this.validateAllFields = this.validateAllFields.bind(this);
    this.validateBankFields = this.validateBankFields.bind(this);
    this.clearForm = this.clearForm.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.verifyRequired = this.verifyRequired.bind(this);
    this.openEditBankForm = this.openEditBankForm.bind(this);
    this.editBank = this.editBank.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.validPanDetails = this.validPanDetails.bind(this);
  }

  componentDidMount() {
    this.setState({
      componentLoading: true,
    });
    // eslint-disable-next-line react/no-find-dom-node
    const truckerId = history.location.state;

    this.getAllTruckTypes();
    const handleGetTruckerDetails = async id => {
      try {
        const docRef = await db.collection('Truckers').doc(id);
        const doc = await docRef.get();
        if (doc.exists) {
          const data = doc.data();
          this.setState(
            {
              selectedTruckerData: data,
              componentLoading: false,
              showDetails: true,
            },
            () => {
              this.getTruckerData();
              this.auditLog(data);
            }
          );
        }
      } catch (error) {
        console.error('Error fetching trucker data: ', error);
      }
    };
    handleGetTruckerDetails(truckerId);
    ReactDOM.findDOMNode(this).scrollIntoView();
  }

  getTruckerData() {
    const truckersData = [];
    const {
      name,
      contactPerson,
      phoneNumber,
      email,
      gstNumber,
      panNumber,
      trucks,
    } = this.state.selectedTruckerData;
    if (trucks) {
      // eslint-disable-next-line guard-for-in
      for (const i in trucks) {
        truckersData.push({
          color: 'success',
          data: [trucks[i].truckNumber, trucks[i].truckType],
        });
      }
    }
    const { classes, activeRole } = this.props;
    // banks will contain all the previous bank releted data
    let banks = [];
    let initialBanks = [];
    // banksData is only to show the Data in the table format
    let banksData = [];
    db.collection('Truckers')
      .doc(this.state.selectedTruckerData.truckerId)
      .collection('Banks')
      .onSnapshot(snapshot => {
        banks = [];
        banksData = [];
        initialBanks = [];
        snapshot.forEach(bank => {
          banks.push(bank.data());
          initialBanks.push(bank.data());
        });
        banks.forEach((bank, i) => {
          const { cashfree_registered, razorpay_registered } = bank;
          const { account_verified } = bank;
          let showAccountnotVerified = false;
          if (account_verified === false) {
            showAccountnotVerified = true;
          }
          banksData.push({
            color: 'success',
            data: [
              bank.bankHolderName,
              bank.accountNumber,
              bank.bankName,
              bank.ifsc,
              bank.bankBranchName,
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.block}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={bank.bankAccountActive}
                        onChange={this.handleChange(bank, i)}
                        value={bank.bankAccountActive}
                        classes={{
                          switchBase: classes.switchBase,
                          checked: classes.switchChecked,
                          icon: classes.switchIcon,
                          iconChecked: classes.switchIconChecked,
                          bar: classes.switchBar,
                        }}
                        disabled={showAccountnotVerified}
                      />
                    }
                  />
                </div>
              </GridItem>,
              cashfree_registered && razorpay_registered ? (
                <Done style={{ color: '#43a047 ' }} />
              ) : (
                <React.Fragment>
                  {(activeRole === 'ops' || activeRole === 'admin') &&
                  !showAccountnotVerified ? (
                    <Button
                      size="sm"
                      color="warning"
                      className={classes.actionButton}
                      key={i}
                      onClick={() => {
                        this.retryContactAndFundAccount(bank);
                      }}
                    >
                      Retry
                    </Button>
                  ) : showAccountnotVerified ? (
                    <p style={{ color: 'red' }}>Invalid Account</p>
                  ) : (
                    <Close style={{ color: '#FF0000 ' }} />
                  )}
                </React.Fragment>
              ),
            ],
          });
        });

        this.setState({
          banksData,
          banks,
          initialBanksLength: banks.length,
          initialBanks,
        });
      });
    this.getFeatureFlagStatus();

    this.setState({
      truckersData,
      name,
      contactPerson,
      phoneNumber,
      email,
      gstNumber,
      panNumber,
      trucks,
    });
  }

  /**
   * will get all truck types from DB
   * @function
   */
  getAllTruckTypes() {
    const truckTypesRef = db.collection(`Global`).doc(`TruckTypes`);
    truckTypesRef
      .get()
      .then(
        resultData => {
          const { truckTypes } = resultData.data();
          this.setState({ truckTypes });
        },
        err => {
          console.log('err', err);
        }
      )
      .catch(err => {
        console.log('err', err);
      });
  }

  getFeatureFlagStatus() {
    db.collection('FeatureFlags')
      .doc('FileUploadsFeature')
      .onSnapshot(doc => {
        if (doc.exists) {
          const data = doc.data();
          this.setState({ featureFlagFiles: data?.truckerFiles });
        }
      });
  }

  auditLog = async data => {
    const userDetails = firebase.auth().currentUser;
    const { activeRole } = this.props;
    const auditBody = {
      // data: { ...transaction },
      type: 'view',
      entity: 'Trucker',
      collection: 'Truckers',
      transporterViewedBy: userDetails.uid,
      email: userDetails.email,
      role: activeRole,
      message: `${userDetails.displayName} with email ${userDetails.email} and role ${activeRole} tried to view ${data.name} Trucker with ${data.truckerId} as Trucker ID`,
      viewedAt: moment().format('llll'),
    };
    await auditLog(auditBody);
    const systemDetails = JSON.parse(localStorage.getItem('systemDetails'));
    const auditPayload = {
      collection: 'trucker',
      orgId: null,
      data: {},
      message: `${userDetails?.displayName} with email id ${userDetails?.email} viewed a trucker ${data?.name} `,
      systemDetails,
      type: `View Trucker`,
      role: `${this.capitalizeFirstLetter(activeRole?.split('is')[0])}`,
      eventDateTime: new Date().toISOString(),
      source: 'office',
    };
    auditLogger(auditPayload);
  };

  // eslint-disable-next-line react/sort-comp
  openEditBankForm(bank) {
    const {
      bankHolderName,
      accountNumber,
      bankName,
      ifsc,
      bankBranchName,
      bankId,
    } = bank;

    this.setState({
      editBank: true,
      bankHolderName,
      accountNumber,
      bankName,
      ifsc,
      branchName: bankBranchName,
      bankId,
    });
    this.handleClickOpen('bankModal');
  }

  editBank(currentBankAccountNumber) {
    const { classes } = this.props;
    const {
      bankHolderName,
      accountNumber,
      bankName,
      ifsc,
      branchName,
      bankId,
      banks,
      banksData,
    } = this.state;

    this.validateBankFields().then(
      success => {
        let index;
        banks.forEach((bank, i) => {
          if (bank.bankId === bankId) {
            index = i;
            if (
              banks[i].accountNumber !== accountNumber ||
              banks[i].bankName !== bankName ||
              banks[i].ifsc !== ifsc ||
              banks[i].bankBranchName !== branchName ||
              banks[i].bankHolderName !== bankHolderName
            ) {
              // If Bank Edit is Success (Compared with the Previous values)
              this.setState({
                isBankEdit: true,
              });
              index = i;
              banks[i].accountNumber = accountNumber;
              banks[i].bankName = bankName;
              banks[i].ifsc = ifsc;
              banks[i].bankBranchName = branchName;
              banks[i].bankHolderName = bankHolderName;
            } else {
              // Edit not happened because data not changed or edited
            }
          }
        });
        banksData[index].data = [
          bankHolderName,
          accountNumber,
          bankName,
          ifsc,
          branchName,
          <GridItem xs={12} sm={12} md={6}>
            <div className={classes.block}>
              <FormControlLabel
                control={
                  <Switch
                    checked={banks[index].bankAccountActive}
                    onChange={this.handleChange(banks[index], index)}
                    value={banks[index].bankAccountActive}
                    classes={{
                      switchBase: classes.switchBase,
                      checked: classes.switchChecked,
                      icon: classes.switchIcon,
                      iconChecked: classes.switchIconChecked,
                      bar: classes.switchBar,
                    }}
                  />
                }
              />
            </div>
          </GridItem>,
          <Button
            color="warning"
            className={classes.actionButton}
            key={index}
            onClick={() => this.openEditBankForm(banks[index])}
            justIcon
          >
            <Edit className={classes.icon} />
          </Button>,
        ];

        this.handleClose('bankModal');
        this.setState({
          editBank: false,
          banksData,
          banks,
          accountNumber: '',
          ifsc: '',
          bankName: '',
          branchName: '',
          bankHolderName: '',
          accountNumberState: '',
          ifscState: '',
          bankNameState: '',
          branchNameState: '',
          bankHolderNameState: '',
        });
      },
      err => {
        console.log(err);
      }
    );
  }

  handleClickOpen(modal) {
    const x = [];
    x[modal] = true;
    this.setState(x);
    this.setState({ verifiedNameAtBank: '' });
  }

  handleClickOpenModal(modal) {
    const x = [];
    x[modal] = true;
    this.setState(x);
  }

  handleCloseModal(modal) {
    const x = [];
    x[modal] = false;
    this.setState(x);
  }

  //  Handle BankAccountActive Toggle
  handleChange = (currentBank, i) => event => {
    const { classes, activeRole } = this.props;
    const { banks, banksData } = this.state;
    const { account_verified } = currentBank;
    let showAccountnotVerified = false;
    if (account_verified === false) {
      showAccountnotVerified = true;
    }
    // If Bank Edit is Success (Compared with the Previous values)
    this.setState({
      isBankEdit: true,
    });
    banks[i].bankAccountActive = event.target.checked;
    banksData[i].data = [
      currentBank.bankHolderName,
      currentBank.accountNumber,
      currentBank.bankName,
      currentBank.ifsc,
      currentBank.bankBranchName,
      <GridItem xs={12} sm={12} md={6}>
        <div className={classes.block}>
          <FormControlLabel
            control={
              <Switch
                checked={banks[i].bankAccountActive}
                onChange={this.handleChange(banks[i], i)}
                value={banks[i].bankAccountActive}
                classes={{
                  switchBase: classes.switchBase,
                  checked: classes.switchChecked,
                  icon: classes.switchIcon,
                  iconChecked: classes.switchIconChecked,
                  bar: classes.switchBar,
                }}
                disabled={showAccountnotVerified}
              />
            }
          />
        </div>
      </GridItem>,
      currentBank.bankId ? (
        currentBank.cashfree_registered && currentBank.razorpay_registered ? (
          <Done style={{ color: '#43a047 ' }} />
        ) : (
          <React.Fragment>
            {(activeRole === 'ops' || activeRole === 'admin') &&
            !showAccountnotVerified ? (
              <Button
                size="sm"
                color="warning"
                className={classes.actionButton}
                key={i}
                onClick={() => {
                  this.retryContactAndFundAccount(currentBank);
                }}
              >
                Retry
              </Button>
            ) : showAccountnotVerified ? (
              <p style={{ color: 'red' }}>Invalid Account</p>
            ) : (
              <Close style={{ color: '#FF0000 ' }} />
            )}
          </React.Fragment>
        )
      ) : null,
    ];

    this.setState({
      banksData,
      banks,
    });
  };

  /**
   * closes the modal button
   * @param {*} modal
   */
  handleClose(modal) {
    const x = [];
    x[modal] = false;
    this.setState(x);
    this.setState({ editBank: false });
    if (modal === 'bankModal') {
      this.setState({
        accountNumber: '',
        ifsc: '',
        bankName: '',
        branchName: '',
        bankHolderName: '',
        accountNumberState: '',
        ifscState: '',
        bankNameState: '',
        branchNameState: '',
        bankHolderNameState: '',
      });
    }
  }

  hideAlert() {
    this.setState({
      alert: null,
    });
    // eslint-disable-next-line react/destructuring-assignment
    const { activeRole } = this.props;
    if (activeRole == 'ops') {
      history.push(`/${activeRole}/truckers`);
    } else if (
      activeRole == 'sales' ||
      activeRole == 'traffic' ||
      activeRole == 'branch-ops'
    ) {
      history.push(`/${activeRole}/truckers/view`);
    }
  }

  // function that returns true if value is there, false otherwise
  verifyRequired(value) {
    if (value) {
      return true;
    }
    return false;
  }

  // function that returns true if value is Valid GST, false otherwise
  verifyGst(value) {
    const gstRex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    if (gstRex.test(value)) {
      return true;
    }
    return false;
  }

  // eslint-disable-next-line class-methods-use-this
  verifyIfsc(value) {
    const regIfsc = /^[A-Za-z]{4}[a-zA-Z0-9]{7}$/;
    if (regIfsc.test(value)) {
      return true;
    }
    return false;
  }

  // function that returns true if value is email, false otherwise
  verifyEmail(value) {
    const emailRex = /[^\s@]+@[^\s@]+\.[^\s@]+/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if value contains only numbers
  verifyNumber(value) {
    const numberRex = new RegExp('^[0-9]+$');
    if (numberRex.test(value)) {
      return true;
    }
    return false;
  }

  async validatePanNumber(pan) {
    // this.setState({ panValidation: true });

    const endPoint = 'pipe/pan-validate';
    const userDetails = firebase.auth().currentUser;
    const { Aa } = userDetails;
    const token = Aa;
    const body = {
      type: 'trucker',
      pan,
    };
    const response = await axios.post(
      `${REACT_APP_API_BASE_URL}${endPoint}`,
      body,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    const duplicate = response?.data?.Info.pan_match;

    if (duplicate) {
      this.setState({
        panValidation: true,
        panNumberState: 'error',
        validationMessage: 'PAN Number already exists',
      });
    } else {
      this.setState({ panNumberState: 'success' });
    }
  }

  // function that verifies if value contains PanCard
  verifyPan(value) {
    const regpan = /(^([a-zA-Z]{5})([0-9]{4})([a-zA-Z]{1})$)/;
    if (regpan.test(value)) {
      this.validatePanNumber(value);
    }
    return false;
  }

  // function that verifies if value contains PanCard
  verifyPhone(value) {
    const regPhone = /^[6789]\d{9}$/;
    if (regPhone.test(value)) {
      return true;
    }
    return false;
  }

  // function that verifies if a string has a given length or not
  verifyLength(value, length) {
    if (value === undefined) {
      return false;
    }
    if (value.length >= length) {
      return true;
    }
    return false;
  }

  handleContacts(value) {
    this.setState({
      secondaryContact: value,
      // secondaryContactPhoneNumber: value.number,
    });
  }

  // function that returns true if value is email, false otherwise
  verifyAlpha(value) {
    const emailRex = /^[a-zA-Z() ]+$/;
    if (emailRex.test(value)) {
      return true;
    }
    return false;
  }

  change(event, stateName, type, stateNameEqualTo) {
    switch (type) {
      case 'required':
        if (this.verifyRequired(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'email':
        if (this.verifyEmail(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'number':
        if (this.verifyNumber(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'alpha':
        if (this.verifyAlpha(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'length':
        if (this.verifyLength(event.target.value, stateNameEqualTo)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'pan':
        if (this.verifyPan(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
            validationMessage: 'Please check PAN Number',
          });
        }
        break;
      case 'phone':
        if (this.verifyPhone(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'gst':
        if (this.verifyGst(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      case 'ifsc':
        if (this.verifyIfsc(event.target.value)) {
          this.setState({
            [`${stateName}State`]: 'success',
            [stateName]: event.target.value,
          });
        } else {
          this.setState({
            [`${stateName}State`]: 'error',
            [stateName]: event.target.value,
          });
        }
        break;
      default:
        break;
    }
  }

  // validates all required fields and returns a promise
  validateAllFields() {
    const { name, banks, trucks } = this.state;
    let error = false;
    return new Promise((resolve, reject) => {
      // Verify Name
      if (this.verifyLength(name, 1)) {
        this.setState({
          nameState: 'success',
        });
      } else {
        this.setState({
          nameState: 'error',
        });
        error = true;
      }

      if (trucks.length > 0) {
        this.setState({ trucksState: '' });
      } else {
        this.setState({ trucksState: 'Please Add Truck Details' });

        error = true;
      }

      if (banks.length > 0) {
        this.setState({ banksState: '' });
      } else {
        this.setState({ banksState: 'Please Add Bank Details' });

        error = true;
      }

      let numActiveBanks = 0;
      banks.forEach(bank => {
        if (bank.bankAccountActive) {
          numActiveBanks += 1;
        }
      });
      if (numActiveBanks !== 1) {
        this.setState({
          activeError: 'Exactly 1 bank must be active.',
        });
        error = true;
      } else {
        this.setState({
          activeError: '',
        });
      }

      if (!error) {
        resolve(true);
      } else {
        reject(error);
      }
    });
  }

  capitalizeFirstLetter(str) {
    // converting first letter to uppercase
    const capitalized = str.charAt(0).toUpperCase() + str.slice(1);

    return capitalized;
  }

  // When Bank Details edited Go through the Approval Process
  submitPendingEditSales() {
    const { activeRole } = this.props;
    const { userDetails, systemDetails } = this.props;
    const { selectedTruckerData } = this.state;
    const {
      banks,
      name,
      email,
      contactPerson,
      phoneNumber,
      gstNumber,
      panNumber,
      trucks,
    } = this.state;
    const user = firebase.auth().currentUser;
    const truckerEditData = {
      ...selectedTruckerData,
      name,
      email,
      contactPerson,
      phoneNumber,
      gstNumber,
      banks,
      panNumber,
      trucks,
      updatedBy: user.uid,
      modifiedAt: new Date(),
      editedBy: userDetails.uid,
      editedByName: userDetails.name,
    };
    const truckerRef = db.collection(`Truckers`);
    truckerRef
      .doc(this.state.selectedTruckerData.truckerId)
      .update({ truckerEditData, editPending: true })
      .then(userDoc => {
        const ID = this.state.selectedTruckerData.truckerId;
        updateSecondaryContact(ID, this.state.secondaryContact);
        const auditBody = {
          data: {
            name,
            email,
            contactPerson,
            phoneNumber,
            gstNumber,
            panNumber,
            trucks,
            banks,
          },
          collection: 'Truckers',
          updatedBy: userDetails.uid,
          systemDetails,
          type: 'Edit Trucker',
          message: `${userDetails.email} edited Trucker with name ${name}`,
        };
        auditLog(auditBody);
        const auditPayload = {
          collection: 'trucker',
          orgId: selectedTruckerData.truckerId,
          data: truckerEditData,
          message: `${userDetails?.name} with email id ${userDetails?.email} editted a trucker`,
          systemDetails,
          type: `Edit Trucker`,
          role: `${this.capitalizeFirstLetter(
            activeRole === 'branch-ops'
              ? 'branchOps'
              : activeRole.split('is')[0]
          )}`,
          eventDateTime: new Date().toISOString(),
          source: 'office',
        };
        auditLogger(auditPayload);
        this.clearForm();
        this.setState({ isLoading: false });
        // Updated Succeessfully
      })
      .catch(err => {
        const errorBody = {
          message: `${userDetails.email} tried to edit Trucker with name ${name} but FAILED`,
          status: 'fail',
          error: err,
          updatedBy: userDetails.uid,
          errorMessage: err.message,
          collection: 'Truckers',
          systemDetails,
        };
        auditLog(errorBody);
        const auditPayload = {
          collection: 'trucker',
          orgId: null,
          data: truckerEditData,
          message: `${userDetails?.name} with email id ${userDetails?.email} editted a trucker but failed ${err}`,
          systemDetails,
          type: `Edit Trucker`,
          role: `${this.capitalizeFirstLetter(
            activeRole === 'branch-ops'
              ? 'branchOps'
              : activeRole.split('is')[0]
          )}`,
          eventDateTime: new Date().toISOString(),
          source: 'office',
        };
        auditLogger(auditPayload);
      });
  }

  // Direct Approval for Other than Bank Edits Trucker Data
  submitPendingEditOps() {
    const { userDetails, systemDetails, activeRole } = this.props;
    const { selectedTruckerData } = this.state;
    const {
      banks,
      name,
      email,
      contactPerson,
      phoneNumber,
      gstNumber,
      panNumber,
      trucks,
    } = this.state;
    const user = firebase.auth().currentUser;
    const truckerEditData = {
      ...selectedTruckerData,
      banks,
      updatedBy: user.uid,
      modifiedAt: new Date(),
    };
    const truckerRef = db.collection(`Truckers`);
    truckerRef
      .doc(selectedTruckerData.truckerId)
      .collection('EditLog')
      .add(truckerEditData)
      .then(userDoc => {
        const truckerRef1 = db.collection(`Truckers`);
        truckerRef1
          .doc(selectedTruckerData.truckerId)
          .update({
            name,
            email,
            contactPerson,
            phoneNumber,
            gstNumber,
            panNumber,
            trucks,
            updatedBy: user.uid,
            editedBy: userDetails.uid,
            editedByName: userDetails.name,
            modifiedAt: new Date(),
          })
          .then(userRes => {
            const ID = selectedTruckerData.truckerId;
            updateSecondaryContact(ID, this.state.secondaryContact);
            validateAndUpdateBanks({
              truckerName: name,
              banks,
              truckerId: selectedTruckerData.truckerId,
              userDetails,
            })
              .then(() => {
                this.clearForm();
                this.setState({ isLoading: false });
              })
              .catch(err => {
                console.log('Error', err);
                this.setState({ isLoading: false });
              });

            const auditBody = {
              data: {
                name,
                email,
                contactPerson,
                phoneNumber,
                gstNumber,
                panNumber,
                trucks,
                banks,
              },
              collection: 'Truckers',
              updatedBy: userDetails.uid,
              systemDetails,
              type: 'Edit Trucker',
              message: `${userDetails.email} edited Trucker with name ${name}`,
            };

            auditLog(auditBody);
            const auditPayload = {
              collection: 'trucker',
              orgId: selectedTruckerData.truckerId,
              data: truckerEditData,
              message: `${userDetails?.name} with email id ${userDetails?.email} editted a trucker`,
              systemDetails,
              type: `Edit Trucker`,
              role: `${this.capitalizeFirstLetter(
                activeRole === 'branch-ops'
                  ? 'branchOps'
                  : activeRole.split('is')[0]
              )}`,
              eventDateTime: new Date().toISOString(),
              source: 'office',
            };
            auditLogger(auditPayload);

            // Updated Succeessfully
          })
          .catch(err => {
            const errorBody = {
              message: `${userDetails.email} tried to edit Trucker with name ${name} but FAILED`,
              status: 'fail',
              error: err,
              updatedBy: userDetails.uid,
              errorMessage: err.message,
              collection: 'Truckers',
              systemDetails,
            };
            auditLog(errorBody);
            const auditPayload = {
              collection: 'trucker',
              orgId: selectedTruckerData.truckerId,
              data: truckerEditData,
              message: `${userDetails?.name} with email id ${userDetails?.email} editted a trucker but failed ${err}`,
              systemDetails,
              type: `Edit Trucker`,
              role: `${this.capitalizeFirstLetter(
                activeRole === 'branch-ops'
                  ? 'branchOps'
                  : activeRole.split('is')[0]
              )}`,
              eventDateTime: new Date().toISOString(),
              source: 'office',
            };
            auditLogger(auditPayload);
            this.setState({ isLoading: false });
          });
        // Updated Succeessfully
      });
  }

  /**
   * Submits the Trucker details to the Database
   * @function
   */
  submitTrucker() {
    const { isBankEdit } = this.state;
    this.setState({
      isLoading: true,
    });
    this.validateAllFields()
      .then(
        success => {
          if (success) {
            // Check if he is sales or finance and if he edits bank data then he should go through Approval Process
            if (isBankEdit) {
              this.submitPendingEditSales();
            } else {
              this.submitPendingEditOps();
            }
          }
        },
        err => {
          console.log(err);
          this.setState({
            isLoading: false,
          });
        }
      )
      .catch(err => {
        console.log(err);
        this.setState({
          isLoading: false,
        });
      });
  }

  /**
   * Will Clears ALl the State values after form submision
   * @function
   */
  clearForm() {
    const { classes } = this.props;
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: 'block', marginTop: '-100px' }}
          title="Good job!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={`${classes.button} ${classes.success}`}
        >
          Trucker Edited Succeessfully
        </SweetAlert>
      ),
    });
    this.setState({
      name: '',
      nameState: '',
      email: '',
      emailState: '',
      contactPerson: '',
      phoneNumber: '',
      phoneNumberState: '',
      gstNumber: '',
      gstNumberState: '',
      panNumber: '',
      panNumberState: '',
      bankHolderName: '',
      banks: '',
      banksData: [],
      truckersData: [],
      isBankEdit: false,
      initialBanksLength: 0,
    });
  }

  handleSimple = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  // Will Get select Trucks form
  // eslint-disable-next-line react/sort-comp
  getTruckTypes() {
    const { classes } = this.props;
    const { truckType, truckTypes, truckTypeState } = this.state;
    return (
      <div>
        <FormControl fullWidth className={classes.selectFormControl}>
          <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
            Select Truck Type
          </InputLabel>
          <Select
            MenuProps={{ className: classes.selectMenu }}
            classes={{ select: classes.select }}
            value={truckType}
            onChange={this.handleSimple}
            inputProps={{ name: 'truckType', id: 'simple-select' }}
          >
            <MenuItem disabled classes={{ root: classes.selectMenuItem }}>
              Select Truck Type
            </MenuItem>
            {truckTypes.map((truckTypeRes, i) => (
              <MenuItem
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected,
                }}
                value={truckTypeRes}
              >
                {truckTypeRes}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {truckTypeState === 'error' && (
          <Danger>Please select truck type</Danger>
        )}
      </div>
    );
  }

  updateTrucksInFirestore(trucks) {
    const { name, selectedTruckerData } = this.state;
    const { userDetails, systemDetails, activeRole } = this.props;
    const user = firebase.auth().currentUser;
    const truckerEditData = {
      ...selectedTruckerData,
      trucks,
      updatedBy: user.uid,
      modifiedAt: new Date(),
    };
    const truckerRef = db.collection(`Truckers`);
    truckerRef
      .doc(selectedTruckerData.truckerId)
      .collection('EditLog')
      .add(truckerEditData)
      .then(userDoc => {
        const truckerRef1 = db.collection(`Truckers`);
        truckerRef1
          .doc(selectedTruckerData.truckerId)
          .update({
            trucks,
            updatedBy: user.uid,
            editedBy: userDetails.uid,
            editedByName: userDetails.name,
            modifiedAt: new Date(),
          })
          .then(userRes => {
            const modal = 'classicModal';
            const x = [];
            x[modal] = false;
            this.setState(x);
            // this.clearForm();
            this.setState({ isLoading: false });
            swal(
              'Your Truck has been added!',
              'Please add the RC for the truck added',
              'success'
            );

            const auditBody = {
              data: {
                trucks,
              },
              collection: 'Truckers',
              updatedBy: userDetails.uid,
              systemDetails,
              type: 'Edit Trucker Trucks',
              message: `${userDetails.email} edited trucks for Trucker with name ${name}`,
            };

            auditLog(auditBody);
            const auditPayload = {
              collection: 'trucker',
              orgId: selectedTruckerData.truckerId,
              data: truckerEditData,
              message: `${userDetails?.name} with email id ${userDetails?.email} editted a trucker`,
              systemDetails,
              type: `Edit Trucker`,
              role: `${this.capitalizeFirstLetter(
                activeRole === 'branch-ops'
                  ? 'branchOps'
                  : activeRole.split('is')[0]
              )}`,
              eventDateTime: new Date().toISOString(),
              source: 'office',
            };
            auditLogger(auditPayload);

            // Updated Succeessfully
          })
          .catch(err => {
            const errorBody = {
              message: `${userDetails.email} tried to edit Trucker with name ${name} but FAILED`,
              status: 'fail',
              error: err,
              updatedBy: userDetails.uid,
              errorMessage: err.message,
              collection: 'Truckers',
              systemDetails,
            };
            auditLog(errorBody);
            const auditPayload = {
              collection: 'trucker',
              orgId: selectedTruckerData.truckerId,
              data: truckerEditData,
              message: `${userDetails?.name} with email id ${userDetails?.email} editted a trucker but failed ${err}`,
              systemDetails,
              type: `Edit Trucker`,
              role: `${this.capitalizeFirstLetter(
                activeRole === 'branch-ops'
                  ? 'branchOps'
                  : activeRole.split('is')[0]
              )}`,
              eventDateTime: new Date().toISOString(),
              source: 'office',
            };
            auditLogger(auditPayload);
            this.setState({ isLoading: false });
            swal(
              'Oops!',
              'something went wrong please try agin later!',
              'error'
            );
          });
        // Updated Succeessfully
      });
  }

  // Add Trucks
  addTrucks() {
    const { userDetails } = this.props;
    const { trucks, truckersData, truckType } = this.state;
    let { truckNumber } = this.state;
    if (truckNumber === '') {
      // do nothing
    } else {
      truckNumber = truckNumber.replace(/[\.@#$%^&*()+=!~`<>{}|,:]+/g, '');
      truckNumber = truckNumber.replace(/[ -,_,-]+/g, '');
    }
    if (this.verifyLength(truckType, 1)) {
      this.setState({ truckTypeState: '' });
    } else {
      this.setState({ truckTypeState: 'error' });
    }
    if (this.verifyLength(truckNumber, 1) && this.verifyLength(truckType, 1)) {
      this.setState({
        truckNumberState: 'success',
        truckTypeState: 'success',
      });
      trucks.push({
        truckNumber,
        truckType,
        updatedByUid: userDetails?.uid,
        updatedByName: userDetails?.name,
        updatedAt: moment().format('llll'),
      });
      truckersData.push({
        color: 'success',
        data: [truckNumber, truckType],
      });
      this.handleClose('classicModal');
      this.setState({
        truckersData,
        trucks,
        truckNumber: '',
        truckType: '',
      });
      this.updateTrucksInFirestore(trucks);
    } else {
      this.setState({
        truckNumberState: 'error',
      });
    }
  }

  // Modal Window To Add The Trucks
  getSweetAlertForm() {
    const { classes } = this.props;
    const { classicModal, truckNumber, truckNumberState } = this.state;
    return (
      <Dialog
        fullWidth
        maxWidth="sm"
        classes={{ root: classes.center, paper: classes.modal }}
        style={{ maxWidth: '400px !important', textAlign: 'center' }}
        open={classicModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => this.handleClose('classicModal')}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <IconButton
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => this.handleClose('classicModal')}
          >
            <Close className={classes.modalClose} />
          </IconButton>
          <h4 className={classes.modalTitle}>Add Truck Details</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Truck Number *"
                id="truck-number"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  onChange: event =>
                    this.change(event, 'truckNumber', 'length', 0),
                  type: 'length',
                  value: truckNumber.replace(/\s/g, ''),
                }}
                success={truckNumberState === 'success'}
                error={truckNumberState === 'error'}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              {this.getTruckTypes()}
            </GridItem>
          </GridContainer>
        </DialogContent>
        <DialogActions className={classes.modalFooter}>
          <Button color="primary" onClick={() => this.addTrucks()}>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  /**
   * Will return the Table Component
   * @function
   */
  getTable() {
    const { classes } = this.props;
    const { truckersData, trucksState, featureFlagFiles } = this.state;

    return (
      <div>
        <CardHeader style={{ marginBottom: '2px', paddingBottom: '2px' }}>
          <h4>Add Trucks</h4>
        </CardHeader>
        {truckersData.length > 0 ? (
          <TruckDetailCard
            truckersData={truckersData}
            selectedTrucker={this.state.selectedTruckerData}
            activeRole={this.props.activeRole}
            userDetails={this.props.userDetails}
            featureFlagFiles={featureFlagFiles}
          />
        ) : (
          <div style={{ textAlign: 'center' }}>
            <p
              className={`${classes.textCenter} ${classes.title}`}
              style={{ textAlign: 'center', color: 'red' }}
            >
              {trucksState}
            </p>
          </div>
        )}
        <CardFooter>
          <Button
            color="info"
            onClick={() => this.handleClickOpen('classicModal')}
            className={classes.updateProfileButton}
            justIcon
          >
            <Add />
          </Button>
        </CardFooter>
      </div>
    );
  }

  // Modal Window To Add The Bank Details
  getBankDetailsForm() {
    const { classes } = this.props;
    const {
      bankModal,
      bankHolderName,
      bankHolderNameState,
      accountNumber,
      accountNumberState,
      ifsc,
      ifscState,
      bankName,
      bankNameState,
      branchName,
      branchNameState,
    } = this.state;

    return (
      <Dialog
        fullWidth
        maxWidth="sm"
        classes={{ root: classes.center, paper: classes.modal }}
        style={{ maxWidth: '400px !important', textAlign: 'center' }}
        open={bankModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => this.handleClose('bankModal')}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <IconButton
            className={classes.modalCloseButton}
            key="close"
            aria-label="Close"
            color="inherit"
            onClick={() => this.handleClose('bankModal')}
          >
            <Close className={classes.modalClose} />
          </IconButton>
          <h4 className={classes.modalTitle}>Add Bank Details</h4>
        </DialogTitle>
        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Bank Holder Name"
                id="bank Holder Name"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  onChange: event =>
                    this.change(event, 'bankHolderName', 'alpha'),
                  value: bankHolderName,
                }}
                success={bankHolderNameState === 'success'}
                error={bankHolderNameState === 'error'}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Account Number"
                id="account-number"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  onChange: event =>
                    this.change(event, 'accountNumber', 'length', 10),
                  type: 'length',
                  value: accountNumber,
                }}
                success={accountNumberState === 'success'}
                error={accountNumberState === 'error'}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="IFSC Code"
                id="ifsc-number"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  onChange: event => this.change(event, 'ifsc', 'ifsc'),
                  type: 'ifsc',
                  value: ifsc,
                }}
                success={ifscState === 'success'}
                error={ifscState === 'error'}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Bank name"
                id="bank-name"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  onChange: event =>
                    this.change(event, 'bankName', 'length', 2),
                  type: 'length',
                  value: bankName,
                }}
                success={bankNameState === 'success'}
                error={bankNameState === 'error'}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <CustomInput
                labelText="Enter your bank branch name"
                id="branch-name"
                formControlProps={{ fullWidth: true }}
                inputProps={{
                  onChange: event =>
                    this.change(event, 'branchName', 'length', 1),
                  type: 'length',
                  value: branchName,
                }}
                success={branchNameState === 'success'}
                error={branchNameState === 'error'}
              />
            </GridItem>
          </GridContainer>
          {this.state.verifiedNameAtBank && (
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                  }}
                >
                  <h4 style={{ fontWeight: 'bold' }}>Name At Bank</h4>
                  <p
                    style={{
                      color: '#2CAE76',
                      fontWeight: 'bold',
                      margin: 0,
                    }}
                  >
                    {this.state.verifiedNameAtBank}
                  </p>
                </div>
              </GridItem>
            </GridContainer>
          )}
        </DialogContent>
        <p style={{ color: 'red' }}>{this.state.validateError}</p>
        <DialogActions className={classes.modalFooter}>
          {this.state.isVerifyingbank ? (
            <CircularProgress
              // className={classes.progress}
              style={{ color: purple[500] }}
              thickness={4}
              // variant="indeterminate"
              disableShrink
            />
          ) : (
            <Button color="primary" onClick={() => this.verifyBanks()}>
              Verify
            </Button>
          )}
          {this.state.isLoadingBank ? (
            <CircularProgress
              // className={classes.progress}
              style={{ color: purple[500] }}
              thickness={4}
              // variant="indeterminate"
              disableShrink
            />
          ) : (
            <Button
              color="primary"
              disabled={!this.state.bankAccountDetailsVerified}
              onClick={() => this.addBanks()}
            >
              Add
            </Button>
          )}
          {/* {editBank ? (
            <Button
              color="primary"
              onClick={() => this.editBank(accountNumber)}
            >
              Save Changes
            </Button>
          ) : (
            <Button color="primary" onClick={() => this.addBanks()}>
              Add
            </Button>
          )} */}
        </DialogActions>
      </Dialog>
    );
  }

  /**
   * Will return the Bank Details Component
   * @function
   */
  getBanks() {
    const { classes } = this.props;
    const { banksData, banksState, activeError } = this.state;

    return (
      <Card>
        <CardHeader style={{ marginBottom: '2px', paddingBottom: '2px' }}>
          <h4>Add Banks</h4>
        </CardHeader>
        {banksData.length > 0 ? (
          <React.Fragment>
            <CardBody className={classes.customCardContentClass}>
              <Table
                hover
                tableHead={[
                  'Name',
                  'Account No',
                  'Bank',
                  'Ifsc',
                  'Branch',
                  'Active',
                  'Fund Account Status',
                ]}
                tableData={banksData}
              />
            </CardBody>
            <div style={{ textAlign: 'center' }}>
              <p
                className={`${classes.textCenter} ${classes.title}`}
                style={{ textAlign: 'center', color: 'red' }}
              >
                {activeError}
              </p>
            </div>
          </React.Fragment>
        ) : (
          <div style={{ textAlign: 'center' }}>
            <p
              className={`${classes.textCenter} ${classes.title}`}
              style={{ textAlign: 'center', color: 'red' }}
            >
              {banksState}
            </p>
          </div>
        )}
        <CardFooter>
          <Button
            color="info"
            onClick={() => this.handleClickOpen('bankModal')}
            className={classes.updateProfileButton}
            justIcon
          >
            <Add />
          </Button>
        </CardFooter>
      </Card>
    );
  }

  showPanDialog() {
    const { classes } = this.props;
    const { panModal, panDetailData, showLoader } = this.state;
    const isPanDetailDataEmpty =
      panDetailData?.pan_status === '' ||
      panDetailData?.pan_status === 'INVALID';

    return (
      <Dialog
        classes={{
          root: `${classes.center}`,
        }}
        fullWidth
        maxWidth="sm"
        open={panModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => this.handleCloseModal('panModal')}
        aria-labelledby="classic-modal-slide-title"
        aria-describedby="classic-modal-slide-description"
      >
        <DialogTitle
          id="classic-modal-slide-title"
          disableTypography
          className={classes.modalHeader}
        >
          <div className={classes.modalHeaderContent}>
            <h4 className={classes.modalTitle}>PAN Validation Details</h4>
          </div>
        </DialogTitle>

        <DialogContent
          id="classic-modal-slide-description"
          className={classes.modalBody}
        >
          {showLoader ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <CircularProgress />
            </div>
          ) : isPanDetailDataEmpty ? (
            <div
              style={{
                backgroundColor: '#FDE8E8',
                border: '1px solid #d32f2f',
                color: 'black',
                padding: '10px',
                borderRadius: '5px',
              }}
            >
              <img
                style={{
                  paddingRight: '10px',
                }}
                src={infoIcon}
                alt=""
              />
              Invalid! PAN number or details does not exist
            </div>
          ) : (
            <div>
              {/* Render the PAN detail data */}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '8px',
                }}
              >
                <p>Verified Name</p>
                <p style={{ fontWeight: 'bold' }}>
                  {panDetailData?.verifed_name}
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '8px',
                }}
              >
                <p>Name on PAN</p>
                <p style={{ fontWeight: 'bold' }}>
                  {panDetailData?.name_pan_card}
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '8px',
                }}
              >
                <p>DOB</p>
                <p style={{ fontWeight: 'bold' }}>
                  {panDetailData?.pan_date_of_birth}
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '8px',
                }}
              >
                <p>PAN Type</p>
                <p style={{ fontWeight: 'bold' }}>{panDetailData?.pan_type}</p>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '8px',
                }}
              >
                <p>PAN Status</p>
                <p style={{ fontWeight: 'bold' }}>
                  {panDetailData?.pan_status}
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '8px',
                }}
              >
                <p>Aadhaar Linked</p>
                <p style={{ fontWeight: 'bold', color: 'green' }}>
                  {panDetailData.pan_aadhaar_linked && (
                    <img src={greenTick} alt="icon" />
                  )}
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '8px',
                }}
              >
                <p>Gender</p>
                <p style={{ fontWeight: 'bold' }}>
                  {panDetailData?.pan_gender}
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '8px',
                }}
              >
                <p>PAN Email</p>
                <p style={{ fontWeight: 'bold' }}>{panDetailData?.pan_email}</p>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '8px',
                }}
              >
                <p>Address State</p>
                <p style={{ fontWeight: 'bold' }}>
                  {panDetailData?.pan_address?.city}
                  {panDetailData?.pan_address?.state}
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '8px',
                }}
              >
                <p>Address Pincode</p>
                <p style={{ fontWeight: 'bold' }}>
                  {panDetailData?.pan_address?.pincode}
                </p>
              </div>
            </div>
          )}
        </DialogContent>

        <DialogActions className={classes.modalFooter}>
          <Button onClick={() => this.handleCloseModal('panModal')}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  async blacklistTrucker(id) {
    const { activeRole } = this.props;
    this.setState({ blacklisting: true });
    const systemDetails = JSON.parse(localStorage.getItem('systemDetails'));

    const userDetails = firebase.auth().currentUser;
    const { Aa } = userDetails;
    const token = Aa;
    try {
      const endPoint = 'pipe/add-blacklist';
      const body = {
        id,
        type: 'trucker',
        role: `is${this.capitalizeFirstLetter(
          activeRole === 'branch-ops' ? 'branchOps' : activeRole.split('is')[0]
        )}`,
      };
      await axios.post(`${REACT_APP_API_BASE_URL}${endPoint}`, body, {
        headers: { Authorization: `Bearer ${token}` },
      });
      this.setState({ blacklisting: false });
      const auditPayload = {
        collection: 'trucker',
        orgId: id,
        data: body,
        message: `${userDetails?.displayName} with email id ${userDetails?.email} blacklisted a trucker`,
        systemDetails,
        type: `Blacklist Trucker`,
        role: `${this.capitalizeFirstLetter(
          activeRole === 'branch-ops' ? 'branchOps' : activeRole.split('is')[0]
        )}`,
        eventDateTime: new Date().toISOString(),
        source: 'office',
      };
      auditLogger(auditPayload);
      swal(
        'Blacklisted Trucker!',
        'you have blacklisted trucker successfully!',
        'success'
      );
    } catch (e) {
      this.setState({ blacklisting: false });
    }
  }

  searchTrucker = async phone => {
    this.setState({ validatingPhone: true });
    let status = false;
    const userDetails = firebase.auth().currentUser;
    const { Aa } = userDetails;
    const token = Aa;
    try {
      const endPoint = `pipe/office-user-validation?phone_number=${phone}&type=phone_number_check&source=trucker`;
      const response = await apiCaller(
        endPoint,
        {},
        `Bearer ${token}`,
        'nh-base',
        'get'
      );
      const data = response?.data;
      if (data.length > 0) {
        this.setState({ presentOrgs: data });
        status = false;
      } else {
        this.setState({ presentOrgs: data });
        status = true;
      }
      this.setState({ validatingPhone: false });
    } catch (e) {
      this.setState({ validationFailed: false });
      status = true;
      this.setState({ validatingPhone: false });
    }
    // return status;
  };

  // Will Verify Bank Details
  validateBankFields() {
    const {
      accountNumber,
      bankHolderName,
      ifsc,
      bankName,
      branchName,
    } = this.state;
    let error = false;
    return new Promise((resolve, reject) => {
      // Verify Account Number
      if (this.verifyLength(accountNumber, 5)) {
        this.setState({
          accountNumberState: 'success',
        });
      } else {
        this.setState({ accountNumberState: 'error' });
        error = true;
      }

      // Verify Account Number
      if (this.verifyAlpha(bankHolderName)) {
        this.setState({
          bankHolderNameState: 'success',
        });
      } else {
        this.setState({ bankHolderNameState: 'error' });
        error = true;
      }

      // Verify IFSC
      if (this.verifyIfsc(ifsc)) {
        this.setState({
          ifscState: 'success',
        });
      } else {
        this.setState({ ifscState: 'error' });
        error = true;
      }

      // Verify Bank Name
      if (this.verifyLength(bankName, 2)) {
        this.setState({ bankNameState: 'success' });
      } else {
        this.setState({ bankNameState: 'error' });
        error = true;
      }

      // Verify Bank Branch
      if (this.verifyLength(branchName, 2)) {
        this.setState({ branchNameState: 'success' });
      } else {
        this.setState({ branchNameState: 'error' });
        error = true;
      }

      if (!error) {
        resolve(true);
      } else {
        reject(error);
      }
    });
  }

  async checkbankDetails(data) {
    const currentUserDetails = firebase.auth().currentUser;
    const { Aa } = currentUserDetails;
    const userToken = Aa;
    const endPoint = 'pipe/bank';
    try {
      const result = await apiCaller(
        endPoint,
        data,
        `Bearer ${userToken}`,
        'pipe'
      );
      return result;
    } catch (err) {
      throw new Error(err);
    }
  }

  verifyBanks() {
    const { accountNumber, ifsc, bankHolderName, phoneNumber } = this.state;

    this.validateBankFields()
      .then(success => {
        this.setState({
          isVerifyingbank: true,
        });
        const body = {
          type: 'verify-bank',
          bank_account_name: bankHolderName,
          bank_account_number: accountNumber,
          ifsc_code: ifsc,
          phone_number: phoneNumber,
        };
        this.checkbankDetails(body)
          .then(result => {
            const verified = result?.data?.account_verified;
            const nameAtbank = result?.data?.name_at_bank;
            if (verified) {
              this.setState({
                verifiedNameAtBank: nameAtbank,
                isVerifyingbank: false,
                bankAccountDetailsVerified: true,
              });
            }
            //
          })
          .catch(err =>
            this.setState({
              isVerifyingbank: false,
            })
          );
      })
      .catch(err => {
        this.setState({
          isVerifyingbank: false,
        });
      });
  }

  async validPanDetails(id) {
    this.setState({
      showLoader: true,
    });
    this.handleClickOpenModal('panModal');
    const userDetails = firebase.auth().currentUser;
    const { Aa: token } = userDetails;

    const endPoint = `pipe/trucker-pan-details?trucker_id=${id}`;

    try {
      const response = await apiCaller(
        endPoint,
        {},
        `Bearer ${token}`,
        'nh-base',
        'get'
      );
      const value = response?.data?.Data;
      this.setState({
        showLoader: false,
        panDetailData: value,
      });
    } catch (e) {
      console.log('Error', e);
    }
  }

  fetchTdsPercentage = async () => {
    const { activeRole } = this.props;

    const currentRole = activeRole === 'branch-ops' ? 'BranchOps' : activeRole;
    const currentActiveRole = `is${currentRole
      .charAt(0)
      .toUpperCase()}${currentRole.slice(1)}`;
    const userDetails = firebase.auth().currentUser;
    const { Aa: token } = userDetails;
    const endPoint = `pipe/trucker-tds-percentage?role=${currentActiveRole}&trucker_id=${this.state.selectedTruckerData.truckerId}`;
    try {
      const response = await apiCaller(
        endPoint,
        {},
        `Bearer ${token}`,
        'nh-base',
        'get'
      );
      this.setState({ tdsPercentage: response?.data?.tds_percentage });
    } catch (e) {}
  };

  // Will Add Banks
  addBanks() {
    const { classes } = this.props;
    const {
      banks,
      banksData,
      accountNumber,
      bankName,
      ifsc,
      branchName,
      bankHolderName,
      initialBanks,
      phoneNumber,
    } = this.state;
    const bank = {
      accountNumber,
      bankName,
      ifsc,
      bankBranchName: branchName,
      bankHolderName,
      bankAccountActive: false,
    };
    this.validateBankFields()
      .then(success => {
        this.setState({
          isLoadingBank: true,
        });
        const body = {
          account_number: accountNumber,
          ifsc_code: ifsc,
          account_name: bankHolderName,
          phone: phoneNumber,
        };
        validateBank(body)
          .then(res => {
            if (res.data.data.hasOwnProperty('all_banks')) {
              const bankData = res.data.data.all_banks;
              this.setState({ verifiedNameAtBank: bankData[0].name_at_bank });
              bankData.forEach(newBank => {
                if (newBank.account_verified) {
                  this.setState({
                    isLoadingBank: false,
                    validateError: '',
                    isBankEdit: true,
                  });
                  banks.push({
                    accountNumber,
                    bankName,
                    ifsc,
                    bankBranchName: branchName,
                    bankHolderName,
                    bankAccountActive: false,
                  });
                  initialBanks.push({
                    accountNumber,
                    bankName,
                    ifsc,
                    bankBranchName: branchName,
                    bankHolderName,
                    bankAccountActive: false,
                  });

                  banksData.push({
                    color: 'success',
                    data: [
                      bankHolderName,
                      accountNumber,
                      bankName,
                      ifsc,
                      branchName,
                      <GridItem xs={12} sm={12} md={6}>
                        <div className={classes.block}>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={false}
                                onChange={this.handleChange(
                                  bank,
                                  banksData.length
                                )}
                                value={false}
                                classes={{
                                  switchBase: classes.switchBase,
                                  checked: classes.switchChecked,
                                  icon: classes.switchIcon,
                                  iconChecked: classes.switchIconChecked,
                                  bar: classes.switchBar,
                                }}
                              />
                            }
                          />
                        </div>
                      </GridItem>,
                      '',
                    ],
                  });
                  this.handleClose('bankModal');
                  this.setState({
                    banksData,
                    banks,
                    accountNumber: '',
                    ifsc: '',
                    bankName: '',
                    branchName: '',
                    bankHolderName: '',
                    accountNumberState: '',
                    ifscState: '',
                    bankNameState: '',
                    branchNameState: '',
                    bankHolderNameState: '',
                  });
                } else {
                  this.setState({
                    isLoadingBank: false,
                    validateError:
                      'We are unable to validate the bank details. Please check if the bank details are correct.',
                  });
                }
              });
            }
          })
          .catch(err => {
            console.log('Error', err);
          });
      })
      .catch(err => {
        console.log('Error', err);
      });
  }

  retryContactAndFundAccount(bank) {
    this.setState({
      approveAlert: true,
      contactLoading: true,
      fundAccountLoading: true,
      razorpayError: '',
    });
    const { selectedTruckerData } = this.state;
    const body = {
      type: 'contact_fund_id',
      beneficiary_id: selectedTruckerData.truckerId,
      account_name: bank.bankHolderName,
      account_number: bank.accountNumber,
      ifsc_code: bank.ifsc,
      ref_id: bank.bankId,
    };
    retryBankAccount(body)
      .then(res => {
        this.setState({
          contactLoading: false,
          fundAccountLoading: false,
          contactSuccess: true,
          fundAccountSuccess: true,
        });
        console.log(res);
      })
      .catch(err => {
        this.setState({
          contactLoading: false,
          fundAccountLoading: false,
          contactSuccess: false,
          fundAccountSuccess: false,
          razorpayError: err.message,
        });
      });
  }

  render() {
    const { classes, activeRole } = this.props;
    const {
      alert,
      nameState,
      name,
      contactPerson,
      phoneNumber,
      phoneNumberState,
      email,
      emailState,
      gstNumber,
      gstNumberState,
      panNumber,
      panNumberState,
      isLoading,
      approveAlert,
      contactSuccess,
      contactLoading,
      razorpayError,
      edit,
      featureFlagFiles,
      componentLoading,
      showDetails,
      tdsPercentage,
    } = this.state;
    const splitNumber = phoneNumber.slice(-4);
    const mask = 'xxxxx';
    const maskedNumber = mask + splitNumber;
    return (
      <div>
        {featureFlagFiles === 1 && <FeatureFlagToastAlert />}
        {componentLoading ? (
          <GridContainer justify="center">
            <CircularProgress
              className={classes.progress}
              style={{ color: purple[500] }}
              thickness={3}
            />
          </GridContainer>
        ) : (
          showDetails && (
            <GridContainer {...this.props}>
              <GridItem xs={12} sm={12} md={12}>
                {alert}
                <Card>
                  <CardHeader
                    className={`${classes.cardHeader}`}
                    color="warning"
                  >
                    <CardIcon
                      color="primary"
                      className={`${classes.cardHeader}`}
                      // eslint-disable-next-line react/destructuring-assignment
                      onClick={() => this.hideAlert()}
                    >
                      <ArrowBack />
                    </CardIcon>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <h4 className={classes.cardIconTitle}>
                        Add Trucker
                        <small />
                      </h4>
                      {this.state.blacklisting ? (
                        <CircularProgress />
                      ) : activeRole === 'ops' ? (
                        <Popup
                          trigger={
                            this.state.blacklisting ? (
                              <CircularProgress
                                style={{ color: '#FCD21F', margin: '5px' }}
                                size={30}
                                thickness={8}
                              />
                            ) : (
                              <Button
                                style={{
                                  background: 'black',
                                  color: '#FCD21F',
                                  fontWeight: 'bold',
                                }}
                              >
                                Blacklist
                              </Button>
                            )
                          }
                          {...{
                            contentStyle,
                          }}
                          modal
                        >
                          {close => (
                            <div
                              style={{
                                padding: '10px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                              }}
                            >
                              <HighlightOffIcon
                                fontSize="large"
                                color="error"
                              />

                              <h4 style={{ fontWeight: 'bold' }}>
                                Are You Sure?
                              </h4>
                              <p>
                                If you proceed, you will not be able to recover
                                it
                              </p>
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'space-between',
                                  width: '100%',
                                }}
                              >
                                <Button onClick={close} style={cancelButton}>
                                  No
                                </Button>

                                <Button
                                  onClick={() => {
                                    close();
                                    this.blacklistTrucker(
                                      this.state.selectedTruckerData.truckerId
                                    );
                                  }}
                                  style={deleteModalButton}
                                >
                                  Yes
                                </Button>
                              </div>
                            </div>
                          )}
                        </Popup>
                      ) : null}
                    </div>
                  </CardHeader>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Trucker Name *"
                          id="username"
                          formControlProps={{ fullWidth: true }}
                          success={nameState === 'success'}
                          error={nameState === 'error'}
                          inputProps={{
                            onChange: event =>
                              this.change(event, 'name', 'length', 1),
                            type: 'text',
                            value: name,
                          }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="Contact Person "
                          id="contact-person"
                          formControlProps={{ fullWidth: true }}
                          inputProps={{
                            onChange: event =>
                              this.change(event, 'contactPerson', 'length', 0),
                            type: 'length',
                            value: contactPerson,
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          {!edit ? (
                            <CustomInput
                              labelText="Phone Number *"
                              id="phone-number"
                              formControlProps={{ fullWidth: true }}
                              inputProps={{
                                readOnly: true,
                                onChange: event =>
                                  this.change(event, 'phoneNumber', 'phone'),
                                type: 'phone',
                                value:
                                  activeRole == 'ops' || activeRole == 'admin'
                                    ? phoneNumber
                                    : maskedNumber,
                              }}
                              success={phoneNumberState === 'success'}
                              error={phoneNumberState === 'error'}
                            />
                          ) : (
                            <div style={{ marginTop: '20px', width: '100%' }}>
                              <TextField
                                onChange={event => {
                                  const regex = /^[6-9][0-9]{9}$/;
                                  if (regex.test(event.target.value)) {
                                    if (event.target.value.length === 10) {
                                      this.searchTrucker(event.target.value);
                                    }
                                    this.setState({
                                      phoneNumber: event.target.value,
                                      phoneNumberState: 'success',
                                    });
                                  } else
                                    this.setState({
                                      phoneNumberState: 'error',
                                    });
                                }}
                                label="Phone Number *"
                                fullWidth
                                error={phoneNumberState === 'error'}
                              />
                              {this.state.validatingPhone ? (
                                <CircularProgress />
                              ) : null}
                              {this.state.presentOrgs?.length > 0 ? (
                                <div
                                  style={{
                                    display: 'flex',
                                    alignItems: 'center',

                                    flexWrap: 'wrap',
                                  }}
                                >
                                  <Typography
                                    variant="caption"
                                    gutterBottom
                                    color="primary"
                                  >
                                    Org already exists with Phone Number &nbsp;
                                  </Typography>
                                  <Typography
                                    variant="caption"
                                    gutterBottom
                                    color="primary"
                                  >
                                    (
                                  </Typography>
                                  {this.state.presentOrgs?.map((org, index) => (
                                    <Typography
                                      variant="caption"
                                      gutterBottom
                                      color="primary"
                                      style={{
                                        marginRight: '5px',
                                        textDecoration: org?.blacklist
                                          ? 'line-through'
                                          : 'none',
                                      }}
                                    >
                                      {org.company_name},
                                    </Typography>
                                  ))}
                                  <Typography
                                    variant="caption"
                                    gutterBottom
                                    color="primary"
                                  >
                                    )
                                  </Typography>
                                </div>
                              ) : null}
                            </div>
                          )}
                          {this.state.validatingPhone ? (
                            <CircularProgress />
                          ) : null}
                        </div>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          success={emailState === 'success'}
                          error={emailState === 'error'}
                          labelText="Email address "
                          id="email-address"
                          formControlProps={{ fullWidth: true }}
                          inputProps={{
                            onChange: event =>
                              this.change(event, 'email', 'email'),
                            type: 'email',
                            value: email,
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        <CustomInput
                          labelText="GST Number "
                          id="gst-number"
                          formControlProps={{ fullWidth: true }}
                          inputProps={{
                            onChange: event =>
                              this.change(event, 'gstNumber', 'gst'),
                            type: 'gst',
                            value: gstNumber,
                          }}
                          success={gstNumberState === 'success'}
                          error={gstNumberState === 'error'}
                        />
                      </GridItem>
                      <GridItem
                        xs={12}
                        sm={12}
                        md={6}
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <CustomInput
                          labelText={
                            <div
                              style={{ display: 'flex', alignItmes: 'center' }}
                            >
                              <p>PAN Number *</p>
                              {this.state.panNumberState === 'error' && (
                                <Tooltip title={this.state.validationMessage}>
                                  <InfoIcon
                                    fontSize="small"
                                    style={{ marginLeft: '10px', color: 'red' }}
                                  />
                                </Tooltip>
                              )}
                            </div>
                          }
                          id="pan-number"
                          formControlProps={{ fullWidth: true }}
                          inputProps={{
                            onChange: event =>
                              this.change(event, 'panNumber', 'pan'),
                            type: 'pan',
                            value: panNumber,
                          }}
                          success={panNumberState === 'success'}
                          error={panNumberState === 'error'}
                        />
                        <Button
                          style={panButton}
                          onClick={() =>
                            this.validPanDetails(
                              this.state.selectedTruckerData.truckerId
                            )
                          }
                        >
                          Pan Valid Details
                        </Button>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <TdsPercentage
                          activeRole={this.props.activeRole}
                          truckerId={this.state.selectedTruckerData.truckerId}
                          fetchTdsPercentage={this.fetchTdsPercentage}
                          tdsPercentage={tdsPercentage}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <TruckerNewFieldsView
                          activeRole={this.props.activeRole}
                          selectedTruckerData={this.state.selectedTruckerData}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <SecondaryContactsView
                          classes={classes}
                          truckerData={this.state.selectedTruckerData}
                          activeRole={this.props.activeRole}
                          handleContacts={value => this.handleContacts(value)}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <PanFileUpload
                          truckerId={this.state.selectedTruckerData.truckerId}
                          activeRole={this.props.activeRole}
                          featureFlagFiles={featureFlagFiles}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        <AdhaarFileUpload
                          truckerId={this.state.selectedTruckerData.truckerId}
                          activeRole={this.props.activeRole}
                          featureFlagFiles={featureFlagFiles}
                        />
                      </GridItem>
                      {featureFlagFiles === 2 && (
                        <GridItem xs={12} sm={12} md={6}>
                          <AdhaarBackFileUploader
                            truckerId={this.state.selectedTruckerData.truckerId}
                            activeRole={this.props.activeRole}
                          />
                        </GridItem>
                      )}
                    </GridContainer>
                    <GridContainer
                      direction="row"
                      alignItems="flex-start"
                      justifyContent="space-between"
                    >
                      <GridItem xs={12} sm={12} md={6}>
                        <GridContainer direction="column">
                          <GridItem xs={12} sm={12} md={12}>
                            <TruckerTDS
                              activeRole={this.props.activeRole}
                              stylesClass={this.props}
                              truckerData={this.state.selectedTruckerData}
                              featureFlagFiles={featureFlagFiles}
                            />
                          </GridItem>

                          <GridItem xs={12} sm={12} md={12}>
                            <TruckerTdsNew
                              activeRole={this.props.activeRole}
                              stylesClass={this.props}
                              truckerData={this.state.selectedTruckerData}
                              featureFlagFiles={featureFlagFiles}
                              fetchTdsPercentage={this.fetchTdsPercentage}
                            />
                          </GridItem>

                          <GridItem xs={12} sm={12} md={12}>
                            <TruckerOverallRatingCard
                              truckerData={this.state.selectedTruckerData}
                            />
                          </GridItem>
                        </GridContainer>
                      </GridItem>

                      <GridItem xs={12} sm={12} md={6}>
                        <CommentSection
                          truckerData={this.state.selectedTruckerData}
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        {this.getBanks()}
                      </GridItem>
                      <GridItem xs={12} sm={12}>
                        {this.getTable()}
                      </GridItem>

                      <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={12}>
                          {this.getBankDetailsForm()}
                        </GridItem>
                      </GridContainer>

                      <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={12}>
                          {this.showPanDialog()}
                        </GridItem>
                      </GridContainer>
                      <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={12}>
                          {this.getSweetAlertForm()}
                        </GridItem>
                      </GridContainer>
                    </GridContainer>
                    {isLoading ? (
                      <CircularProgress
                        className={classes.progress}
                        style={{ color: purple[500] }}
                        thickness={7}
                      />
                    ) : (
                      <Button
                        color="warning"
                        onClick={this.submitTrucker}
                        className={classes.updateProfileButton}
                        disabled={
                          this.state.duplicateTrucker ||
                          this.state.panNumberState === 'error'
                        }
                      >
                        Submit
                      </Button>
                    )}
                    <Clearfix />
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          )
        )}
        {approveAlert && (
          <SweetAlert
            confirmBtnText="Done"
            title=""
            onConfirm={() => {
              this.setState({ approveAlert: false });
            }}
            onCancel={() => {
              this.setState({ approveAlert: false });
            }}
            confirmBtnCssClass={`${this.props.classes.button} ${this.props.classes.success}`}
          >
            <h2>
              <span className={this.props.classes.rajdhaniFont}>
                PaaS Status
              </span>
            </h2>
            <GridContainer>
              <GridItem xs={12}>
                <span style={{ fontSize: 25, textAlign: 'center' }}>
                  {' '}
                  Account is being verified.
                </span>
                {contactLoading && (
                  <CircularProgress
                    className={classes.progress}
                    style={{ color: purple[500] }}
                    size={20}
                    thickness={3}
                  />
                )}
              </GridItem>

              <GridItem xs={12}>
                {contactSuccess && (
                  <p style={{ textAlign: 'center', color: '#009688' }}>
                    Will be available after verification.
                  </p>
                )}
              </GridItem>
              <GridItem xs={12}>
                <span
                  style={{
                    fontSize: 15,
                    float: 'left',
                    color: 'red',
                  }}
                >
                  {razorpayError}
                </span>
              </GridItem>
              <GridItem xs={12}>
                <span
                  style={{
                    fontSize: 15,
                    float: 'left',
                    color: 'red',
                  }}
                >
                  {razorpayError && 'Retry in Trucker Edit.'}
                </span>
              </GridItem>
            </GridContainer>
          </SweetAlert>
        )}
      </div>
    );
  }
}
AdminEditTrucker.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  activeRole: state.main.activeRole,
  userDetails: state.main.userDetails,
  systemDetails: state.main.systemDetails,
});

export default connect(mapStateToProps)(
  withStyles(AddTruckersStyle)(AdminEditTrucker)
);
